@import 'config/_theme.scss';

.isp-section {
  height: 100%;
  margin: $el-margin;
}

.isp-condition {
  display: block;
  max-height: 20rem;
  overflow-y: auto;
  border: 1px solid $color-grey-light;
  padding: $el-pad/2;
  background: $color-light;

  &__footer{
    text-align: center;
  }
  h1, h2{
    margin-bottom: $el-pad/2;
  }
  h2{
      font-size: 2rem;
  }

  h3, h4{
    margin-bottom: $el-pad/3;
  }
  h3{
      font-size: 1.8rem;
  }

  p{
    margin-bottom: $el-pad/5;
    margin-left: $el-pad/2;
    line-height: 1.5;
  }

  strong{
    font-weight: $weight-bold;
  }

  ul{
    list-style-position: outside;
    margin-bottom: $el-pad;

    > li{
      margin-left: $el-pad;
      margin-bottom: $el-pad/5;
    }
  }

  ul{
    list-style: disc;
  }
  ol{
    display: block;
    list-style: decimal;
    list-style-position: inside;
    counter-reset: item;
  }
}