@import 'config/_theme.scss';

.modal-overlay {
  background-color: rgba($color-dark, 0.5) !important;
  z-index: $z-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.refinance, .error {
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background: $color-light;
  width: 327px;
  border-radius: 12px;
  font-size: $font-normal;

  &__header {
    font-weight: bold;
    text-align: center;
    margin: $el-pad 0 0;

    &__text {
      font-weight: $weight-semi;
      align-items: center;
      padding: $el-pad/2;
      font-size: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__body {
    text-align: center;
    align-items: center;
    padding: $el-margin;

    &__image {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__content {
      padding: $el-margin;
      line-height: $lh;
      margin: $el-pad/2;

      a {
        color: $color-primary;
      }
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 0 $el-pad/2 $el-pad/2;

    button {
      min-width: 240px;
      border: 1.5px solid $color-primary;
      border-radius: 30px;
      font-weight: bold;
      line-height: 24px;
      margin: 0 $el-pad $el-pad/2;
      letter-spacing: normal;
    }
  }

  &__close {
    float: right;
    margin: 0 $el-pad/2;
    i {
      color: $color-gray-800;
      background-color: transparent;
      border-radius: 10px;
    }
  }

  &__til-app-btn {
    color: $color-primary !important;
  }

  &__til-line {
    border: transparent !important;
    color: $color-dark !important;
    i {
      height: unset !important;
      margin-right: $el-pad/2 !important;
    }
  }
}
