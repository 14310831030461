@import 'config/_theme.scss';

.body {
  width: 100%;
  align-items: center;
}

.select {
  width: 100%;
  margin-bottom: 10px;
  background: transparent;

  input {
    height: 10px;
    width: 100%;
  }

  &__header {
    margin: 0;
    font-weight: $weight-bold;
  }
  
  &__license {
    margin-top: 5px;
    margin-bottom: 25px;
    span {
      color: black;
      font-size: $font-base;
      text-transform: none;
    }
    input {
      height: 10px;
      width: 100%;
      span {
        padding: 0 !important;
      }
    }
  }

  &__date {
    margin-top: 10px;

    span {
      font-weight: $weight-bold;
      color: black;
      font-size: $font-base;
    }

    input {
      height: 20px;
    }
  }

  &__date-picker {
    height: $el-height-sm;
    white-space: nowrap;
    position: relative;
    border: transparent;
    border-radius: 5px;
    font-family: $font-header;
    padding: 0.5rem 1rem !important;
  }

  &__time {
    margin-top: 10px;
    align-items: center;

    span {
      color: black;
      font-size: $font-base;
    }
  }

  &__service {
    margin-top: 25px;
    &__header {
      span {
        font-weight: $weight-bold;
      }
    }
    span {
      color: black;
      font-size: $font-base;
      text-transform: none;
      text-align: justify;
      padding: 0 !important;
    }
    input {
      height: 10px;
      width:100%;
      margin-bottom: 20px;
    }
    .input__container {
      height: 30px;
      input {
        margin-bottom: 0;
      }
    }
  }

  &__remark {
    text-align: justify !important;
    margin-bottom: 10px;
    span {
      padding: 0 !important;
      color: red;
      font-size: 10px;
    }
  }
}

.time-selection {
  width: 100%;
}

.remark {
  margin-top: 30px;
  text-align: left;
  margin-bottom: 10px;
  span {
    padding: 0 !important;
    color: red;
    font-size: 10px;
    font-weight: $font-base !important;
  }
}

.search-dealer__selection span {
  background: transparent;
  padding: 0 !important;
}

.date-picker {
  height: $el-height-sm;
  white-space: nowrap;
  position: relative;
  border: transparent;
  border-radius: 5px;
  font-family: $font-header;
  text-align: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block !important;
  width: 98% !important;
  @media (max-width: 375px) {
    width: 97.5% !important;
  }
  @media (max-width: 320px) {
    width: 97% !important;
  }
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  margin: 0.75rem 1.17rem !important;
  // font-size: 1.4rem;
  font-size: $font-base;
  font-family: $font-body !important;
  @media (max-width: 320px) {
    margin: 0.8rem !important;
  }
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 1.4rem !important;
  font-family: $font-body !important;
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range {
  background-color: #F01516 !important;
}