@import "config/_theme.scss";


.snackbar {
  background: none !important;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6rem;
    padding: 0;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1);
    background: $color-dark;
    > div {
      padding: $el-pad/2;
    }
  }

  &.snackbar--success, &.snackbar--bookmark-success{
    > div {
      background: $color-green-light !important;
      color: $color-light;
    }
  }

  &.snackbar--error {
    > div {
      background: $color-primary !important;
      color: $color-light;
    }
  }

  &.snackbar--warning {
    > div {
      background-color: $color-accent !important;
      color: $color-light;
    }
  }

  &__message {
    font-size: $font-base;
    color: $color-light;
    font-weight: $weight-semi;
  }

  &__close {
    padding: 0;
    height: auto;

    i {
      color: $color-light;
      margin-right: $el-pad/2;
    }
  }

  @media (min-width: 960px) {
    right: $el-pad !important;
    bottom: $el-pad !important;

    &__close {
      position: absolute;
      right: $el-pad/4;
      padding: 0 $el-pad/2;
      height: auto;
      overflow-y: hidden;
    }
  }
}

.goto-bookmark {
  background-color: $color-light !important;
  color: $color-green-dark !important;
  font-weight: bold;
  height: 28px;
  letter-spacing: normal;
}