@import 'config/_theme.scss';

.package-container {
  margin: 16px;
  font-size: 16px;
  // Update next sprint (SP27)
  // padding-bottom: $nav-height;
  @media #{$desktop}, #{$tablet} {
    margin: 0;
  }

  &__title {
    margin: $el-pad 0;
    height: 32px;
    font-weight: bold;
    font-size: 14px;

    &__normal,
    &__bronze,
    &__silver {
      height: 32px;
      border-radius: $el-rad;
      color: $color-light;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__normal {
      background: $color-primary-ISUZU;
    }

    &__bronze {
      background: linear-gradient(38.42deg, #9f3b1b 0%, #c2826e 100%);
    }

    &__silver {
      background: linear-gradient(38.42deg, #8a8a8c 0%, #b4b4b5 100%);
    }
  }

  &__title-text,
  &__privilege__title {
    font-weight: bold;
    margin-bottom: 9px;
  }

  &__package {
    padding-bottom: 14px;
    &__price-text {
      font-weight: bold;
    }
    &__detail {
      margin-top: 14px;
      padding-bottom: 14px;
      display: flex;
      justify-content: space-between;
      box-shadow: inset 0px -1px 0px $color-gray-g3;

      &__price {
        color: $color-primary-ISUZU;
        &__container {
          display: flex;
          justify-content: flex-end;
        }
        &__current {
          text-decoration: line-through;
          color: $color-gray-g7;
          font-size: 13px;
          margin-right: 12px;
        }
      }
    }
  }

  &__privilege {
    box-shadow: inset 0px -1px 0px $color-gray-g3;
    &__title {
      text-align: center;
      font-weight: bold;
    }
    &__container {
      margin: 14px 0;
    }
    &__detail {
      display: flex;
      i {
        margin-right: 19px;
      }
    }
  }
}

.isp-btn {
  position: absolute;
  top: 1rem;
  right: 5%;

  @media #{$desktop} {
    top: 7.5rem;
    right: calc((100% - #{$wrapper-width})/2);
  }

  @media #{$tablet} {
    top: 7.5rem;
    right: 3rem;
  }
}
