@import 'config/_theme.scss';

.profile-change-pass {
  &-wrapper {
    flex-direction: column;
    background-color: $color-light;
    height: 100vh;
    padding-bottom: $nav-height;

    &__header {
      padding: $el-pad $el-pad 0 $el-pad;
      color: #ed1c24;
      font-size: 18px;
      @media #{$desktop}, #{$tablet} {
        margin: 0 auto;
        max-width: 54rem;
      }
    }

    &__form {
      padding: $el-pad/2 $el-pad $nav-height $el-pad;

      &__field {
        margin-bottom: $el-pad/2;
        font-size: 16px;
        div {
          width: 100%;
        }
        #require {
          color: $color-primary;
        }
      }
      .input {
        margin-bottom: 1.5rem;
      }

      &__action {
        display: flex;
        margin-top: $el-pad * 1.5;
        button {
          border-radius: 30px;
          width: 250px;
          height: 55px;
          margin: auto;
        }
      }
    }

    @media #{$desktop}, #{$tablet} {
      &__form {
        margin: 0 auto;
        max-width: 54rem;
      }
    }

    &__content {
      margin: 5rem auto 0 auto;
      @media #{$desktop} {
        max-width: $wrapper-width;
      }
    }
  }
}
