$el-pad             : 3rem;
$el-rad             : .5rem;
$el-height          : 4.5rem;
$el-height-sm       : 3rem;
$el-margin          : .5rem;
$con-pad            : 8rem;
$btn-rad            : 80px;
$el-radius          : 8px;

$font-base          : 1.4rem;
$font-normal        : 16px;
$font-header        : 'TIS', sans-serif;
$font-body          : 'TIS', sans-serif;
$font-icon          : 'Material Icons';

$z-nav              : 100;

$color-primary      : #F01516;
$color-secondary    : #3672F2;
$color-accent       : #FF6227;
$color-light        : #FFFFFF;
$color-dark         : #1C1C1C;
$color-semi-dark    : #555555;
$color-grey         : #737B88;
$color-grey-dark    : #595c5f;
$color-grey-light   : #E3E5E8;
$color-grey-soft    : #BEC4CC;
$color-border       : #F5F5F5;
$color-green        : #55be17;
$color-green-light  : #00C380;
$color-green-dark    : #009662;
$color-yellow       : #f4ee42;
$color-bright-red   : #ED1C24;
$color-hover-red   : #FFF3F3;
$color-gray-200     : #F6F6F6;
$color-gray-400     : #C3C3C3;
$color-gray-800     : #5D5D5D;
$color-gray-900     : #909090;
$color-primary-ISUZU: #ED1C24;
$color-primary-defualt: #E0001A;
$color-gray-g9      :#444444;
$color-gray-g3      : #EBEBEB;
$color-gray-g7      : #777777;
$color-gray-g5      :#AAAAAA;

$bg                 : #F4F6F6;
$bg-header          : #E3E5E8;
$bg-nav             : #FFFFFF;
$bg-nav-desk        : #FBFBFB;
$bg-mileage         : #D8D8D8;
$bg-discount        : #3672F2;
$bg-checked         : rgba($color-primary, .15);
$bg-checked-border  : rgba($color-primary, .2);
$bg-maint           : rgba($color-accent, .3);
$bg-maint-border    : rgba($color-accent, .4);
$bg-unread          : rgba($color-primary, .05);
$bg-unread-border   : rgba($color-primary, .1);
$bg-menu-list-hover : #FFF2F4;

$weight-light       : 300;
$weight-normal      : 400;
$weight-semi        : 600;
$weight-bold        : 700;

$h1                 : $font-base * 3.5;
$h2                 : $font-base * 2.5;
$h3                 : $font-base * 1.8;
$h4                 : $font-base * 1.6;
$h55                : $font-base * 1.3;
$h5                 : $font-base * 1.2;
$h65                : $font-base * 1.15;
$h625               : $font-base * 1.05;
$h6                 : $font-base;
$expiry             : $font-base * 0.75;
$label              : $font-base * 0.8;
$nav                : $font-base * 0.8;
$nav-ph2            : $font-base * 0.65;
$nav-desk           : $font-base * 0.9;

$icon-only          : 2.8rem;
$label-spc          : .1rem;
$nav-spc            : .05rem;
$lh                 : 1.4;

$top-height         : 4.5rem;
$top-height-desk    : 6rem;
$top-height-desk-a2hs   : 12rem;
$header-height      : $el-height;
$tab-height         : $el-height;
$button-height      : $el-height;
$label-height       : 1.5rem;
$label-margin       : $el-margin;
$input-height       : $el-height;
$clear-height       : 2rem;
$clear-bottom       : 1.25rem;
$clear-top          : 3.25rem;
$header-margin      : $el-margin;
$tooltip-pad        : $el-margin;
$nav-height         : 6.7rem;
$nav-height-desk    : 6rem;
$nav-height-desk-a2hs    : 9rem;
$nav-icon           : 2.4rem;
$nav-icon-el        : 4rem;
$counter-height     : 1.5rem;
$counter-width      : 2.8rem;
$slider-margin      : 1.8rem;
$slider-ind         : 5rem;
$modal-height       : $el-height;
$modal-menu         : 20rem;
$z-tooltip          : 10;
$z-navigation       : 50;
$z-top              : 101;
$z-slider           : 2;
$z-modal            : 103;
$z-camera           : 104;

$wrapper-width      : 120rem;
$phone-sm           : "(min-device-width: 320px) and (max-device-width: 480px)";
$phone-sm-pt        : "(min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait)";
$phone-sm-ls        : "(min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape)";
$phone-md           : "(min-device-width: 481px) and (max-device-width: 767px)" ;
$ipad               : "(min-device-width: 480px) and (max-device-width: 1023px)" ;
$tablet             : "(min-width: 768px) and (max-width: 1199px)";
$desktop            : "(min-width: 1200px)";

$breakpoint-md-down : "(max-width: 767px)";
$breakpoint-md-up   : "(min-width: 768px)";

$line-icon          : 50px;
$install-app-bar-height: 40px;
