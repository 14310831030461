@import "config/_theme.scss";

.vehicle-edit{
  background: $color-light;
  &__body{
    padding: $el-pad/2 $el-pad;
    p{
      margin-bottom: $el-pad;
      font-weight: $weight-semi;
      line-height: $lh;
    }
  }

  &__action{
    padding: $el-pad/2 $el-pad;
  }
}