@import '../../../../config/theme';

.bg-container {
  max-width: 60rem;
  align-items: center;
  max-width: 1000px;
  img {
    width: 100%;
    padding: unset !important;
  }
}

.isp-package {
  margin-bottom: $nav-height*2;
  &__isp {
    background: $color-light;
    @media (min-width: 1024px) {
      width: 60rem;
      margin: 20px auto;
    }  

    &__header {
      text-align: center;
      img {
        width: 60%;
        margin: 2rem;
      }
      span {
        font-size: 1.5rem;
        h4 {
          color: $color-primary;
        }
        span {
          color: $color-primary;
        }
      }
    }

    &__vehicle-detail {
      // Update next sprint (SP27)
      // margin: $el-pad $el-pad*1.5;
      // margin: 0 4.5rem;
      &__img {
        margin: 1rem 3rem;
        img {
          width: 100%;
        }
      }
      @media (max-width: 319px) {
        margin: 3rem 2rem;
      }
      &__label {
        margin: 0 3rem;
        @media (min-width: 1024px) {
          margin: 0 14rem !important;
        }
        @media (min-width: 768px) {
          margin: 0 19rem;
        }
        @media (max-width: 320px) {
          margin: 0;
        }
      }
    }

    &__ads {
      margin-top: 1rem;
    }

    &__button {
      .button {
        width: 100%;
        margin: 1rem 0;
        background: $color-primary;
        span {
          font-weight: bold;
          font-size: 1.7rem;
        }
      }
      @media (max-width: 425px) {
        position: relative;
        
      }
    }
  }
}

.contact {
  text-align: center;
  padding-bottom: $nav-height*2;
}