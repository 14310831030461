@import 'config/_theme.scss';

.top,
.top-active {
  height: $top-height;
  background: $color-light;
  top: 0;
  width: 100%;
  z-index: $z-top;
  position: fixed;
  /** Hambuger menu */
  // box-shadow: 0px 8px 0px #f6f6f6, inset 0px -1px 0px #ebebeb;

  @media #{$desktop}, #{$tablet} {
    position: fixed;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
  }

  .top-wrapper {
    margin: 0 auto;
    height: 100%;
    width: calc(100% - (#{$el-pad} * 0.5));
    display: flex;
    max-width: $wrapper-width;
    justify-content: center;
  }

  &__logo {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      vertical-align: middle;

      img {
        width: auto;
        max-height: 100%;
      }
    }
  }

  &__menu {
    display: none;
    @media #{$desktop}, #{$tablet} {
      display: flex;
      width: 100%;
      left: 0;
    }
    &__nav {
      width: 100%;

      display: none;

      @media #{$desktop}, #{$tablet} {
        display: flex;
        justify-content: flex-end;
      }
      &__navigation {
        @media #{$phone-sm},#{phone-md} {
          position: absolute;
          right: 0;
          top: 0;
          height: inherit;
        }
      }
    }
  }

  &__mobile-menu {
    position: absolute;
    left: 90%;
  }

  &__profile {
    display: none;
    margin-left: auto;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 100;
    cursor: pointer;

    &:hover {
      .top__profile__menu {
        display: inline-block;
        position: absolute;
        background: $color-light;
        top: $top-height * 0.7;
        right: 0;
        min-width: $modal-menu;
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1);
        color: $color-dark;

        &__user {
          font-size: $h5;
          padding: $el-pad/2;
          font-weight: $weight-bold;
          text-transform: capitalize;
        }
      }
    }

    &__icon {
      width: $top-height * 0.7;
      height: $top-height * 0.7;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-left: $el-pad/2;

      i {
        font-size: $top-height/2;
      }
    }
  }

  @media #{$desktop}, #{$tablet} {
    height: $top-height-desk;

    .top-wrapper {
      justify-content: flex-start;
    }

    &__logo {
      flex-grow: 0;
      justify-content: flex-start;
    }

    &__profile {
      display: flex;

      &__name {
        padding: $el-pad/2;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        &__corporate {
          display: grid;
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &__menu {
        display: none;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        color: $color-dark;
      }
    }
  }
  &.top--signup {
    box-shadow: none;
    border-bottom: 1px solid #C3C3C3;
    .top-wrapper {
      justify-content: center;
    }
  }
}

.top-signin {
  // background: $bg;
  z-index: $z-top;

  @media (max-width: 767px) {
    width: 100%;
  }

  .top__logo {
    @media (max-width: 425px) {
      padding-top: 50px;
    }
    @media #{$desktop} {
      position: absolute;
      width: 35%;
      right: 0;
      margin-top: 70px;
      &__image {
        margin: 0 !important;
      }
    }
    &__image {
      height: 5rem;
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
      @media (max-width: 425px) {
        padding-top: -1.5rem;
      }

      img {
        max-height: 80%;
      }
    }
  }
}

.top-active {
  visibility: visible;
  transition: all 0.5s;
}

.top-hidden {
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(-100%);
}

#contact-nav-bar,
#inbox-nav-bar {
  @media #{$phone-sm}, #{$phone-sm-pt}, #{$phone-sm-ls}, #{$tablet} {
    padding-right: 1.5rem;
    width: unset;
    height: 100%;
  }
  div > svg > path {
    height: 20;
    width: 20;
    fill: $color-gray-g9;
  }
}
