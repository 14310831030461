@import 'config/_theme.scss';

.banner-item {
  img{
    @media #{$desktop} {  
      max-width: 800px;
      height: auto;
    }
    max-width: 100%;
    margin: auto;
  }
}

.banner-timeline-item {
  img{
    border-radius: $el-radius;
    @media #{$desktop}, #{$tablet} {  
      height: auto;
      max-width: 100%;
    }
    max-width: calc(100% - 10px);
    margin-bottom: 30px;
  }
}