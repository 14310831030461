@import 'config/_theme.scss';

.package-remark {
  padding: $el-pad;
  background: $color-gray-200;
  border-radius: $el-radius;
  margin-top: $el-pad;

  &--detail {
    display: flex;
    p {
      margin-right: $el-margin;
    }
  }
}