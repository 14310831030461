@import 'config/_theme.scss';
@import '@material/react-tab-bar/index';
@import '@material/react-tab-scroller/index';
@import '@material/react-tab/index';
@import '@material/react-tab-indicator/index';
@import '@material/ripple/mixins';

.tab-bar {
  @include mdc-tab-bar-width(100%);
  border-bottom: 1px solid darken($bg, 10);
  margin: 0 auto;
  max-width: $wrapper-width;
  box-sizing: border-box;
  font-family: $font-header;

  &--folder {
    width: 100%;
    border-bottom: none !important;
    display: flex;

    .mdc-tab-scroller {
      width: 100%;
    }

    .tab-bar__tab {
      display: inline-block;
      font-weight: $weight-semi;
      flex: 1 1 auto;
      width: auto;
      min-width: unset;
      padding: 0;
      margin-left: $el-margin;
      border-radius: $el-rad $el-rad 0 0;
      background: $color-light;
      text-transform: uppercase !important;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      font-size: 1.4rem !important;

      .mdc-tab__content {
        font-family: $font-header;
      }

      @media (max-width: 359px) {
        font-size: 1.2rem !important;
      }

      &:last-child {
        margin-right: $el-margin;
      }

      i {
        margin: 0;
        padding: 0;
        min-width: 0;
        width: 2.2rem;
        font-size: 2rem;
      }

      &.normal {
        color: $color-dark;
        background: $color-grey-light;
      }

      &.favorite {
        color: $color-primary;
        background: $color-grey-light;
      }

      &.primary {
        color: $color-primary;
        background: rgba($color-primary, 0.15);
      }

      &.accent {
        color: $color-accent;
        background: rgba($color-accent, 0.15);
      }

      &.secondary {
        color: $color-secondary;
        background: rgba($color-secondary, 0.15);
      }

      &.hide {
        display: none;
      }

      &.mdc-tab--active {
        color: $color-dark;
        background: $color-light;
      }

      .mdc-tab-indicator {
        @include mdc-tab-indicator-underline-height(0);
      }
    }
  }

  // For ISP only
  &--package {
    border-bottom: none !important;
    display: flex;
    height: 56px;
    overflow: hidden;

    @media (min-width: 768px) {
      padding: 0 !important;
    }

    @media #{$desktop}, #{$tablet} {
      max-width: 1000px !important;
    }

    .mdc-tab-scroller {
      width: 100%;
      .mdc-tab-scroller__scroll-content {
        padding: 0;
      }
    }

    .mdc-tab-scroller__scroll-content {
      @media (max-width: 320px) {
        display: flex;
        margin: 0;
      }
    }

    .mdc-tab-scroller__scroll-area {
      height: 100%;
    }

    .tab-bar__tab {
      height: 56px;
      opacity: 1;
      text-transform: none !important;
      background: $color-gray-200;
      color: $color-gray-g7;
      column-gap: 10px;
      width: calc((100% / 3) - 3px);
      letter-spacing: 0;

      .mdc-tab__content {
        font-family: $font-header;
      }

      @media (max-width: 425px) {
        font-size: 1.2rem !important;
      }

      &.mdc-tab--active {
        color: $color-light;
        background: $color-primary-ISUZU;
        opacity: 1;
      }

      .mdc-tab-indicator {
        @include mdc-tab-indicator-underline-height(0);
      }
    }

    &--2 {
      .tab-bar__tab {
        &.mdc-tab--active {
          background: linear-gradient(38.42deg, #9F3B1B 0%, #C2826E 100%);
        }
      }
    }

    &--3 {
      .tab-bar__tab {
        &.mdc-tab--active {
          background: linear-gradient(38.42deg, #8A8A8C 0%, #B4B4B5 100%);
        }
      }
    }
  }

  &__tab {
    color: $color-dark;
    text-align: center;
    font-weight: $weight-bold;
    height: $tab-height;
    font-size: $font-base !important;
    text-transform: capitalize !important;
    padding-left: $el-pad/2 !important;
    padding-right: $el-pad/2 !important;

    .mdc-tab__content {
      font-family: $font-header;
    }

    &.mdc-tab--active {
      color: $color-primary;
    }

    &:hover {
      background: transparent;
    }

    // For ISP only
    &--0, &--1, &--2 {
      &:hover {
        background: $color-gray-g3;
        opacity: 1;
      }
    }

    .mdc-tab-indicator {
      @include mdc-tab-indicator-underline-color($color-primary);
      @include mdc-tab-indicator-underline-height(2px);
    }

    .mdc-tab__ripple {
      @include mdc-states-base-color($color-primary);
      @include mdc-states-hover-opacity(0);
      @include mdc-states-focus-opacity(0);
      @include mdc-states-press-opacity(0.05);
    }
  }

  &__signin-tab {
    color: $color-grey;
    text-align: center;
    font-weight: $weight-bold;
    height: $tab-height;
    font-size: $font-base !important;
    text-transform: capitalize !important;
    padding-left: $el-pad/2 !important;
    padding-right: $el-pad/2 !important;
    border: solid 1px;
    border-color: $color-grey;
    border-radius: 3px;

    #mobile {
      display: none;
      @media (max-width: 425px) {
        display: inline;
      }
    }

    .mdc-tab-indicator {
      @include mdc-tab-indicator-underline-height(0px);
    }

    .mdc-tab__content {
      font-family: $font-header;
    }

    &.mdc-tab--active {
      background: $color-primary;
      color: $color-light;
      border-color: $color-primary;
    }

    .mdc-tab__ripple {
      @include mdc-states-base-color($color-primary);
      @include mdc-states-hover-opacity(0);
      @include mdc-states-focus-opacity(0);
    }
  }

  @media #{$desktop}, #{$tablet} {
    padding: 0;
    width: 100%;

    &--folder {
      .mdc-tab-scroller {
        padding-left: 0;
      }
      .tab-bar__tab {
        flex-grow: 0;
        padding-left: $el-pad/2 !important;
        padding-right: $el-pad/2 !important;
        margin-left: 0;
        margin-right: $el-margin;
      }
    }
  }

  @media #{$tablet} {
    margin: 0 auto;
    max-width: $wrapper-width;
    padding: 0 $el-pad;
  }
}

.signin .tab-bar {
  align-items: center;
  overflow: hidden !important;
  .mdc-tab-scroller__scroll-area--scroll {
    overflow: hidden;
  }
  button {
    margin: 1.7px;
    display: inline-block;
    width: 32%;
  }
  @media #{$desktop}, #{$tablet} {
    padding: 0px !important;
  }
}

.mdc-tab-scroller__scroll-content {
  display: flex;
  column-gap: 3px;
  flex: auto;
  @media (max-width: 425px) {
    padding: 0 10px;
  }
  @media (max-width: 320px) {
    display: content;
    flex: auto;
    text-align: center;
    margin: auto;
    width: 80%;
  }
}
