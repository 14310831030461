@import '../../../config/theme';

.PDPA {
  font-style: normal;
  font-size: 14px;

  &__header {
    font-weight: $weight-bold;
    font-size: 16px;
    line-height: 32px;
  }

  &__sub__header {
    font-weight: $weight-bold;
    font-size: 14px;
    line-height: 110%;
    color: $color-dark;
  }

  &__body {
    font-weight: $weight-normal;
    font-size: 14px;
    line-height: 120%;
    color: $color-dark;
  }

  .tab {
    margin-left: 10px;
  }

  .privacy_table{
    margin-bottom: $el-pad/3;
    max-width: 800px;

    table{
      width:80%;  
    }

    table, th, td {
      border: 1px solid black;
      font-size: 14px;
    }
    
    .objective-column {
      display: flex;
    }

    td{
      padding: 0px 0px 5px 5px;
    }

  }

}
