@import 'config/_theme.scss';

.isp-selection {
  &__body {
    margin: 1rem;
    padding: 1rem;
    background: $color-light;
    max-width: 60rem;

    @media (min-width: 640px) {
      margin: auto;
    }
  }

  &__header {
    display: flex;
    &__back {
      margin-right: auto;
    }
  }

  &__title {
    background-color: $color-primary;
    height: $el-height-sm * 1.5;
    text-align: center;
    border-radius: $el-rad;
    margin-bottom: $el-margin * 2;
    span {
      color: $color-light;
      line-height: 5rem;
      font-size: $font-base * 1.2;
    }
  }

  &__field {
    background-color: #f2f2f2;
    border-radius: $el-rad;
    span {
      font-size: $font-base;
      line-height: 5rem;
      padding: 10px;
    }
  }

  &__vehicle-detail {
    margin-bottom: $el-margin * 6;
    .input {
      &__input {
        background-color: #f2f2f2;
      }
    }
  }

  &__confirm {
    @media (max-width: 767px) {
      padding-bottom: 7rem;
    }
    button {
      width: 100%;
    }
  }
}

.isp-pack {
  margin: 1rem 0;
  display: flex;
  max-width: 60rem;

  &__header {
    display: flex;
    height: 60px;

    &__label {
      width: 80%;
      margin: auto;
    }

    &__image {
      width: 20%;
      height: 100%;
      margin: 0 $el-margin * 3;
      img {
        height: 100%;
      }
    }
  }

  &__isp {
    background: #f2f2f2;
    width: 100%;
    height: 10rem;
    position: relative;
    border-radius: $el-rad * 2;
    display: flex;
    text-align: center;

    &__img {
      width: 30%;
      height: 100%;
      img {
        max-height: 100%;
        display: block;
        @media (min-width: 768px) {
          margin: 0;
        }
      }

      @media (min-width: 768px) {
        width: unset;
        margin: auto;
      }
    }

    span {
      margin: auto;
    }

    &__content {
      margin: auto;
      width: 70%;

      @media (min-width: 768px) {
        width: unset;
        margin-left: 1rem;
      }
      span {
        font-size: 1.5rem;
        #isp-detail {
          color: $color-primary;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-term {
  background-color: rgba($color-dark, 0.5) !important;

  align-items: center;
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  overflow: hidden;
  position: absolute;
  z-index: $z-modal + 1;
  padding: 0;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: $el-rad;
  border: none;
  width: calc(100vw - 6rem);
  max-width: 50rem;
}

.consent-header {
  padding: 3rem;
  background: #e3e5e8;
  overflow: hidden;
  text-align: center;
  span {
    font-weight: $weight-bold;
  }
}
.consent_content {
  background: $color-light;
  padding-bottom: 0.5rem;
}
.consent_footer {
  background: $color-light;
  display: flex;
  align-items: center;
  flex-direction: row;
  button {
    width: 100%;
    border-radius: unset;
  }
}
.checkbox-section {
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  margin: 0rem;

  label {
    margin: 2rem 0rem 1rem 0rem;
  }
}
.terms-cond {
  display: block;
  height: 80%;
  overflow-y: auto;
  border: 1px solid $color-grey-light;
  padding: $el-pad/2;
  background: $color-light;

  h1,
  h2 {
    margin-bottom: $el-pad/2;
  }

  h3,
  h4 {
    margin-bottom: $el-pad/3;
  }

  p {
    margin-bottom: $el-pad;
    text-align: justify;
  }

  strong {
    font-weight: $weight-bold;
  }

  .terms-cond__ol-1 {
    list-style-position: outside;

    > li {
      margin-left: $el-pad/2;
      padding-bottom: $el-pad/2;

      > ol {
        list-style-position: outside;

        > li {
          margin-left: $el-pad/2;
          padding-bottom: $el-pad/2;
        }
      }
    }
  }

  ul {
    list-style-position: outside;
    margin-bottom: $el-pad;

    > li {
      margin-left: $el-pad;
    }
  }

  ul {
    list-style: disc;

    > li {
      margin-left: $el-pad/2;
    }
  }
  ol {
    display: block;
    list-style: decimal;
    list-style-position: inside;
    counter-reset: item;
  }
}
