@import "config/_theme.scss";


@mixin car {
  width: 11rem;
  height: auto;
  position: absolute;
  bottom: 7.5rem;
  @media (min-width: 280px) and (max-width: 300px) {
    width: 8rem;
    bottom: 12rem;
  }
  @media (min-width: 301px) and (max-width: 340px) {
    bottom: 10rem;
    width: 9rem;
  }
  @media (min-width: 341px) and (max-width: 374px) {
    bottom: 9rem;
    width: 9rem;
  }
  @media (min-width: 375px) and (max-width: 400px) {
    width: 11rem;
    bottom: 7.5rem;
  }
  @media (min-width: 401px) and (max-width: 440px) {
    bottom: 6.5rem;
  }
  @media #{$desktop}, #{$tablet}, (min-width: 441px) {
    bottom: 13rem;
  }
}

.detail-excellency {
  text-transform: uppercase;
  height: 100%;
  padding-bottom: $nav-height + $el-pad;
  max-width: 50rem;
  margin: 0 auto;

  button {
    margin: 0.5rem 0 1rem 0;
  }

  &__header {
    position: absolute;
    height: auto;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, 0);

    img {
      max-width: 100%;
      max-height: 4rem;
    }
  }

  .excellency-btn, .excellency-vehicle-btn {
    position: absolute;
    top: .3rem;
    height: auto;
    right: 2%;

    @media #{$desktop}, #{$tablet} {
      top: 7rem;
      right: 15%;
    }
  }

  .excellency-vehicle-btn {
    @media (max-width: 425px) {
      top: $el-margin*2;
    }
  }

  &__image {
    position: relative;
    text-align: center;
    margin-top: 1rem;
    display: flex;

    img {
      max-width: 100%;
      max-height: 15rem;
      width: auto;
      margin: 0 auto;
    }
  }

  &__body {
    text-align: center;
    padding: 0.5rem .5rem;
    max-width: 55rem;
    margin: 0 auto;

    &__name {
      margin: 0.5rem 0;
      font-weight: $weight-bold;
    }

    &__info-1 {
      display: flex;
      justify-content: space-between;
      height: 100%;
      margin: 0 0 1rem 0;
      font-size: 1.3rem;
      text-transform: capitalize;
    }

    &__info-2 {
      height: 100%;

      img {
        width: 80%;
        max-width: 200px;
        height: auto;
      }
    }

    &__info-3 {
      display: flex;
      justify-content: space-around;
      padding: 1rem;

      &__remark {
        color: #444444;
        text-transform: none;
        margin-bottom: $el-margin * 4;
        font-size: 12px;
        line-height: 19px;
      }

      &__next-status {
        width: 50%;
        display: flex;
        flex-direction: column;

        &__header {
          padding: 1rem 0;
          height: auto;
          width: 100%;
          align-self: center;
          background-color: $color-grey-soft;
          font-weight: $weight-semi;
        }

        &__text {
          padding: 1rem 0;
          background-color: $color-grey-light;
          width: 100%;
        }
      }

      &__remaining {
        width: 40%;
        background-color: $color-grey-soft;
        display: flex;
        flex-wrap: wrap;

        &__header {
          padding: 0.25rem 0;
          height: 4rem;
          width: 100%;
          align-self: center;
        }

        &__text {
          padding: 0 0 0.25rem 0;
          background-color: $color-grey-light;
          width: 100%;
        }
      }
    }
  }
  &__is-platinum {
    img {
      width: 100%;
    }
  }
  @media #{$desktop} {
    &__header {
      top: 7rem;
    }
  }

  @media #{$tablet} {
    &__header {
      top: 7rem;
    }
  }

  &__button {
    display: flex;
  }

  i {
    margin: 1rem .5rem 1rem 0rem !important;
    svg {
      fill: $color-primary;
    }
  }
}

.infographic {
  display: block;
  height: 25rem;
  padding: 0;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  max-width: 55rem;

  @media #{$desktop}, #{$tablet}, (min-width: 441px) {
    height: 35rem;
  }

  &__wrapper {
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;

    &__next {
      position: absolute;
      top: $el-pad;
      left: $el-pad * 2;
      display: flex;
      opacity: 1 !important;

      &__icon {
        display: block;
        width: 4rem;
        height: 4rem;
        margin-right: $el-pad/2;
        img {
          max-width: 100%;
        }
      }

      &__text {
        position: absolute;
        top: $el-pad/2;
        left: $el-pad;
        h6 {
          color: $color-dark;
          font-size: $h6;
          margin-top: 0;
        }
        p {
          font-weight: $weight-semi;
        }
        span {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    .car {
      margin-bottom: $el-margin;
      &__no-excellency,
      &__0 {
        @include car;
        left: 1%;
        @media (min-width: 301px) and (max-width: 374px) {
          left: 2.5%;
        }
      }

      &__13 {
        @include car;
        left: 25%;

        @media (min-width: 280px) and (max-width: 300px) {
          left: 24.3%;
        }
        @media (min-width: 375px) and (max-width: 424px) {
          left: 24%;
        }
        @media #{$desktop}, #{$tablet}, (min-width: 441px) {
          left: 26%;
        }
      }

      &__14 {
        @include car;
        left: 19%;
        bottom: 7.5rem;
        @media (min-width: 280px) and (max-width: 300px) {
          left: 19%;
        }
        @media (min-width: 375px) and (max-width: 424px) {
          left: 18%;
        }
        @media #{$desktop}, #{$tablet}, (min-width: 540px) {
          left: 20%;
        }
      }

      &__12,
      &__24 {
        @include car;
        left: 37%;
        @media (min-width: 280px) and (max-width: 300px) {
          left: 36%;
        }
        @media (min-width: 301px) and (max-width: 374px) {
          left: 36%;
        }
        @media (min-width: 375px) and (max-width: 424px) {
          left: 35.5%;
        }
        @media #{$desktop}, #{$tablet}, (min-width: 441px) {
          left: 39%;
        }
      }

      &__23 {
        @include car;
        left: 49%;
        @media (min-width: 280px) and (max-width: 300px) {
          left: 47.5%;
        }
        @media (min-width: 301px) and (max-width: 375px) {
          left: 47%;
        }
        @media #{$desktop}, #{$tablet}, (min-width: 441px) {
          left: 52%;
        }
      }

      &__34 {
        @include car;
        left: 55%;
        @media (min-width: 280px) and (max-width: 300px) {
          left: 53.3%;
        }
        @media (min-width: 301px) and (max-width: 375px) {
          left: 53%;
        }
        @media (min-width: 425px) and (max-width: 500px) {
          left: 55.5%;
        }
        @media #{$desktop}, #{$tablet}, (min-width: 540px) {
          left: 58%;
        }
      }

      &__remaining {
        background-color: $color-light;
        opacity: 0.8;
        width: 65%;
        text-align: center;
        margin: 1rem auto;
        border-radius: 2rem;
        padding: .3rem;
        font-size: 1rem;
      }

      &__arrow {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 10%;
      }
    }

    .nodes {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 20.5rem;
      padding: 0 4rem;
      z-index: 5;
      @media (min-width: 280px) and (max-width: 300px) {
        height: 15rem;
        padding: 0 3rem;
      }
      @media (min-width: 301px) and (max-width: 340px) {
        height: 17rem;
      }
      @media (min-width: 341px) and (max-width: 374px) {
        height: 18rem;
      }
      @media (min-width: 375px) and (max-width: 400px) {
        height: 19.5rem;
        padding: 0 4.5rem;
      }
      @media (min-width: 425px) and (max-width: 440px) {
        height: 21rem;
        padding: 0 4.5rem;
      }
      @media #{$desktop}, #{$tablet}, (min-width: 441px) {
        height: 24rem;
        padding: 0 4.5rem;
      }

      &__node {
        height: 2.5rem;
        width: 2.5rem;
        z-index: 5;
        display: flex;
        justify-content: center;
        img {
          display: block;
          margin: auto;
        }
      }

      &__connecting-line {
        height: 0.2rem;
        width: calc(100% - 11rem);
        background-color: white;
        position: absolute;
        left: 5.9rem;
        bottom: 6rem;
        z-index: 1;
        @media (min-width: 280px) and (max-width: 300px) {
          bottom: 10.6rem;
          left: 5rem;
          width: calc(100% - 9rem);
        }
        @media (min-width: 301px) and (max-width: 340px) {
          bottom: 8.6rem;
          left: 5rem;
        }
        @media (min-width: 341px) and (max-width: 374px) {
          bottom: 7.6rem;
        }
        @media (min-width: 401px) and (max-width: 424px) {
          bottom: 5.1rem;
        }
        @media (min-width: 425px) and (max-width: 440px) {
          bottom: 4.5rem;
          left: 5rem;
        }
        @media #{$desktop}, #{$tablet}, (min-width: 441px) {
          bottom: 11.7rem;
        }
      }
    }

    .status {
      padding: 0.5rem 3rem 0;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
  }
}
