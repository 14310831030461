@import '../../../../config/theme';

.terms_section {
  height: 100%;
}
.terms-cond-setting {
  display: block;
  height: 80%;
  overflow-y: auto;
  border: 1px solid $color-grey-light;
  padding: $el-pad/2;
  background: $color-light;

  &__footer {
    text-align: center;
  }
  h1,
  h2 {
    margin-bottom: $el-pad/2;
  }
  h2 {
    font-size: 2rem;
  }

  h3,
  h4 {
    margin-bottom: $el-pad/3;
  }
  h3 {
    font-size: 1.8rem;
  }

  p {
    margin-bottom: $el-pad/5;
  }

  strong {
    font-weight: $weight-bold;
  }

  .terms-cond__ol {
    list-style-position: outside;
    list-style: none;
    padding-left: 0;

    > li {
      padding-bottom: $el-pad/2;

      > ol {
        list-style-position: outside;
        list-style: none;
        padding-left: $el-pad/2;

        > li {
          margin-left: $el-pad/4;
          padding-bottom: $el-pad/5;
          padding-top: $el-pad/5;

          > ol {
            padding-left: $el-pad/2;
          }
        }
      }
    }
  }

  ul {
    list-style-position: outside;
    margin-bottom: $el-pad;

    > li {
      margin-left: $el-pad;
      margin-bottom: $el-pad/5;
    }
  }

  ul {
    list-style: disc;
  }
  ol {
    display: block;
    list-style: decimal;
    list-style-position: inside;
    counter-reset: item;
  }

  .terms-cond__ul-1 {
    list-style: none;
    padding-left: 0;

    &.dist {
      list-style: disc;
      margin-left: 3rem;
    }

    > li {
      margin-left: 0;
    }
  }
}
