@import 'config/_theme.scss';

.detail-isp {
  height: 100%;
  padding-bottom: $nav-height + $el-pad;
  width: 100%;

  &__header {
    position: absolute;
    height: auto;
    top: 1rem;
    left: 50%;
    transform: translate(-55%, 0);

    img {
      max-width: 100%;
    }

    @media #{$desktop},#{$tablet} {
      top: 7rem;
    }
  }

  &__isp {
    @media #{$desktop} {
      max-width: $wrapper-width/2;
      margin: 0 auto;
    }
    @media #{$tablet}{
      max-width: calc(100% - (#{$el-pad}* 2));
      margin: 0 auto;
    }

    &__header {
      position: relative;
      text-align: center;
      margin: $el-pad/3 auto;

      img {
        max-width: 100%;
      }
    }
    &__logo {
      position: relative;
      text-align: center;
      margin: $el-pad/3 auto;

      img {
        max-height: 5rem;
        width: auto;
      }
    }

    &__name {
      font-size: 3.2rem;
      text-align: center;
      display: -webkit-flex;
      display: block;
      flex-wrap: nowrap;
      padding: 0 0.2rem;
      margin-bottom: 1rem;
      color: $color-primary;
    }

    &__vehicle {
      position: relative;
      text-align: center;
      display: flex;
      flex-wrap: wrap;

      img {
        max-width: 100%;
        max-height: 15rem;
        width: auto;
        margin: 1rem auto 1rem;
      }

      &__name,
      &__license {
        width: 100%;
        margin-top: 0.25rem;
      }
      &__remark {
        margin: auto;
        span {
          color: $color-primary;
        }
      }
    }

    &__package {
      font-size: 1.2rem;
      text-align: center;
      display: -webkit-flex;
      display: block;
      flex-wrap: nowrap;
      padding: 0 0.2rem;
      margin-bottom: 3rem;
      margin-top: 3rem;

      &__detail {
        display: flex;
        flex-direction: column;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding: 0 0.1rem;
        box-sizing: border-box;

        &__box {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .pkg-header {
          padding: 0.5rem;
          background-color: $color-primary;
          color: $color-light;
          font-weight: $weight-semi;
          display: flex;
          min-height: 3.5rem;
          width: 40%;
        }

        .pkg-text {
          padding: 0.5rem;
          background-color: $color-light;
          min-height: 3rem;
          width: 40%;
        }
      }
    }

    &__privilege {
      position: relative;
      text-align: center;
      margin: 1rem auto;

      img {
        width: 80%;
      }
    }

    &__expiry {
      position: relative;
      text-align: center;
      margin: 1rem auto;
    }

    &__topup {
      position: relative;
      text-align: center;
      margin: 1rem auto;
      cursor: pointer;

      img {
        width: 90%;
      }
    }
  }

  .isp-btn {
    position: absolute;
    top: 1rem;
    right: 5%;

    @media #{$desktop} {
      top: 7.5rem;
      right: calc((100% - #{$wrapper-width})/2);
    }

    @media #{$tablet} {
      top: 7.5rem;
      right: 3rem;
    }
  }
}

.non-isp {
  position: relative;
  text-align: center;
  padding-top: 1rem;

  @media #{$tablet}, #{$desktop} {
    max-width: $wrapper-width/2;
    margin: 0 auto;
  }
  @media #{$tablet}{
    max-width: calc(100% - (#{$el-pad}* 2));
    margin: 0 auto;
  }

  img {
    width: 100%;
    margin: 0 auto 2rem;
  }
}

.warranty-reamark {
  font-size: 10px;
}