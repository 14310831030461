@import '../../../config/theme';

.guide-item {
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$desktop}, #{$tablet} {
    width: 40%;
  }

  img {
    max-width: 80%;
    height: auto;
  }
}