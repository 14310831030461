@import 'config/_theme.scss';

.detail-vehicle-form {
  padding-bottom: $el-pad + $nav-height;
  height: 100vh;
  .detail-vehicle-form-wrapper {
    padding-bottom: $el-pad + $nav-height;
    width: calc(100% - (#{$el-pad}* 2));
    margin: 0 auto;
  }

  &__image {
    display: block;
  }

  .detail-vehicle-form__form {
    > .detail-vehicle-form__body__image {
      display: none;
    }
  }

  &__body {
    @media #{$desktop} {
      width: 50%;
    }
    p {
      margin-bottom: $el-pad;
      font-weight: $weight-semi;
      line-height: $lh;
    }

    .input {
      margin-bottom: $el-pad;
    }

    .input-upload {
      display: none;
    }

    > .detail-vehicle-form__body__image {
      display: block;
    }

    &__detail {
      &__name {
        display: flex;
        .input {
          width: 70%;
        }
      }

      &__info {
        .input__container {
          border: 1px solid $color-grey-light;
          border-radius: $el-rad;
        }
      }

      &__label {
        margin-top: $el-pad;
      }

      &__images_wrapper {
        display: flex;
        margin-top: $el-pad;
        flex-wrap: wrap;
        gap: 1%;
        @media #{$phone-sm} ,#{$phone-sm-pt},#{$phone-sm-ls},#{$phone-md},#{$tablet} {
          display: grid;
          grid-template-columns: repeat(3, 30.5%);
          grid-template-rows: repeat(2, 50%);
          gap: 5%;
        }
        &__img {
          cursor: pointer;
          text-align: center;
          @media #{$desktop} {
            max-width: 20%;
          }
          @media #{$phone-sm} ,#{$phone-sm-pt},#{$phone-sm-ls},#{$phone-md},#{$tablet} {
            margin: auto;
          }
          img {
            height: 7.5rem;
            width: 7.5rem;
            margin: auto;
            object-fit: cover;
            @media (min-width: 375px) {
              height: 9.5rem;
              width: 9.5rem;
            }
            @media (min-width: 425px) {
              height: 11rem;
              width: 11rem;
            }
            @media #{$desktop} {
              height: 8.5rem;
              width: 8.5rem;
            }
            &.isCover {
              border: 0.2rem solid $color-primary;
            }
          }
          button {
            width: 100%;
          }
        }
      }
    }

    &__button {
      display: flex;
      justify-content: space-between;
      .button {
        width: 48%;
        margin-top: $el-pad;

        &:disabled {
          background: $bg;
        }
      }
    }

    &__image {
      height: 50%;
      width: 50%;
      p {
        margin-bottom: $el-pad;
        font-weight: $weight-semi;
        line-height: $lh;
      }

      &__result {
        background: #f4f4f4;
        height: 50vh;
        align-items: center;
        display: flex;
        img {
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }

      &__action {
        display: flex;
        justify-content: space-between;
        margin-top: $el-pad/2;
        .input-upload {
          display: none;
        }

        button {
          width: calc(50% - (#{$el-pad}/ 2)) !important;
          &:first-child {
            margin-right: $el-pad/2;
          }
        }
      }
    }
  }

  @media #{$desktop}, #{$tablet} {
    .detail-vehicle-form-wrapper {
      margin: 0 auto;
      max-width: $wrapper-width;

      .detail-vehicle-form__body > .detail-vehicle-form__body__image {
        display: none;
      }

      .detail-vehicle-form__form > .detail-vehicle-form__body__image {
        display: block;
        margin-top: 0;
        float: left;
        width: 40%;
        margin: 0 $el-pad 0 $el-pad;

        .button.camera {
          display: none;
        }

        .button.upload {
          display: block;
          width: 100% !important;
        }
      }

      .detail-vehicle-form__body {
        padding: 0;
        float: right;

        &:after {
          content: '';
          clear: both;
          display: block;
        }

        button {
          width: auto !important;
          padding-left: $el-pad;
          padding-right: $el-pad;
        }
      }
    }
  }

  @media #{$tablet} {
    .detail-vehicle-form-wrapper {
      margin: 0 auto;

      .detail-vehicle-form__form > .detail-vehicle-form__body__image {
        width: 40%;
      }

      .detail-vehicle-form__body {
        width: 50%;
      }
    }
  }
}

.camera-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-camera;
  background: $color-dark;

  .close-camera {
    position: absolute;
    bottom: $el-pad;
    right: $el-pad;
    background: $color-dark;
    border-radius: 100%;
    height: $el-height;
    width: $el-height;
    color: $color-light !important;
  }
}

.camera {
  width: 50%;
  @media #{$desktop}, (min-width: 1024px) {
    display: none;
  }
}

.modal_edit {
  width: 100%;
  border-radius: 0.5rem;
  background-color: #ffffff;
  align-items: center;
  button {
    width: 100%;
    border-radius: 20rem;
    border-color: black;
    height: 5rem;
  }

  .delete {
    span {
      color: red;
    }
  }

  .cancel {
    span {
      font-weight: 700;
    }
  }

  &.first {
    margin-bottom: 15rem;
  }
  &.notCover {
    margin-bottom: 10rem;
  }
}

.modal_confirm {
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.5rem;

  button {
    width: 50%;
    border-radius: 0.5rem;
  }

  .title {
    text-align: center;
    padding-top: 1rem;
  }

  .delete {
    span {
      color: red;
    }
  }

  .cancel {
    span {
      font-weight: 700;
    }
  }
}
.upload {
  width: 50%;
}

.modal-second {
  width: calc(100vw - 6rem);
  position: absolute;
  padding: 0;
  top: 50%;
  display: block;
  background-color: #ffffff;
  border-radius: 0.5rem;
  width: 100%;
}

.blur_licence_plate_modal {
  &.icon {
    margin-bottom: 2rem;
  }

  &_image {
    max-width: 90%;
    max-height: 50%;
  }
  .col {
    text-align: center;
  }
  &_check_button {
    height: 100%;
    margin: 0 0 0 0;
    svg {
      width: 5rem;
      height: 5rem;
    }

    &::after {
      background-color: unset;
    }
    &::before {
      background-color: unset;
    }
  }

  &__notBlur {
    margin-top: 5rem;
    height: 17px;
  }

  .canvas-image {
    width: 100%;
    .hint_plate {
      position: relative;
      bottom: 64px;
    }
  }
}
