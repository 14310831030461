@import "config/_theme.scss";


.privileges-conditions {
  height: 100%;
  padding-bottom: $nav-height + $el-pad;
  max-width: 50rem;
  margin: 0 auto;

  &__title {
    text-transform: uppercase;
    font-weight: $weight-bold;
    text-align: center;
    position: absolute;
    top: 2rem;
    right: 0;
    left: 0;
    width: auto;
  }

  &__header {
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 $el-pad/2 0;

    &__btn-left {
      height: 100%;
      position: absolute;
      left: 1rem;
    }

    &__btn-right {
      height: 100%;
      position: absolute;
      right: 1rem;
    }

    &__img {
      width: 80%;
      height: auto;
    }
  }

  &__body {
    height: 100vh;
    display: flex;
    justify-content: center;

    &__img {
      flex-grow: 1;
      height: 100%;
    }
  }

  @media #{$desktop} {
    &__title {
      top: 8rem;
    }
  }

  @media #{$tablet} {
    &__title {
      top: 8rem;
    }
  }
}

.privSwiper {
  display: flex;
  flex-wrap: wrap;

  &__header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    &__img {
      align-self: center;
      width: 45%;
      height: auto;
    }

    &__prev, &__next {
      flex: 1;
      position: relative;
      top: -20px;
    }
  }

  &__body {
    width: 100%;
  }
}

.swiper {
  &__nav {
    position: absolute;
    top: 10rem;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__next,
    &__prev {
      z-index: $z-slider;
      width: 3rem;
      height: 3rem;

      i {
        color: $color-grey;
      }
    }

    &__next {
      right: -2rem;
    }

    &__prev {
      left: -3rem;
    }
  }
}
