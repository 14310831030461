@import '../../assets/fonts/fonts.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '../../config/theme';
@import './reset';
@import './icons';

// Base font-size for scalling
html {
  font-size: 62.5% !important;
}

body {
  font-size: $font-base;
  font-family: $font-body;
}

// Wrapper
.wrapper {
  max-width: 100%;
  position: relative;
  top: $top-height;
  min-height: 100vh;
  line-height: $lh;

  &.wrapper--no-auth {
    top: $top-height;
    background: $bg;
  }
  &.wrapper--bg {
    top: $top-height;
    background: $bg;
  }
  &.wrapper--full-page {
    position: unset;
  }
  // Standalone page (e.g., Terms and Conditions page) must be white empty page
  // filled with content.
  &.wrapper--standalone-page {
    background-color: $color-light;
  }

  @media #{$desktop}, #{$tablet} {
    height: 100%;
    top: 0;
    padding-top: $top-height-desk;
    min-height: calc(100vh - #{$top-height-desk + $nav-height-desk});

    &.wrapper--no-auth {
      padding-top: 0;
      top: $top-height-desk;
    }
  }
}

.desktop {
  @media #{$desktop} {
    &.desktop--invite-controller {
      .desktop__wrapper {
        .desktop__wrapper__container {
          .wrapper {
            top: $el-pad * 2;
          }
        }
      }
    }

    &.desktop--no-auth--signin {
      background-size: cover;
      position: fixed;
      width: 100%;

      .desktop__wrapper {
        width: 100%;

        .desktop__wrapper__container {
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
          margin: 0 auto;

          .wrapper {
            top: 0;
            min-height: 100vh;

            &__route {
              height: 860px;
            }

            .top {
              position: static;
            }
          }
        }
      }
    }
  }

  @media #{$tablet} {
    &.desktop--no-auth {
      background-size: cover;
      background-color: $color-grey;

      .desktop__wrapper {
        max-width: calc(100% - (#{$el-pad} * 2));
        margin: 0 auto;

        .desktop__wrapper__container {
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
          width: 54rem;
          margin: 0 auto;

          .wrapper {
            top: 0;
            min-height: 100vh;

            .top {
              position: static;
            }
          }
        }
      }
    }
  }

  &.desktop--no-auth--signup {
    background: none;
    .desktop__wrapper {
      max-width: none;
      .desktop__wrapper__container {
        width: 100%;
        .wrapper.wrapper--no-auth {
          background: none;
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
  font-weight: $weight-bold;
}
strong {
  font-weight: $weight-bold !important;
}

h1 {
  font-size: $h1;
}
h2 {
  font-size: $h2;
}
h3 {
  font-size: $h3;
}
h4 {
  font-size: $h4;
}
h5 {
  font-size: $h5;
}
h6 {
  font-size: $h6;
}

blockquote {
  padding-left: $el-pad;
  border-left: 2px solid $color-grey;
  margin-bottom: $el-pad;
}

.ReactModal__Body--open {
  position: static;
  width: 100%;
  height: 100%;
}

.ReactModal__Html--open {
  overflow-y: hidden;
}

.ReactModalPortal {
  position: fixed;
  z-index: 999 !important;
}

.content-setting {
  background-color: $bg-nav;
  padding-top: 5rem;

  height: 100vh;
  &__detail {
    @media #{$desktop} {
      max-width: $wrapper-width;
    }
  }
}

.container-setting {
  max-width: 120rem;
  justify-content: center !important;
  margin: auto !important;
}

body.install-app-bar-visible {
  .top {
    top: $install-app-bar-height;
  }
  .wrapper {
    top: calc(#{$top-height} + #{$install-app-bar-height});
    @media #{$desktop}, #{$tablet} {
      top: 4rem;
    }
  }
}

.desktop {
  &__menu__nav {
    display: flex;
    @media #{$desktop}, #{$tablet} {
      display: none;
    }
  }
}
