@import 'config/_theme.scss';

.collap {
  font-size: 15px;
  &--header {
    padding: $el-pad/3;
    font-size: 16px;
    font-weight: bold;
    background: #f6f6f6;
    box-shadow: inset 0px -1px 0px #ebebeb;
  }

  &--body {
    padding: $el-pad/2;
    box-shadow: inset 0px -1px 0px #ebebeb;
  }

  &--container {
    padding: $el-pad/2 0 0 0;
  }

  &--detail {
    color: $color-gray-g9;
  }

  &--table {
    width: 100%;
    margin-top: $el-margin;
    th, td {
      border: 1px $color-gray-g9 solid;
    }
    &-column-check {
      text-align: center;
    }
    &-column-label {
      padding-left: $el-margin;
    }
  }
}

.FAQ-term {
  margin-top: $el-pad;
}

.Collapsible__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}