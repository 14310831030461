@import "../../config/theme";

.page404{
  height: 400px;
  padding-top: $el-pad/2;
  margin: 3rem auto;
  text-align: center;
  color: $color-grey;
  font-weight: $weight-bold;
  font-size: 2rem;

  &__pic{
    width: 80%;
    height: 200px;
  }
}

