@import 'config/_theme.scss';

.vehicle-action {
  width: 100%;
  background: $color-dark;

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: $wrapper-width;
    margin: 0 auto;
  }

  &__left {
    width: 3rem;
    display: flex;
    align-items: flex-start;

    button {
      height: 4.5rem;
      padding: 0;
      width: 3rem;
      min-width: 3rem;
      color: $color-light !important;
      background: none;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: calc(100% - 3rem);

    &__assign,
    &__unassign {
      background: $color-grey !important;
      margin-left: $el-margin;
    }

    &__clear {
      background: $color-primary !important;
      margin-left: auto;

      &--icon {
        margin-left: -0.5rem;
        margin-right: -3rem;

        i {
          color: $color-primary;
        }

        @media #{$breakpoint-md-down} {
          display: none;
        }
      }
    }

    &__label {
      padding: 0.5rem;
      color: $color-grey-light;
    }

    @media #{$breakpoint-md-down} {
      padding: 0.8rem 0.5rem 0.5rem 0;

      &__label {
        order: 1;
        width: 100%;
        font-size: $label;
      }
    }
  }

  @media #{$tablet} {
    &__wrapper {
      max-width: calc(100% - (#{$el-pad}* 2));
      margin: 0 auto;
    }
  }
}
