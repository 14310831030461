@import 'config/_theme.scss';

.promotion-item{
  &--is-list{
    padding: 0;

    .promotion-item__list{
      padding: 0 0 $el-pad/2 0;
      background: $bg;
      width: 100%;
      display: flex;
      flex-direction: row;

      &__img{
        margin-left: $el-pad;
        margin-right: $el-pad/2;
        flex: 0 0 10rem;
        overflow: hidden;
        position: relative;

        img{
          height: auto;
          max-height: 100%;
          width: auto;
          max-width: 100%;
        }
      }

      &__desc{
        h6{
          font-size: $label;
          color: $color-grey;
          text-transform: uppercase;
          margin-bottom: $el-margin;
        }

        p{
          font-size: $font-base;
          font-weight: $weight-semi;
        }

        flex: 1 1 auto;
        padding: 0;
        margin-right: $el-pad;
      }
    }
  }

  &__list{
    display: flex;
    flex-direction: column;

    &__img{
      margin: 0;
      width: 100%;
      margin-bottom: $el-pad/2;
      flex: 0 0 15rem;
      overflow: hidden;
      position: relative;

      img{
        position: absolute;
        left: 50%;
        top: 50%;
        height: auto;
        max-height: 100%;
        width: auto;
        transform: translate(-50%,-50%);
        max-width: none;
      }
    }
  }

  @media #{$desktop}, #{$tablet}{
    &__body{
      max-width: $wrapper-width;
    }

    &--is-list{
      .promotion-item__list{
        flex-direction: column;
  
        &__img{
          margin: 0;
          width: 100%;
          margin-bottom: $el-pad/2;
          flex: 0 0 15rem;

          img{
            min-width: auto;
          }
        }
  
        &__desc{
          flex: 1 1 auto;
          padding: 0;
          margin-right: $el-pad;
        }
      }
    }
  }
}