@import 'config/_theme.scss';
@import '@material/react-checkbox/index';
@import '@material/ripple/mixins';

.checkbox{
  @include mdc-checkbox-ink-color($color-light);
  @include mdc-checkbox-focus-indicator-color($color-primary);
  @include mdc-checkbox-container-colors($color-grey, transparent, $color-primary, $color-primary);

  &.mdc-ripple-upgraded{
    @include mdc-states-base-color($color-primary);
    @include mdc-states-hover-opacity(0);
    @include mdc-states-focus-opacity(0);
    @include mdc-states-press-opacity(.05);
  }
}