@import '../../../../config/_theme.scss';

.repair {
  padding-bottom: $nav-height;
  height: calc(100vh + 6.7rem);
  @media #{$desktop}, #{$tablet} {
    padding-bottom: 0;
    height: 100vh;
  }
  li span {
    position: relative;
    left: -7px;
  }
}

.repair-detail {
  margin: auto;
  background-color: $color-light;
  @media #{$desktop} {
    width: 800px;
  }
  &__header {
    width: 100vw;
    height: 40px;
    background: $color-primary;
    color: $color-light;
    font-weight: bold;
    font-size: 15px;
    display: table-cell;
    vertical-align: middle;
    span {
      margin-left: $el-pad / 2;
      @media #{$desktop} {
        margin-left: $el-pad;
      }
    }
  }
  &__body {
    margin: $el-pad / 2;
    align-items: center;
    font-size: 16px;
    @media #{$desktop}, #{$tablet} {
      margin: $el-pad;
    }
    &__top {
      margin-bottom: $el-pad / 2;
      &__detail {
        display: flex;
        span {
          width: 40%;
          @media #{$desktop}, #{$tablet} {
            width: 15%;
          }
        }
        .service-center,
        .mileage,
        .payment_type {
          width: 75%;
        }
      }
    }
    &__line {
      border: 1px solid $color-gray-g3;
      margin-left: -$el-pad / 2;
      margin-right: -$el-pad / 2;
      @media #{$desktop}, #{$tablet} {
        margin-left: -$el-pad;
        margin-right: -$el-pad;
      }
    }

    &__item {
      margin-top: 2rem;
      &__header {
        font-weight: bold;
      }
      &__pending {
        padding-bottom: $el-pad * 3;
        @media #{$desktop}, #{$tablet} {
          padding: unset;
        }

        li {
          font-size: 14px;
        }

        li::marker {
          color: $color-primary;
        }
      }
    }
  }
  &__total-price {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: $bg-nav;
    z-index: 100;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
    padding: $el-pad/2 0;
    span {
      margin: auto;
    }

    @media #{$desktop}, #{$tablet} {
      width: unset;
      margin: $el-pad;
      padding: unset;
      z-index: 0;
      position: relative;
      background-color: unset;
      box-shadow: unset;
    }

    &__text-net,
    &__text-vat,
    &__value-net,
    &__value-vat {
      margin: auto $el-pad / 2;
      font-size: 14px;
      color: $color-gray-g9;
      @media #{$desktop}, #{$tablet} {
        margin: unset;
      }
    }

    &__text-total {
      margin: auto $el-pad / 2;
      display: flex;
      i {
        display: flex;
      }

      .info-icon {
        margin-left: $el-margin / 2;
        .button {
          position: absolute;
          top: -8px;
          right: 2px;
          color: $color-gray-900;
          @media #{$desktop} {
            display: none;
          }
        }
      }

      .info-tooltip {
        background: #ffffff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        opacity: 1 !important;
        display: flex;
        padding: unset !important;
        @media #{$phone-sm}, #{$phone-sm-pt}, #{$phone-sm-ls}, #{$tablet} {
          top: calc(100% - 270px) !important;
          left: 15px !important;
        }

        @media #{$tablet} {
          top: calc(100% - 655px) !important;
          left: 30px !important;
        }

        .mobile-tablet {
          @media #{$desktop} {
            display: none;
          }
        }

        .desktop {
          @media #{$phone-sm}, #{$phone-sm-pt}, #{$phone-sm-ls}, #{$tablet} {
            display: none;
          }
        }
      }

      .info-detail {
        width: 250px;
        font-size: 15px;
        padding: $el-pad/2 $el-pad $el-margin * 2 $el-pad/2;
      }

      @media #{$desktop}, #{$tablet} {
        margin: unset;
      }
    }

    &__value-total {
      margin: auto $el-pad / 2;
      @media #{$desktop}, #{$tablet} {
        margin: unset;
      }
      font-size: 20px;
      font-weight: bold;
    }

    &__net,
    &__vat,
    &__total {
      display: flex;
      justify-content: space-between;
    }
  }
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  left: 37.5% !important;
  @media #{$desktop} {
    left: 50% !important;
  }
}

#tooltip-overlay {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-dark, 0.5) !important;
  pointer-events: visible;
  @media #{$desktop} {
    display: none;
  }
}
