@import 'config/_theme.scss';

.content-header {
  padding: $el-pad;
  background: $bg-header;
  overflow: hidden;

  &-home{
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 15px !important;
    padding-right: 15px !important;

    h4{
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      font-size: 1.7rem !important;
      margin-bottom: unset !important;
    }

    h6{
      padding-top: 15.5px !important;
      padding-bottom: 15.5px !important;
    }

  }


  &__social-media {
    margin: auto;
    max-width: 120rem;

    @media #{$phone-sm} ,#{$phone-sm-pt},#{$phone-sm-ls},#{$phone-md} {
      
    }
 
  }

  .content-header-wrapper {
    display: flex;
    max-width: 100%;
    margin: 0 auto;

    &__label {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__controllers {
      flex: 1;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color-primary;
        background: $color-light;
        padding: $el-pad / 2;
        border-radius: $el-rad;
        margin: 0 0.5rem;
        line-height: 1;
        width: 25%;

        &--number {
          font-weight: $weight-bold;
          font-size: $h4;
        }

        &--label {
          padding-top: 0.5rem;
          text-align: center;
        }
      }
    }

    .button-group {
      margin: auto -0.5rem auto auto;

      button {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .button-group--download {
        border-radius: $btn-rad;
        width: 133px;
        font-weight: bold;
      }

      &--download {
        display: none;
      }
    }
  }

  h4 {
    font-size: $h4;
    // margin-bottom: $header-margin;
  }

  p {
    font-size: $font-base;
    line-height: $lh;
  }

  @media #{$desktop} {
    .content-header-wrapper {
      max-width: $wrapper-width;
    }

    .button-group {
      &--download {
        display: inline-block !important;
      }
    }
  }
}
