@import 'config/_theme.scss';

.select {
  height: $el-height-sm;
  white-space: nowrap;
  position: relative;
  margin-top: 5px;

  &.open-top {
    .select__menu {
      top: auto !important;
      bottom: 100% !important;
    }
  }

  &__control {
    min-height: $el-height-sm !important;
    height: $el-height-sm !important;
    border-color: $color-light !important;
    background: $color-light !important;

    &--is-focused {
      border-color: $color-grey-soft !important;
      box-shadow: none !important;
    }

    &--is-disabled {
      background-color: $color-gray-200 !important;
      border: 1px solid $color-gray-g3 !important;
      color: $color-gray-900;
    }
  }

  &__value-container {
    padding: 0.5rem 1rem !important;
    // font-size: $label;
    // font-weight: $weight-semi;
    font-family: $font-header;
    color: $color-dark;
    height: $el-height-sm !important;
  }

  &__single-value {
    height: 100% !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding: 0.5rem 0.5rem 0.5rem 0 !important;
    color: $color-grey !important;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__option {
    bottom: 0;
    padding: 1rem 1rem !important;
    color: $color-dark !important;
    cursor: pointer !important;
    margin-left: -1px;
    font-size: $font-base !important;
    white-space: initial;

    &:hover {
      &:not(.select__option--is-selected) {
        background: lighten($color-primary, 45%) !important;
      }
    }

    &--is-selected {
      color: $color-light !important;
      background: $color-primary !important;
    }
  }

  &__menu {
    position: absolute !important;
    top: 35px !important;
    padding: 0 !important;
    border: none !important;
  }

  &__menu-list {
    margin: 0 !important;
  }

  &__option {
    padding: $el-pad/2;
    min-height: 0;
  }

  &__prefix-icon {
    position: absolute;
    top: 0.7rem;
    left: 0.5rem;
    max-height: 1.8rem !important;
    font-size: 1.8rem !important;
    color: $color-grey;
    z-index: 100;
    cursor: default;

    &.is-flipped {
      transform: rotateX(180deg);
    }

    &.is-clickable {
      cursor: pointer;
    }
  }

  &__has--prefix-icon {
    .select__value-container {
      padding: 0.5rem 1rem 0.5rem 2.5rem !important;
    }
  }
}