@import '../../config/theme';

.assign-controller {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - #{$top-height-desk + $nav-height-desk});

  & .content-header {
    flex-shrink: 0;
  }

  &__body {
    flex: 1;
    background-color: white;

    &__container {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      margin: auto;

      &__controllers {
        flex: 0;
        padding: 1rem 3rem;
        background-color: $bg-nav-desk;

        &__content {
          margin: auto;
          max-width: 120rem;
        }
      }

      &__vehicles-list {
        height: 0;
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        flex-grow: 1;

        @media #{$desktop}, #{$tablet} {
          padding: 1rem 3rem;
        }

        &--header {
          width: 100%;
          top: 0;
          margin: 0 auto;
          max-width: 111rem;
          padding: 1.5rem 4.5rem;
          font-weight: 700;

          &__item {
            display: inline-flex;

            &.name {
              width: 20%;
            }

            &.current-assignment-column,
            &.new-assignment-column {
              width: calc(40% - 1rem);
              padding-right: 1rem;
            }

            @media #{$phone-sm} {
              &.name {
                width: 30%;
              }

              &.current-assignment-column,
              &.new-assignment-column {
                width: calc(25% - 1rem);
                padding-right: 1rem;
              }
            }
          }
        }

        & .list {
          width: 100%;
          overflow-y: auto;

          .vehicle-item--list {
            height: auto !important;
          }

          @media #{$phone-sm} {
            &.name {
              width: 40%;
            }
          }
        }
      }

      &__actions {
        display: flex;
        flex: 0;
        justify-content: center;
        padding-bottom: 3rem;

        @media #{$phone-sm} {
          padding-bottom: 0;
        }

        button {
          width: auto;
          padding: 0 5rem;
          margin: 1rem;
        }
      }
    }
  }
}
