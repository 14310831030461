@import "config/_theme.scss";


.coupons {
  padding-bottom: $nav-height;

  .content-header {
    display: none;
  }

  &__header {
    z-index: 10;
    background: $bg;
    width: 100%;

    &__pager {
      display: flex;

      &__all-coupons {
        margin-left: 3rem;
        height: 3rem;
        align-self: center;

        @media (max-width:359px){
          height: 4rem;
        }
      }

      @media #{$desktop}{
        max-width: $wrapper-width;
        width: 100%;
        justify-content: flex-start;
        margin: 0 auto;
        &__all-coupons{
          margin-left: 0;
        }
      }
    }

    .list-pager {
      background: $bg;
      margin: 0 0 0 auto;
    }

    &__total {
      background: $bg;
      display: flex;
      align-items: center;
      height: $el-height;
      font-size: $label;
      color: $color-grey;
      font-weight: $weight-semi;
      letter-spacing: $label-spc;
      text-transform: uppercase;
      position: relative;

      span {
        margin-left: $el-pad;
      }
    }
  }

  &__body {
    position: relative;

    .ReactVirtualized__Grid__innerScrollContainer{
      padding-top: 2rem;
      height: unset !important;
      max-height: unset !important;
    }

  }

  &__body-no-coupon {
    height: calc(100vh - 21rem);
    align-items: center;
    display: flex;

    &__content {
      text-align: center;
      width: 100%;
      &__text {
        color: $color-grey;
        font-size: 2rem;
        margin-top: 20px;
      }
    }
  }

  .list {
    padding-bottom: $nav-height;

    .ReactVirtualized__List {
      padding: 0 $el-pad;

      .coupon-item {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    > div {
      height: auto !important;

      .ReactVirtualized__Grid {
        height: auto !important;
      }
    }
  }

  @media #{$desktop}, #{$tablet} {
    .content-header {
      display: block;
    }

    &__header {
      position: static;
    }

    &__body {
      max-width: $wrapper-width;
      margin: 0 auto;
      top: 0;
    }

    .list {
      > div {
        padding: 0;
      }
      .ReactVirtualized__List {
        padding: 0;
      }
      .ReactVirtualized__Grid__innerScrollContainer {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media #{$tablet} {
    &__body {
      margin: 0 auto;
    }
  }
}