@import '../../config/theme';

.user-guide {
  &__content {
    padding-top: $el-pad;
    // padding-bottom: $nav-height;

    .swiper-button-prev {
      left: 0rem !important;
    }
    .swiper-button-next {
      right: 0rem !important;
    }
  }

  .accordion {
    padding: $el-pad;
    padding-top: 0;
    margin-bottom: 3rem;

    &__header,
    .Collapsible {
      padding: $el-pad/2 0;
      border-bottom: $color-grey-soft;
      font-weight: $weight-semi;
    }

    .Collapsible__trigger {
      cursor: pointer;
    }

    .Collapsible__contentOuter {
      margin-top: $el-pad/2;
      font-weight: $weight-normal;
    }
  }

  @media #{$desktop} {
    &__content {
      margin: 0 auto;
      max-width: $wrapper-width;
    }
  }
}
