@import '../../config/theme';

.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  .signup--content-top {
    text-align: center;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    .signup--title {
      font-size: 40px;
      font-weight: $weight-bold ;
      max-width: 390px;
      @media #{$desktop} {
        max-width: none;
      }
    }
    .signup--separate {
      display: inline-block;
      padding: 10px 0;
      .signup--short-line-red {
        width: 32px;
        height: 3px;
        background: #E0001A;
      }
    }
    .signup--description {
      font-size: 20px;
      font-weight: $weight-bold ;
      max-width: 390px;
      @media #{$desktop} {
        max-width: none;
      }
    }
    .signup--click-to-download {
      font-size: 16px;
      color: #ED1C24;
      text-decoration: underline;
      font-weight: $weight-bold;
    }
    .signup--img-banner {
      padding: 30px 0;
      max-height: 220px;
    }
    img {
      display: inline-block;
    }
  }
  .signup--content-bottom {
    position: relative;
    width: 100%;
    text-align: center;
    max-width: 1980px;
    .signup--background-full-width {
      background: radial-gradient(50% 50% at 50% 50%, rgb(227, 15, 23) 0%, rgb(178, 5, 7) 100%);
      position: absolute;
      top: 40px;
      left: 0;
      bottom: 70px;
      right: 0;
      display: inline-block;
      .signup--background-painting {
        width: 100%;
        height: 100%;
        img {
          height: 100%;
        }
        .signup--img-rotate-180-deg {
          transform: rotate(180deg);
          margin-left: 140px;
        }
      }
    }
    .signup--img-detail {
      position: relative;
      display: inline-block;
      max-height: 365px;
    }
    .signup--img-detail-mobile {
      width: 100%;
      padding-top: 16px;
    }
  }

  &.signup--mobile {
    .signup--content-top {
      text-align: left;
      .signup--title {
        font-size: 32px;
        max-width: none;
      }
      .signup--description {
        font-size: 16px;
        max-width: none;
      }
    }
    .signup--content-bottom {
      max-width: 600px;
    }
  }
}