@import 'config/_theme.scss';

.detail-insurance {
  &-wrapper__details__body__details__field {
    width: 50%;

    &.detail-insurance__body {
      &__insurance-plan,
      &__insurance-expire {
        width: 50%;
      }
      &__contact-tpis {
        width: 100%;

        &__title {
          color: #1c1c1c;
          font-size: 1.68rem;
          width: 100%;
          text-align: center;
          margin-bottom: 1rem;
          font-weight: 700;
          text-transform: capitalize;
        }

        &__btn {
          width: 100%;
          display: flex;

          i {
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        button {
          width: 40%;
          height: 4rem;
          padding: 0 1rem;
          margin: auto;
          border-radius: 20px;
        }
      }

      @media #{$phone-md}, #{$phone-sm} {
        &__insurance-plan,
        &__insurance-expire {
          width: 100%;
        }
      }
    }
  }
}
