@import "../../config/theme";

.chat-page-wrapper {
  height: 100%;
  padding-bottom: $nav-height;
  margin-bottom: $nav-height;

  .content-header{
    display: none;
  }

  &__body {
    padding: $el-pad/2 $el-pad 0 $el-pad;

    &__header{
      margin-bottom: $el-pad;
    }

    &__line-btn {
      @media #{$desktop}{
        display: block;
      }
    }

    &__line {
      display: flex;
      margin: $el-pad/2 0;
      background-color: #00C300;
      color: white;
      border-radius: 5px;

      &--btn {
        height: $line-icon;
        width: $line-icon;
        flex: 0 0 auto;
        background-size: cover;
        background-position: 0 0;
        border-right: 1.5px solid #00b300;
      }

      &--dealer-name {
        margin: auto $line-icon;
        font-weight: $weight-bold;
        font-size: 1.5rem;
      }

      @media #{$desktop}{
        display: none;
      }
    }
    
    &__line:hover {
      background-color: #00E000;
    }

    &__line:active {
      background-color: #00B300;
    }

    &__line:hover .chat-page-wrapper__body__line--btn {
      border-right: 1.5px solid #00c900;
    }

    &__line:active .chat-page-wrapper__body__line--btn{
      border-right: 1.5px solid #009800;
    }

    &__qrcode {
      display: block;
      margin-bottom: $el-pad;
    }

    &__service-center {
      margin: $el-pad/2 0;

      &__links {
        padding: $el-pad/2 0;
        font-weight: $weight-semi;
        font-size: $h5;
        &--link {
          padding: $el-pad/4 0;
        }
      }
    }
    p {
      margin-bottom: $el-margin;
    }

    button {
      margin: 0 $el-margin $el-margin 0;
      width: auto !important;
    }
  }

  .social-media{
    padding: 0;
    margin-top: $el-pad*2;
  }

  
  @media #{$desktop}, #{$tablet}{
    .content-header{
      display: block;
    }
    
    &__body {
      margin: 0 auto;
      max-width: $wrapper-width;

      &__qrcode{
        display:block;

        .QRCode{
          margin-right: 1rem;
        }
        
        &__text{
          margin: $el-pad/2 0;
        }
      }
    }
  }
}

