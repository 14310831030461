@import '../../config/theme';

.create-account {
  padding-bottom: $el-pad;

  &__PDPA {
    background-color: $color-light;
  }

  &__action {
    margin: $el-pad/2 $el-pad;
    width: calc(100% - (#{$el-pad} * 2));
  }

  &__acception {
    display: inline;

    p {
      padding-left: $el-pad/3;
    }
  }

  &__form {
    padding: $el-pad;

    .input {
      margin-bottom: $el-pad/2;
    }

    &__phone {
      display: flex;
      align-items: flex-start;
      flex-direction: row;

      .input {
        width: 100%;

        &__label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__radio {
      margin-bottom: $label-margin;

      > div {
        display: flex;
        flex-direction: row;

        span {
          font-size: $font-base;
          font-weight: $weight-semi;
        }

        span[class^='MuiIconButton'] {
          color: $color-primary;
        }
      }
    }

    &__action {
      margin-top: $el-pad/2;
      display: flex;
      align-items: center;
      flex-direction: row;

      &__PDPA {
        margin-top: 40px;
        @media #{$desktop}, #{$tablet} {
          margin-top: 12rem;
        }
      }

      &__text {
        width: 100%;

        .terms_section > .terms-cond,
        .consent {
          height: 18rem;
        }

        &__header {
          padding-bottom: 5px;
          font-size: 1.8rem;
          font-family: 'Titillium Web', sans-serif;
          font-weight: 700;
        }
      }

      &__section {
        display: flex;
        align-items: center;

        width: 100%;

        .input__textarea {
          width: 100%;
        }

        .input {
          width: 100%;
        }

        &__legalStatement {
          width: 100%;
          overflow-y: auto;
        }
        &--half {
          display: flex;
          align-items: center;
          flex: 1 1 50%;
          margin: 0;
          font-weight: $weight-bold;

          button {
            width: 100%;
            display: block;
          }
        }
        &__remark {
          span, a {
            font-size: 12px;
            color: #444444;
            margin-left: $el-margin/2;
          }
        }
      }
    }

    &__otp {
      &__title {
        margin-bottom: $el-pad;
        h5 {
          margin-bottom: $el-pad/2;
        }
      }

      &__phone {
        margin-bottom: $el-pad/2;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__action,
        &__number {
          font-weight: $weight-bold;
          margin-right: $el-pad;
          word-break: break-word;
          span {
            font-size: $h5;
          }
        }

        &__action {
          &:nth-child(even) {
            margin-right: 0;
            button {
              color: $color-primary !important;
            }
          }
        }
      }

      &__instruction {
        margin-bottom: $el-pad;
      }

      &__timer {
        color: $color-primary;
        font-size: $h4;
        font-weight: $weight-semi;
        text-align: right;
      }

      &__input {
        margin-bottom: $el-pad;
      }
    }
  }

  .terms-cond {
    display: block;
    height: 30rem;
    overflow-y: auto;
    border: 1px solid $color-grey-light;
    padding: $el-pad/2;
    background: $color-light;

    h1,
    h2 {
      margin-bottom: $el-pad/2;
    }

    h3,
    h4 {
      margin-bottom: $el-pad/3;
    }

    p {
      margin-bottom: $el-pad;
      text-align: justify;
    }

    strong {
      font-weight: $weight-bold;
    }

    .terms-cond__ol-1 {
      list-style-position: outside;

      > li {
        margin-left: $el-pad/2;
        padding-bottom: $el-pad/2;

        > ol {
          list-style-position: outside;

          > li {
            margin-left: $el-pad/2;
            padding-bottom: $el-pad/2;
          }
        }
      }
    }

    ul {
      list-style-position: outside;
      margin-bottom: $el-pad;

      > li {
        margin-left: $el-pad;
      }
    }

    ul {
      list-style: disc;

      > li {
        margin-left: $el-pad/2;
      }
    }
    ol {
      display: block;
      list-style-position: inside;
      counter-reset: item;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 1.5rem 3rem;

    &__language {
      flex: 0 1 40%;
    }
  }

  @media #{$desktop} {
    &__form {
      margin: 0 auto;
      max-width: $wrapper-width;
    }
  }
}
