@import '../../../../config/theme';

.isp-status {
  margin: 20px auto;
  padding: 1rem;
  background: $color-light;
  max-width: 60rem;
  &__header {
    font-size: $h65;
    font-weight: bold;
  }
  &__button {
    margin-top: $el-margin * 2;
  }

  img {
    width: 100%;
    margin-bottom: $el-margin * 3;
  }
}

.auto-cancel {
  font-size: $h65;
  &__btn {
    span {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }
  }
}

.co-remark {
  span {
    span {
      color: $color-primary;
      font-weight: bold;
    }
  }
}

#isp-bold {
  font-weight: bold;
  color: $color-dark;
}