@import 'config/_theme.scss';

.card {
  border: 1px solid #d9dbdb;
  background-color: $color-light;
  border-radius: 1rem;
  display: flex;
  text-align: left;
  margin: $el-margin * 2;
  cursor: pointer;

  &__image {
    width: 40%;
    img {
      max-width: 75%;
      vertical-align: middle;
      padding: 3rem;
    }
  }

  &__detail {
    width: 50%;
    display: inline-block;
    margin: $el-margin * 2 $el-margin * 2 0;
    img {
      width: 120px;
      height: auto;
      margin: 1rem 0;
    }

    &__name {
      margin: 0.5rem 0;
      font-weight: $weight-bold;
    }
  }
}
