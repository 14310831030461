@import "config/_theme.scss";


.excellency-conditions{
  height: 100%;

  &__title{
    text-transform: uppercase;
    font-weight: $weight-bold;
    text-align: center;
    position: absolute;
    top: 2rem;
    right: 0;
    left: 0;
    width: auto;
  }

  @media #{$desktop}{
    &__title{
      top: 8rem;
    }
	}

	@media #{$tablet} {
    &__title{
      top: 8rem;
    }
	}
}