@import 'config/_theme.scss';

.company-add {
  max-height: 100%;
  // display: flex;
  background-color: white;

  &_header {
    font-size: 1.8rem;
  }
  &__content {
    background-color: white;
    // height: 80rem;
    min-height: 100vh;
    margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    background-color: white;

    .textField {
    }
  }
  &__list {
    // margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    // background-color: white;
    // @media (min-width: 1200px) {
    //   max-width: 120rem;
    // }

    // &__title {
    //   padding: 2rem 3rem;
    //   margin-top: 3rem;
    //   background-color: #F4F6F6;
    // }
    &__company {
      padding: 2rem 3rem;
      border-bottom: 0.2rem solid #dfe1e5;
    }
  }
  &__title {
    padding: 2rem 3rem;
    margin-top: 3rem;
    background-color: #f4f6f6;
  }

  &__header {
    background-color: #f4f6f6;
  }
  .col-title {
    text-align: center !important;
    margin: auto !important;

    @media #{$desktop} {
      max-width: 50%;
    }
    @media #{$tablet} {
      max-width: 60%;
    }
    @media #{$phone-md} {
      max-width: 70%;
    }
    @media #{$phone-sm} {
    }
  }
  .col-row {
    margin: 3.5rem !important;
  }
}

.submit-add-company {
  background-color: #f01516 !important;
  span {
    font-family: $font-header !important;
    color: white;
    font-size: 1.5rem;
  }
  width: 20rem;
  height: 5rem;
  border-radius: 2rem !important;
  
}

.submit-add-company-disable {
  background-color: #959595 !important;
  span {
    font-family: $font-header !important;
    color: white;
    font-size: 1.5rem;
  }
  width: 20rem;
  height: 5rem;
  border-radius: 2rem !important;
}
.textField-add-company {
  label {
    font-size: 1.4rem !important;
  }
}

#outlined-basic-helper-text {
  font-size: 1.2rem;
  font-family: $font-header !important;
}

.content-modal {
  width: 100%;

  &_row {
    width: 100%;
  }
  &_col {
    text-align: left;
    padding-top: 2rem;
    span: {
      padding: 2rem 1rem;
    }
  }
  &_alert {
    text-align: center;
    padding-top: 2rem;
    span: {
      padding: 2rem 1rem;
    }
  }
  &_alert2 {
    text-align: center;
    padding-top: 0rem;
    span: {
      padding: 2rem 1rem;
    }
  }
}

.MuiFormLabel-root-16.MuiFormLabel-focused-17 {
  color: $color-primary !important;
}

.MuiOutlinedInput-root-23.MuiOutlinedInput-focused-24 .MuiOutlinedInput-notchedOutline-30 {
  border: 1px solid $color-primary !important;
}
