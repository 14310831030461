@import 'config/_theme.scss';

.til-refinance {
  background-color: $color-light;
  height: calc(100% + 6.7rem);
  padding-bottom: $nav-height;

  @media #{$desktop}, #{$tablet} {
    height: 100%;
  }

  &__body {
    padding: $el-margin * 2;
    @media #{$desktop}, #{$tablet} {
      width: 640px;
      margin: auto;
    }

    &__box {
      width: 280px;
      background-color: $color-hover-red;
      margin: auto;
      border-radius: 15px;
      text-align: center;
      padding: $el-pad 0;

      &__icon {
        margin: auto;
        width: 70px;
        height: 70px;
        background: $color-light;
        border-radius: 50%;
        margin-bottom: $el-pad/2;

        &__lottie {
          padding-top: 10px;
        }
      }

      &__amount {
        color: $color-bright-red;
        font-size: $h4;
        font-weight: $weight-bold;
      }

      &__link {
        font-weight: bold;
        color: $color-bright-red;
        text-decoration-line: underline;
        cursor: pointer;
        border-color: transparent;
        background-color: transparent;
      }
    }

    &__title {
      font-weight: $weight-bold !important;
    }

    &__form {
      padding-top: $el-pad;

      .input {
        margin: $el-pad/2 0;
        &__label {
          display: flex;
          font-size: $font-base;
        }
        &__input {
          border: 1px solid $color-gray-400;
          font-family: $font-body;
        }
      }

      &__dropdown {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;

        .select {
          width: 48%;

          &__control {
            border-color: $color-gray-400 !important;
            height: 48px !important;
          }
        }
      }

      &__consent {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        margin: $el-pad 0 0 0;
      }

      &__check-consent {
        display: inline-block;
      }

      &__consent-message {
        display: inline-block;
        font-size: $nav-desk;
        color: $color-bright-red;
        width: 85%;
      }

      &__action {
        margin-top: $el-pad;
        text-align: center;
        vertical-align: middle;
        button {
          border-radius: 80px;
          width: 200px;
          @media #{$desktop}, #{$tablet} {
            width: 50%;
          }
        }
      }

      #require {
        color: $color-primary;
      }
    }
  }

  @media #{$desktop}, #{$tablet} {
    &__body {
      .til-refinance__body-wrapper {
        margin: 0 auto;
        max-width: $wrapper-width;

        .til-refinance__body__title {
          float: left;
          width: 36rem;
          margin-right: $el-pad * 4;
        }

        .til-refinance__body {
          width: calc(100% - (36rem + (#{$el-pad}* 4)));
          padding: 0;
          float: right;

          .input__tooltip--modal {
            display: none;
          }

          &:after {
            content: '';
            clear: both;
            display: block;
          }

          button {
            width: auto !important;
            padding-left: $el-pad;
            padding-right: $el-pad;
          }
        }
      }
    }
  }

  @media #{$tablet} {
    &__body {
      .til-refinance__body-wrapper {
        margin: 0 auto;
        max-width: $wrapper-width;

        .til-refinance__body {
          width: calc(100% - (36rem + (#{$el-pad}* 2)));
        }
      }
    }
  }
}