@import 'config/_theme.scss';
.add2home {
  height: $install-app-bar-height;
  background: $color-light;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: $z-top+1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &__wrapper {
    position: relative;
    background-color: $color-grey-dark;
  }

  .install__app {
    align-items: center;
    display: flex;
    text-align: center;
    padding-block: 5px;
    padding-inline: 15px;

    p {
      padding-top: 1px;
      color: $color-light;
      font-family: TIS;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;

      @media #{$tablet}, #{$desktop} {
        font-size: 15px;
      }
    }

    i {
      margin-inline: 15px;
      font-size: 0;
    }

    button.mdc-button {
      margin-left: 15px;
      border-radius: 8px;
      height: 30px;
      padding-inline: 5px;
      font-family: TIS;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: normal;
      padding-top: 1px;
    }
  }

  button {
    &::before, &::after {
      content: none;
    }
  }

  button.add2home__install__app__close__btn {
    position: absolute;
    top: 0;
    right: 0;
    min-width: auto;
    width: auto;
    height: 40px;
    margin: 0;
    padding: 0 15px;
    border: 0;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 0;
      margin: 0;
    }
  }

  .hidden {
    display: none;
  }

  @media #{$tablet}, #{$desktop} {
    .md\:block {
      display: block;
    }
    .md\:justify-center {
      justify-content: center;
    }
  }
}