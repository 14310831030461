@import 'config/_theme.scss';

$action-el-height: 3.5rem;

.controller-selector {
  display: flex;
  background-color: $bg-nav-desk;
  align-items: flex-start;
  flex-direction: column;

  @media #{$desktop}, #{$tablet} {
    flex-direction: row;
  }

  &__static {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 100%;

    @media #{$desktop}, #{$tablet} {
      width: auto;
    }

    &__back {
      flex-shrink: 0;

      & .button-back {
        height: $action-el-height;
      }
    }

    &__dropdown {
      align-items: flex-start;
      margin-left: 2rem;
      flex-grow: 1;
      flex-shrink: 0;

      @media #{$desktop}, #{$tablet} {
        width: 20rem;
        margin: 0 2rem;
      }

      & .select__control {
        height: $action-el-height !important;
        min-height: $action-el-height !important;
      }
    }
  }

  &__selectedList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-height: 8rem;
    overflow-y: auto;

    @media #{$phone-sm} {
      margin-top: 1rem;
    }

    span {
      flex-shrink: 0;
    }

    &__label {
      text-transform: uppercase;
      font-weight: $weight-semi;
      margin-right: 0.5rem;
    }

    & .chip {
      margin: 0.25rem;
    }
  }
}
