@import 'config/_theme.scss';

.list-pager{
  display: flex;
  justify-content: flex-start;
  max-width: $wrapper-width;
  margin: 0 auto;
  align-items: center;
  padding-right: $el-pad;
  background: $color-light;

  &__total{
    margin-left: auto;
    padding: 1.2rem 0;
  }

  .button--pager{
    margin-left: $el-margin;
  }

  @media #{$desktop}, #{$tablet}{
    padding-right: 0;
  }

  @media #{$tablet}{
    padding-right: $el-pad;
    padding-left: $el-pad;
  }
}