@import 'config/_theme.scss';

.vehicle-details-controllers {
  &__header {
    font-size: $label;
    color: $color-gray-800;
    letter-spacing: $label-spc;
    display: block;
    margin-bottom: $el-margin;
  }

  &__unassigned {
    &__value {
      margin-bottom: 1.5rem;
      margin-right: 1rem;
      font-size: 1.68rem;
      font-weight: normal;
      text-transform: capitalize;
      color: $color-dark;
    }
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;

    .chip {
      flex-shrink: 0;
      padding: 0;
      color: $color-grey;

      span {
        font-size: $font-base;
        font-weight: normal;
        color: $color-dark;
        word-break: break-word;
        text-decoration: none;
        margin: 0;
      }

      .button i {
        color: $color-grey;
      }
    }
  }

  &__action {
    display: flex;

    .select {
      flex: 1;
    }

    button {
      margin-top: $el-margin;
      height: 32px;
      font-weight: bold;
      font-size: 13px;
    }

    &__buttons {
      button {
        margin-left: 1rem;
      }
    }
  }
}
