@import 'config/_theme.scss';


.button-fav{
  background: rgba($color-dark, .05);
  box-sizing: border-box;
  border: 1px solid rgba($color-dark, .1) !important;
  display: flex;
  align-content: center;
  justify-content: center;
  border: none;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  position: absolute;
  outline: none;
  
  i {
    font-size: 2rem;
    color: rgba($color-dark, .3);
    align-self: center;
  }

  &--favorite{
    background: rgba($color-primary, .05);

    i{
      color: $color-primary !important;
    }
  }
}