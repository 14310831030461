@import 'config/_theme.scss';

.profile-edit {
  &-wrapper {
    padding: $el-pad $el-pad $nav-height $el-pad;
    margin-bottom: $nav-height;
    flex-direction: column;
    background-color: $color-light;
    height: 100vh;

    &__form {
      margin-top: $el-pad/2;
      .input {
        margin-bottom: $el-pad/2;
      }

      &__field {
        margin-bottom: $el-pad/2;
        div {
          width: 100%;
        }
        #require {
          color: $color-primary;
        }
      }

      &__action {
        display: flex;
        margin-top: $el-pad * 1.5;
        button {
          border-radius: 30px;
          height: 55px;
          width: 250px;
          margin: auto;
        }
      }

      &__phone {
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        .input {
          width: 100%;

          &__label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &__social-media {
      margin-top: $el-pad;
      padding-top: $el-pad;
      border-top: 1px solid $color-grey-soft;
      margin-bottom: $el-pad;

      p {
        font-size: $font-base;
        font-weight: $weight-bold;
        margin-bottom: $el-pad/2;
      }
    }
  }

  @media #{$desktop} {
    .profile-edit-wrapper {
      margin: 0 auto;

      .profile-edit-wrapper__form,
      .profile-edit-wrapper__social-media {
        max-width: 54rem;
        margin: 0 auto;
      }
    }
  }

  @media #{$tablet} {
    .profile-edit-wrapper {
      .profile-edit-wrapper__form,
      .profile-edit-wrapper__social-media {
        max-width: 54rem;
        margin: 0 auto;
      }
    }
  }
}
