@import 'config/_theme.scss';

.profile {
  font-size: $font-base;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;

  .profile-wrapper {
    overflow-x: unset;
  }

  .profile-bg-wrapper {
    background-color: $color-light;
    height: 100vh;
    padding-left: 1.5rem;
  }
  &__content {
    margin: 5rem auto;

    @media #{$desktop} {
      max-width: $wrapper-width;
    }
  }

  &__body {
    margin-top: 3rem;

    &__name {
      font-size: $h3;
      text-transform: capitalize;
      color: $color-dark;
      margin-bottom: $el-pad/2;
      font-weight: $weight-bold;
    }
    &__account-type,
    &__birthday {
      .value {
        text-transform: capitalize !important;
      }
    }
    &__office-num-ext {
      display: flex;

      .label:last-child {
        margin-left: $el-pad;
      }
    }
  }

  &__footer {
    &__links {
      text-decoration: none;
      font-weight: $weight-semi;
    }

    &--btn {
      text-align: left;
      min-width: 3rem;
      padding-left: 0;
    }

    button {
      background-color: none !important;
      text-align: left;
      color: $color-primary;
    }
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-grey;
    margin: 1em 0;
    padding: 0;
  }

  a {
    float: left;
    clear: left;
    margin-bottom: 1rem;
  }

  .label {
    display: block;
    font-size: $label;
    color: $color-grey;
    font-weight: $weight-semi;
    letter-spacing: $label-spc;
    margin-bottom: $el-margin;
  }

  .value {
    font-size: $h5;
    font-weight: $weight-bold;
    color: $color-dark;
    margin-bottom: $el-pad/2;

    .assign-button {
      margin-left: $el-margin;
    }

    .select {
      display: inline-block;
      min-width: 15rem;
    }
  }

  &__footer {
    padding-bottom: $el-pad * 2;
    overflow: auto;
  }

  hr {
    display: block;
  }

  @media #{$desktop}, #{$tablet} {
    .profile-wrapper {
      max-width: $wrapper-width;
      margin: 0 auto;
      display: flex;
      flex-direction: row-reverse;
    }

    hr {
      display: none;
    }

    &__footer {
      flex: 0 0 27rem;
    }

    &__body {
      flex: 1 1 auto;
    }
  }
}
