@import 'config/_theme.scss';

.search-dealer {
  height: 100%;
  padding: 0 $el-pad $nav-height + $el-pad;
  max-width: 120rem;
  margin: 0 auto;

  &__selection {
    margin: 1rem 0 2rem;
    padding: 1rem;
    background-color: $color-grey-light;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;

    > span {
      padding-bottom: 0 !important;
    }

    span {
      padding: 1rem;
      width: 100%;
    }

    &__checkbox {
      display: flex;
      align-items: center;
      flex: 1 1 50%;
      margin: 0;
    }

    &__dropdown {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin: 1rem 0;

      .select {
        width: 45%;
      }
    }

    .dealer-search-btn {
      width: 50%;
      margin: 1rem auto 0.5rem;
    }
  }

  &__result {
    margin: 1rem 0rem;
    padding: 1rem 2rem;
    background-color: $color-grey-light;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.modal__body__content {
  padding: 1.5rem !important;
  margin-top: 10px;
}

.dealer-modal {
  &__contacts {
    display: flex;
    justify-content: center;

    &__phone {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      height: 5rem;
      width: 5rem;
      background-color: $color-grey-soft;
      border-radius: 10px;
      text-decoration: blink;

      .material-icons {
        font-size: 4rem;
      }

      @media #{$desktop} {
        display: none;
      }
    }
    
    &__service-center {
      @media #{$desktop} {
        display: none;
      }
    }

    &__line {
      margin-left: 1rem;
      height: 5rem;
      width: 5rem;
      background-image: url('../../../../assets/images/btn_base.png');
      background-size: cover;
      cursor: pointer;

      &:hover {
        background-image: url('../../../../assets/images/btn_hover.png');
      }

      &:active {
        background-image: url('../../../../assets/images/btn_press.png');
      }

      @media #{$desktop} {
        display: none;
      }
    }

    &__qr {
      display: none;
      width: 100%;

      &__separator {
        position: relative;
        width: 0px;
        height: 15rem;
        border-left: 2px solid #b7b7b7;

        &--button {
          background-image: url('../../../../assets/images/btn_base.png');
          background-size: contain;
          width: 4rem;
          height: 4rem;
          position: absolute;
          margin: auto 0;
          top: 0;
          bottom: 0;
          border-radius: 50%;
          right: -18px;
        }
      }

      &__wrapper {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        padding: 0.25rem 0;
        width: 100%;
        box-sizing: border-box;
      }

      &__label {
        display: grid;

        button {
          margin-bottom: 5px;
          width: 100%;
        }

        span {
          margin-left: 0.5rem;
        }
      }

      @media #{$desktop} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__desktop-phone {
    padding-top: 1rem;
    display: none;

    @media #{$desktop} {
      display: flex;
      justify-content: flex-start;
    }
  }
  &__hours {

    &__header {
      font-weight: $weight_bold;
      font-size: $h5;
    }

    &__days {
      display: flex;
      justify-content: space-between;
      font-weight: $weight_normal;

      &--closed {
        color: red;
      }
    }
  }
}

.appointment-header {
  border-radius: 16px 16px 0 0;
  background: $color-light;
  height: $modal-height * 1.2;
  display: flex;
  align-items: center;
  text-align: center;
  
  &__text {
    font-weight: $weight-semi;
    align-items: center;
    padding: $el-pad/2;
    font-size: $font-base;
    width: calc(100% - 6.5rem);
    display: block;
    justify-content: center;
  }

  &__icon {
    display: inline-block;

    span {
      vertical-align: middle;
      color: $color-grey;
      font-weight: bold;
    }
  }
}

.appointment {
  display: block;
  margin-left: 15px;
  p {
    margin-bottom: 10px;
  }
}

.modal-appointment {
  background-color: rgba($color-dark, 0.5) !important;
  z-index: $z-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  align-items: center;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  
  @media #{$desktop} {
    height: calc(100vh - 9rem);
    max-height: 70rem;
    width: calc(100vw - 6rem);
    max-width: 50rem;
    top: 10%;
    left: 35%;
  }

  @media #{$tablet} {
    height: calc(100vh - 9rem);
    max-height: 70rem;
    width: calc(100vw - 6rem);
    max-width: 50rem;
    top: 5%;
    left: 18%;
  }
}

.for-mobile {
  display: inline-block;
  @media #{$desktop} {
    display: none;
  }
}

.contact-top {
  text-align: center;
  margin-top: 10px;
  img {
    width: 30%;
  }
}

.contact-bottom {
  text-align: center;
  img {
    width: 30%;
  }
}

.dealer-appoint-btn {
  background-color: #cd0013 !important;
}

.bp-only {
  text-align: center;
  margin-top: 30px;
  img {
    width: 50%;
  }
}

.exclude-bp {
  text-align: center;
  margin-top: 30px;
  img {
    width: 30%;
  }
}