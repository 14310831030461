@import "config/_theme.scss";

@import "@material/fab/mdc-fab";

.home {
  padding-bottom: $nav-height * 2;

  .slider {
    margin-top: $el-pad;
  }

  &__header {
    font-weight: $weight-semi;
    font-size: $h5;
    text-align: center;
    padding: 0 $el-pad $el-pad/3;
    color: $color-grey-dark;
    justify-content: center;
  }

  .home-fab {
    position: fixed;
    height: 72px;
    width: 72px;
    right: $el-pad/2;
    bottom: $el-pad/2 + $nav-height;
    z-index: 999;
  }

  .dealer-chat {
    border: transparent;
    background-color: transparent;
  }
}

