@import 'config/_theme.scss';
@import 'react-image-gallery/styles/scss/image-gallery.scss';

.detail-vehicle {
  padding-bottom: $nav-height + $el-pad;

  hr {
    border: none;
    border-top: 2px solid $color-grey-light;
  }

  .vehicle-detail-fab {
    position: fixed;
    width: 72px;
    height: 72px;
    right: $el-pad/2;
    bottom: $el-pad/2 + $nav-height;
    z-index: 999;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: calc(100% - (#{$el-pad}));
    max-width: $wrapper-width;

    &__details {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      &__image {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: $el-pad/3 0;

        & > img {
          max-width: 100%;
        }
      }

      &__body {
        flex: 1;
        padding: $el-pad/3 0;
        font-size: $font-base;

        &__line {
          height: 10px;
          background-color: $color-gray-200;
          border-bottom: 1px solid $color-gray-400;
          margin: 2rem;
          margin-left: -$el-pad;
          margin-right: -$el-pad;
          @media #{$tablet}, #{$desktop} {
            height: 0;
          }
        }

        &__top {
          width: 100%;
          display: flex;

          &__detail {
            width: 70%;
          }

          &__btn {
            margin: 0 0 0 auto;
            button {
              width: 135px;
              height: 32px;
              border-radius: 20px;
              font-size: 12px;
              font-weight: $weight-bold;
            }
          }
        }

        &__name {
          font-size: 20px;
          text-transform: capitalize;
          color: $color-dark;
          margin-bottom: 0;
          font-weight: $weight-bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media #{$desktop} {
            padding-right: $el-margin * 4;
          }
        }

        &__license-plate {
          font-family: TIS;
          font-style: normal;
          font-weight: $weight-normal;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: $color-dark;
        }

        &__details {
          display: flex;
          flex-wrap: wrap;

          &__section-name {
            width: 100%;
            display: flex;
            font-family: TIS;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 1rem;

            &__header {
              width: 70%;
              .label {
                margin-left: auto;
              }
            }
          }

          &__group-field {
            display: flex;
          }

          &__field {
            width: 50%;

            &.detail-vehicle__body {
              &__age {
                width: 50%;
              }

              &__maintenance {
                width: 100%;
              }

              &__maintenance {
                order: 1;
              }

              @media #{$phone-md}, #{$phone-sm} {
                &__vin {
                  width: 50%;
                }
              }
              &__t2h {
                width: 30%;
                border-radius: 20px;
                height: 33.11px;
                font-size: 12px;
                font-weight: 700;
                margin: 0 0 0 auto;

                @media #{$phone-md},#{$tablet} {
                  width: 27%;
                }
                @media #{$desktop} {
                  width: 20%;
                }
                &[disabled] {
                  background-color: $color-gray-g5;
                  color: $color-light;
                }
              }
            }

            .label {
              font-size: $label;
              color: $color-gray-800;
              letter-spacing: $label-spc;
              display: block;
              margin-bottom: $el-margin;
            }

            .value,
            a {
              font-size: $font-base;
              text-transform: capitalize;
              color: $color-dark;
              margin-bottom: $el-pad/2;
              padding-right: $el-pad/2;
              word-break: break-word;
              text-decoration: none;
            }

            .overdue {
              color: #f01516;
              white-space: nowrap;
            }
          }
        }
      }

      &__line {
        position: static;
        width: 100%;
        height: 0px;
        border: 1px solid #dfe1e5;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 15px 0px;
      }

      &__button {
        gap: 8px;
        display: flex;
        i {
          height: unset !important;
        }
        button {
          width: 100%;
          border: 1.5px solid #c3c3c3;
          box-sizing: border-box;
          border-radius: 8px;
          font-weight: bold;
        }
        span {
          margin-left: 2px;
        }
      }
    }

    .slider {
      width: 100%;
    }
  }

  &__badges {
    display: flex;
    margin-top: $el-margin;

    &__wrapper {
      &:first-child {
        text-align: left;
      }
      margin-right: $el-margin;

      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3) ~ & {
        &:last-child {
          text-align: right;
        }
      }

      img {
        margin: 0;
        max-height: 4rem;
        width: auto;

        &.detail-vehicle__badges__excellency,
        &.detail-vehicle__badges__isp {
          cursor: pointer;
        }

        &.detail-vehicle__badges__isp {
          background: white;
          border-radius: 5px;
        }

        &.detail-vehicle__badges__isuzu_care {
          border-radius: 5px;
          max-height: 3rem;
          margin-top: 0.6rem;
        }
      }
    }
  }

  &__action {
    margin: 0 auto;
    max-width: $wrapper-width;
    padding: $el-pad/2 $el-pad;
  }

  @media #{$tablet}, #{$desktop} {
    &-wrapper {
      &__details {
        display: flex;
        flex-direction: row;

        &__image {
          max-width: 50%;
          padding: $el-pad/3 $el-pad;
        }

        &__body {
          padding: $el-pad/3 $el-pad;
        }
      }
    }

    &__action {
      display: none;
    }
  }
  &-remark {
    font-size: 10px;
  }
}

.image-gallery {
  width: 100%;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: unset !important;
}

.image-gallery-thumbnail {
  opacity: 0.5;
  border: transparent !important;
  @media (min-width: 320px) {
    width: 47px;
    height: 47px;
  }
  @media (min-width: 340px) {
    width: 53px;
    height: 53px;
  }
  @media (min-width: 375px) {
    width: 56px;
    height: 56px;
  }
  @media (min-width: 400px) {
    width: 62px;
    height: 62px;
  }
  @media (min-width: 425px) {
    width: 64px;
    height: 64px;
  }
  @media #{$tablet} {
    width: 60px;
    height: 60px;
  }
  @media #{$desktop} {
    width: 90px;
    height: 90px;
  }
}
.image-gallery-thumbnail.active {
  opacity: 1;
  @media (min-width: 320px) {
    width: 47px;
    height: 47px;
  }
  @media (min-width: 340px) {
    width: 53px;
    height: 53px;
  }
  @media (min-width: 375px) {
    width: 56px;
    height: 56px;
  }
  @media (min-width: 400px) {
    width: 62px;
    height: 62px;
  }
  @media (min-width: 425px) {
    width: 64px;
    height: 64px;
  }
  @media #{$tablet} {
    width: 60px;
    height: 60px;
  }
  @media #{$desktop} {
    width: 90px;
    height: 90px;
  }
}

.image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.image-gallery-thumbnails-container {
  text-align: left !important;
  cursor: unset !important;
  transform: unset !important;
  display: flex;
}

.image-gallery-slide {
  justify-content: center;
  background: #f4f4f4;
  height: calc(75vw - 3rem) !important;
  @media #{$desktop}, #{$tablet} {
    height: calc((37.5vw / 4) * 3) !important;
  }
  margin-bottom: $el-margin;
  align-items: center;
  display: flex;
}

.image-gallery-image {
  max-height: calc(75vw - 3rem) !important;
  @media #{$desktop}, #{$tablet} {
    max-height: calc((37.5vw / 4) * 3) !important;
  }
}

.detail-vehicle-flex {
  display: flex;
  width: 100%;
}
