@import 'config/_theme.scss';

.signin {
  @media #{$desktop} {
    display: flex;
    height: 100%;
  }

  &__desktop {
    @media #{$desktop} {
      width: 100%;
      display: flex;
    }

    &__banner {
      display: none;
      @media #{$desktop} {
        display: flex;
        width: 65%;
        background: $color-light;
        float: left;
        img {
          max-width: 100%;
          margin-top: 120px;
        }
      }
    }

    &__content {
      @media #{$desktop} {
        float: left;
        width: 35%;
        margin-top: 120px;
      }
    }

    &__detail {
      @media #{$desktop} {
        width: 80%;
        margin: auto;
      }
    }
  }

  &__logo {
    @media #{$desktop} {
      margin: auto;
      width: 80%;
    }
    @media (max-width: 425px) {
      margin-top: 4rem;
    }
  }

  width: 100%;
  &__content {
    color: $color-primary;
    font-size: $h55;
    font-weight: $weight-semi;
    text-align: center;
  }

  &__issue {
    padding: 0 $el-pad;
    button {
      color: $color-primary !important;
      padding: 0;
      font-weight: $weight-bold;
    }
  }

  &__social_media {
    font-size: $font-base;
  }

  &__action {
    margin: $el-pad/2 $el-pad;
    width: calc(100% - (#{$el-pad} * 2));
  }

  &__form {
    .input {
      margin-bottom: $el-pad/2;

      &__input {
        border: 1px solid $color-grey-soft;
        @media #{$desktop}, #{$tablet} {
          border: unset;
        }
      }
    }

    &__action {
      button {
        text-transform: none;
      }

      &__section {
        &--half {
          display: flex;
          align-items: center;
          flex: 1 1 50%;
          margin: 0;
          font-weight: $weight-bold;
        }

        label {
          font-size: $label;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: $el-pad/2 $el-pad;

    &__language {
      flex: 0 1 50%;
    }
    &__links {
      display: block;

      overflow: auto;
      width: 100%;
      clear: both;
    }

    @media #{$desktop} {
      display: none;
    }

    .select {
      border: 1px solid $color-grey-soft;
      border-radius: $el-rad;
      @media #{$desktop}, #{$tablet} {
        border: unset;
      }
    }
  }

  @media #{$desktop} {
    .tab-bar {
      padding-left: $el-pad;
      padding-right: $el-pad;
      width: calc(100% - (#{$el-pad} * 2));
    }

    &__issue {
      margin: 0 auto;
      max-width: $wrapper-width;
    }

    &__content {
      margin: 0 auto;
      max-width: $wrapper-width;
    }

    &__footer {
      margin: 0 auto;
      max-width: $wrapper-width;
    }
  }

  @media #{$tablet} {
    .tab-bar {
      padding-left: $el-pad;
      padding-right: $el-pad;
      width: calc(100% - (#{$el-pad} * 2));
    }
  }
}

.signin__body {
  padding: $el-pad $el-pad $el-pad/2 $el-pad;
}

.signin__body > div,
// .signin__form__action is not really a descendant of .signin__body, but we
// still want to maintain the same margin-top.
.signin__form__action {
  margin-top: $el-margin * 3;

  &:first-child,
  // .signin__issue already got enough element height. No need for extra margin.
  &.signin__issue {
    margin-top: 0;
  }
}

.social-media {
  display: flex;
  padding: $el-pad/2 $el-pad;

  &__item > a,
  &__item__facebook {
    width: $el-height;
    height: $el-height;
    border-radius: 100%;
    background: $color-grey;
    display: flex;
    align-content: center;
    justify-content: center;

    &:hover {
      background: $color-primary;
      cursor: pointer;
    }

    svg {
      width: 2.5rem;
      height: auto;
    }
  }

  &__item {
    margin-right: $el-pad/2;
    position: relative;

    &__sub {
      display: none;
      box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);

      &:after {
        top: 100%;
        left: 2.5rem;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: $color-light;
        border-width: 10px;
        margin-left: -10px;
      }

      a {
        text-decoration: none !important;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $el-pad/2;

        &__image {
          flex: 0 0 8rem;
          display: block;
          width: $el-height;
          height: $el-height;
          overflow: hidden;
          position: relative;
          margin-right: $el-pad/2;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            height: auto;
            width: 100%;
            transform: translate(-50%, -50%);
            max-width: none;
          }
        }

        &__desc {
          text-decoration: none;
          font-weight: $weight-bold;
          color: $color-dark;
        }
      }
    }

    &:hover {
      .social-media__item__sub {
        display: flex;
        position: absolute;
        padding: $el-pad/2;
        background: $color-light;
        border-radius: $el-rad;
        bottom: $el-height;
        flex-direction: column;
        min-width: 20rem;
      }
    }
  }

  @media #{$desktop} {
    margin: 0 auto;
    max-width: $wrapper-width;
  }
}

.social {
  @media #{$desktop} {
    display: none;
  }

  p {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1rem;
    color: $color-grey;
  }
}

.version {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  font-size: 1rem;
  color: $color-grey-soft;
}
