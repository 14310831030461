@import 'config/_theme.scss';

@import '@material/fab/mdc-fab';

.privileges {
  min-height: 100vh;
  background: $bg;
  padding-bottom: $nav-height;

  &__header {
    width: 100%;
    text-align: center;
    padding: 2rem 0;
    font-size: $h4;
    font-weight: $weight-semi;
  }

  &__body {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: $wrapper-width;
    margin: 0 auto;
    top: 0;
    padding: 0 0 0 $el-pad;

    &__btn {
      position: relative;
      width: 40%;
      max-width: 150px;
      border-radius: 15px;
      margin-right: $el-pad;
      margin-bottom: $el-pad;
      &[disabled] {
        pointer-events: none;
        opacity: 0.4;
      }

      img {
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 0.5rem;
        width: 100%;
      }
    }

    &__btn:before {
      content: '';
      display: block;
      padding-top: 100%;
      border-radius: 15px;
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      cursor: pointer;
    }
  }
  @media #{$desktop}, #{$tablet} {
    &__header {
      position: static;

      .content-header {
        display: block;
      }
    }
  }
}
