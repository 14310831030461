@import "config/_theme.scss";

.vehicle-search{
  &__body{
    .vehicle-search__body-wrapper{
      margin: 0 auto;
      width: calc(100% - (#{$el-pad}*2))
    }

    &__vin{
      display: none;

      label{
        display:block;
        width: 100%;
        height: $label-height;
        margin-bottom: $label-margin;
        font-size: $label;
        font-weight: $weight-semi;
        text-transform: uppercase;
        letter-spacing: $label-spc;
        color: $color-grey;
      }

      p{
        font-weight: $weight-normal !important;
      }
    }

    p{
      margin-bottom: $el-pad;
      font-weight: $weight-semi;
      line-height: $lh;
    }

    .input{
      margin-bottom: $el-pad;
    }

    &.feedback{
      padding: $el-pad/2 $el-pad;
      p{
        font-weight: $weight-semi;
        line-height: $lh;
        margin-bottom: $el-pad/2;
      }
  
      button{
        color: $color-primary !important;
        padding: 0 !important;
        margin-bottom: $el-pad;
      }
    }
  }

  .remark{
    padding: $el-pad/2;
    background: rgba($color-accent, .5);
    font-weight: $weight-semi;
  }

  @media #{$desktop}, #{$tablet}{
    &__body{
      &__vin{
        display: block;

        &__image{
          img{
            max-width: 100%;
          }
        }
      }
      
      .vehicle-search__body-wrapper{
        margin: 0 auto;
        max-width: $wrapper-width;

        .vehicle-search__body__vin{
          float: left;
          width: 36rem;
          margin-right: $el-pad*4;
        }

        .vehicle-search__body{
          width: calc(100% - (36rem + (#{$el-pad}*4)));
          padding: 0;
          float: right;

          .input__tooltip--modal{
            display: none;
          }

          &:after{
            content: '';
            clear: both;
            display: block;
          }

          button{
            width: auto !important;
            padding-left: $el-pad;
            padding-right: $el-pad;
          }
        }
      }
    }
  }

  @media #{$tablet}{
    &__body{
      &__vin{
        display: block;
      }

      .vehicle-search__body-wrapper{
        margin: 0 auto;
        max-width: $wrapper-width;

        .vehicle-search__body__vin{
          margin-right: $el-pad*2;
        }

        .vehicle-search__body{
          width: calc(100% - (36rem + (#{$el-pad}*2)));
        }
      }
    }
  }
}