@import 'config/_theme.scss';

.modal-overlay {
  background-color: rgba($color-dark, 0.5) !important;
  z-index: $z-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.confirm-modal {
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background: $color-light;
  width: 346px;
  height: 280px;
  border-radius: 18px;

  &__header {
    width: 100%;
    border-radius: 16px 16px 0 0;
    background: $color-light;
    height: $modal-height * 0.8;
    display: flex;
    align-items: center;

    &__text {
      font-weight: $weight-semi;
      align-items: center;
      padding: $el-pad/2;
      font-size: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__icon {
      display: inline-block;

      i {
        background-color: $color-gray-800;
        vertical-align: middle;
        color: $color-light;
      }
    }
  }

  &__body {
    text-align: center;

    &__content {
      padding: $el-pad/2;
      line-height: $lh;
      margin: $el-pad;
      font-size: 16px;

      a {
        color: $color-primary;
      }
    }
  }

  &__action {
    display: flex;
    align-content: center;
    justify-content: center;

    button {
      min-width: 130px;
      border: 1.5px solid #ed1c24;
      box-sizing: border-box;
      border-radius: 30px;
      font-weight: bold;
      font-size: 16px;
      margin: 0 $el-margin*2;
    }
  }

  &__cancel-btn {
    span {
      color: #ed1c24;
    }
  }
  
  &__confirm-btn {
    background-color: #ed1c24 !important;
    span {
      color: $color-light;
    }
  }

  &__close {
    float: right;
    margin: 0 $el-pad/2;
    i {
      color: $color-light;
      background-color: $color-gray-800;
      border-radius: 10px;
    }
  }
}