@import 'config/_theme.scss';

.promotion-detail{
  .content-header{
    display: none;
  }

  @media #{$desktop}, #{$tablet}{
    .content-header{
      display: block;
    }
  }
}