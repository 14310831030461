@import 'config/_theme.scss';
@import '@material/fab/mdc-fab';

.controllers {
  height: 100%;
  padding-bottom: $nav-height;

  &__header {
    position: fixed;
    z-index: 10;
    background: $bg;
    width: 100%;

    &__pager {
      background: $bg;

      .list-pager {
        background: $bg;
      }
    }

    .content-header {
      display: none;
    }

    &__total {
      background: $color-light;
      display: flex;
      align-items: center;
      height: $el-height;
      font-size: $label;
      color: $color-grey;
      font-weight: $weight-semi;
      letter-spacing: $label-spc;
      text-transform: uppercase;
      position: relative;

      span {
        margin-left: $el-pad;
      }
    }
  }

  &__body {
    position: static;
    display: block;
    min-height: 100vh;
    width: 100%;
    padding-bottom: $nav-height + $el-pad;

    .list {
      top: 10.5rem;
      position: relative;
      min-height: calc(100vh - 10.5rem);
    }
  }

  .fab {
    position: fixed;
    right: $el-pad/2;
    bottom: $el-pad/2 + $nav-height;
    @include mdc-fab-accessible($color-dark);
  }

  @media #{$desktop}, #{$tablet} {
    .list {
      top: $el-margin;

      > div {
        display: inline-flex;
        width: calc((100% - (#{$el-pad}/ 2 * 3)) / 4);
        margin-right: $el-pad/2;
        margin-bottom: $el-pad/2;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }

    .fab {
      display: none;
    }

    &__header {
      position: sticky;

      .content-header {
        display: block;
      }
    }
  }

  @media #{$tablet} {
    &__body {
      margin: 0 auto;
      width: calc(100% - (#{$el-pad}* 2));

      .list {
        max-width: 100%;

        > div {
          display: inline-flex;
          width: calc((100% - (#{$el-pad}/ 2 * 2)) / 3);
          margin-right: $el-pad/2;
          margin-bottom: $el-pad/2;

          &:nth-child(4n) {
            margin-right: $el-pad/2;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
