@import 'config/_theme.scss';

.controller-form {
  background-color: $color-light;
  height: 100vh;

  &__form {
    padding: $el-pad/2;

    .input {
      margin: $el-pad/2 0;
      &__label {
        display: flex;
        font-size: $font-base;
      }
      &__input {
        border: 1px solid $color-gray-400;
        font-family: $font-body;
      }
    }

    &__selection {
      > div {
        display: flex;
        flex-direction: row;

        label {
          border: 1px solid $color-gray-400;
          box-sizing: border-box;
          border-radius: 4px;
          margin: 0 1rem 0 0;
          width: 117px;
          height: 48px;
        }

        span {
          font-size: $font-base;
          font-family: $font-body;
        }
      }
    }

    &__channel-selection {
      display: flex;
      margin: $el-margin*2 0 $el-margin 0;
      text-align: center;
      > div {
        display: flex;
        flex-direction: row;
        label {
          border: unset;
          border-radius: unset;
          margin: 0;
          height: 20px;
        }
        span {
          font-size: $font-base;
          font-family: $font-body;
          padding: 0 $el-margin;
          padding: 0 0.1rem;
        }
      }
    }

    &__action {
      margin-top: $el-pad;
      text-align: center;
      button {
        border-radius: 80px;
        @media #{$desktop}, #{$tablet} {
          width: 200px;
        }
      }
    }
  }

  @media #{$desktop}, #{$tablet} {
    &__form {
      margin: 0 auto;
      max-width: 54rem;
    }
  }
}

.radio_active {
  border: 1px solid $color-primary-defualt !important;
  background-color: $bg-menu-list-hover;
  span {
    color: $color-primary-defualt;
  }
}

#require {
  color: $color-primary-defualt;
}

#channel {
  width: 100%;
  margin-right: $el-margin;
}

.input__error {
  padding: unset;
}