@import 'config/_theme.scss';

.protection {
  margin: $el-pad $el-pad/3;
  text-align: center;
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &__icon {
      margin: 10px 0;
      img {
        margin-bottom: 19px;
      }
      p {
        white-space: nowrap;
      }
    }
  }
}