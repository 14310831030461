@import 'config/_theme.scss';

.isp-vehicle {
  background: $color-gray-200;
  height: 120vh;
  &__header {
    background: $color-light;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    @media #{$breakpoint-md-down} {
      display: none;
    }
  }
  &__logo {
    background: $color-light;
    border-radius: 50% / 0 0 100% 100%;
    height: 50px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    @media #{$desktop} {
      width: 600px;
    }
    @media #{$tablet} {
      max-width: calc(100% - (#{$el-pad}*2));
    }
  }
  &__detail {
    text-align: center;
    padding-top: $el-pad*2;
    height: 100vh;
    &__img {
      margin: $el-pad*2 auto;
      max-width: 80%;
      img {
        width: 100%;
      }
    }
  }
  &__btn {
    margin-top: $el-pad;
    button {
      margin-bottom: $el-pad/2;
    }
  }
}

.package__detail {
  margin-top: $el-margin;
  &__box {
    display: flex;
    justify-content: center;
    &__name, &__value {
      display: flex;
      align-items: center;
      justify-content: space-around;
      min-height: 3.5rem;
      width: 40%;
    }
    &__name {
      background-color: $color-primary-ISUZU;
      color: $color-light;
      font-weight: $weight-semi;
      margin-right: $el-margin/2;
    }
    &__value {
      padding: 0.5rem;
      background-color: $color-light;
      margin-left: $el-margin/2;
    }
  }
}