@import 'config/_theme.scss';

.t2h-wrapper {
  text-align: center;

  img {
    @media (max-width: 425px) {
      width: 100%;
    }
  }

  .box {
    height: 0;
  }

  .line-t2h, .line-bottom, .contact-line, .website {
    width: 175px;
    height: 33.11px;
    display: inline-grid;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-transform: none;
    border-radius: 10px;
  }
  
  .line-t2h {
    background-color: #34A93F;
    transform: translate(0%, -2100%);
    @media (max-width: 320px) {
      transform: translate(0%, -1000%);
    }
    @media (min-width: 321px) and (max-width: 375px) {
      transform: translate(0%, -1150%);
    }
    @media (max-width: 425px) and (min-width: 376px) {
      transform: translate(0%, -1300%);
    }
  }

  .contact-line {
    background-color: #34A93F;
    transform: translate(0%, 580%);
    @media (max-width: 320px) {
      transform: translate(0%, 240%);
    }
    @media (min-width: 321px) and (max-width: 375px) {
      transform: translate(0%, 280%);
    }
    @media (max-width: 425px) and (min-width: 376px) {
      transform: translate(0%, 330%);
    }
  }

  .line-bottom {
    background-color: #34A93F;
    transform: translate(30%, -500%);
    @media (max-width: 320px) {
      transform: translate(25%, -270%);
    }
    @media (min-width: 321px) and (max-width: 375px) {
      transform: translate(30%, -300%);
    }
    @media (max-width: 425px) and (min-width: 376px) {
      transform: translate(30%, -350%);
    }
  }

  .website {
    background-color: #1d3461;
    transform: translate(30%, -400%);
    @media (max-width: 320px) {
      transform: translate(25%, -250%);
    }
    @media (min-width: 321px) and (max-width: 375px) {
      transform: translate(30%, -280%);
    }
    @media (max-width: 425px) and (min-width: 376px) {
      transform: translate(30%, -320%);
    }
  }
}
