@import 'config/_theme.scss';

.full-image {
  height: 100vh;
  background-color: $color-dark;
  overflow-y: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: $color-light;
    padding: 0 $el-margin * 2;
  }

  &__body {
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    img {
      margin: auto;
      max-width: 100%;
      max-height: 90%;
    }
  }

  &__footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-light;
    padding: 0 $el-margin * 2;
    min-height: 45px;
    &__delete {
      float: right;
    }
  }

  &__modal {
    position: unset !important;
    inset: unset !important;
    border: unset !important;
    background: unset !important;
    overflow: unset !important;
    border-radius: unset !important;
    outline: unset !important;
    padding: unset !important;
  }
}