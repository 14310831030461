@import 'config/_theme.scss';

.menu-list {
  border-bottom: 1px solid $color-gray-400;
  cursor: pointer;

  &_content {
    display: flex;
    height: 6rem;
    padding: 0 1rem 0 1.5rem;
    position: relative;
    align-items: center;
    // background-color: $bg;
    // background-color: $bg-menu-list-hover;
    // justify-content: flex-end;

    span {
      color: $color-bright-red;
      font-size: 16px;
    }
    &_active {
      display: flex;
      height: 6rem;
      padding: 0 1rem 0 1.5rem;
      position: relative;
      align-items: center;
      background-color: $bg-menu-list-hover;
      span {
        color: $color-bright-red;
        font-size: 16px;
      }
    }
    &_title {
      display: flex;
      height: 6rem;
      padding: 0 1rem 0 1.5rem;
      position: relative;
      align-items: center;
      cursor: default;

      // background-color: $bg;
      span {
        color: $color-gray-800;
        font-size: 16px;
      }
    }
  }
}
