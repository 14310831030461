@import '../../../../config/theme';

  .privacy_section{
      height: 100%;
      
  }
  .privacy{
    display: block;
    height: 90%;
    overflow-y: auto;
    border: 1px solid $color-grey-light;
    padding: $el-pad/2;
    background: $color-light;

    h1, h2{
      margin-bottom: $el-pad/2;
    }

    h2{
        font-size: 2rem;
    }

    h3, h4{
      margin-bottom: $el-pad/3;
    }
    h3{
        font-size: 1.8rem;
    }

    p{
      margin-bottom: $el-pad/3;
      text-indent: 5%;
    }

    strong{
      font-weight: $weight-bold;
    }

    .privacy__ol-1{
      list-style-position: outside;
      list-style: none;
      

      > li{
        margin-left: $el-pad/4;
        padding-bottom: $el-pad/3;
        

        > ol{
          list-style-position: outside;
          list-style: none;

          > ol{
            list-style-position: outside;
            list-style: decimal;

            > li{
              margin-left: $el-pad/1;
              padding-bottom: $el-pad/6;
              padding-top: $el-pad/6;
            }
          }


          > li{
            margin-left: $el-pad/2;
            padding-bottom: $el-pad/5;
            padding-top: $el-pad/4;
            
          }
          
        }
      }
    }

    ul{
      list-style-position: outside;
      margin-bottom: $el-pad;

      > li{
        margin-left: $el-pad;
      }
    }

    ul{
      list-style: disc;

      > li{
        margin-left: $el-pad/2;
      }
    }
    ol{
      display: block;
      list-style: decimal;
      list-style-position: inside;
      counter-reset: item;
      
   
    }
    .privacy_table_list{

      tr{
            ol {
              counter-reset: list;
          }
          ol> td > li {
              list-style: none;
              padding: 2px 0px 3px 0px;
          }
          ol > td > li:before {
              content: counter(list) ") ";
              counter-increment: list;
          }
          ol > td{
            padding-right: 5px;
          }
        }
        
      margin-bottom: $el-pad/3;
      max-width: 800px;
      margin-left: $el-pad/2;

      table{
        width:90%;
      }
    }
    .privacy_table{
      padding-left: 0;
      margin-bottom: $el-pad/3;
      max-width: 800px;
      white-space: break-spaces;

      .objective-column {
        display: flex;
      }

      table{
        width:80%;
      }

      table, th, td {
        font-size: small;
        border: 1px solid black;
        
      }
      td{
        padding: 0px 0px 5px 5px;
      }
    }
  }