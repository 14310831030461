@import 'config/_theme.scss';

.controller-item {
  display: block;
  height: 9.5rem;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  background: $color-light;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  padding: $el-pad/2 $el-pad;
  cursor: pointer;

  &__name {
    width: calc(100% - 9rem);
    height: $el-height;

    &__fullname {
      font-size: $h5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: $el-margin;
      font-weight: $weight-semi;
      font-family: $font-header;
    }
    &__username {
      color: $color-primary;
      height: 1.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $font-base;
      
      
    }
    &__status--201{
      color: $color-secondary;
    }
    &__status--101{
      color: $color-grey;
    }
    
  }
  
  &__units {
    margin-left: auto;
    width: 9rem;
    text-align: right;
    color: $color-grey;
    font-size: $h5;
    font-weight: $weight-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $font-header;
  }

  &__delete {
    margin-left: auto;
    width: 3rem;
    text-align: right;
    z-index: 1;
  }

  &--placeholder{
    .controller-item__name{
      &__fullname {
        height: $h5;
        background: rgba($color-dark, .05);
      }
      &__username {
        background: rgba($color-dark, .05);
      }
    }
    
    .controller-item__units {
      height: $h5;
      width: 7.5rem;
      margin-left: auto;
      background: rgba($color-dark, .05);
    }
  }
}
