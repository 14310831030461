@import 'config/_theme.scss';

.detail-header {
  padding: 1px $el-pad / 2;

  &__title {
    font-weight: bold;
    font-size: 16px;
  }
}

.repair-history {
  margin: auto;
  @media #{$desktop} {
    background-color: $bg;
  }
  &__body {
    padding-bottom: $el-pad * 2;
    height: 95vh;
    @media #{$desktop} {
      height: 85vh;
    }

    &__bg {
      background-color: $bg;
      padding: $el-pad / 3;
      @media #{$desktop} {
        padding: $el-pad / 3 15%;
      }
    }
    &__card {
      background: $color-light;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: $el-pad / 2;
      vertical-align: middle;
      display: flex;
      justify-content: space-between;

      &__info {
        width: 95%;
        &__header {
          display: flex;
          color: $color-primary;
          font-weight: bold;
          margin-bottom: $el-margin;
          span {
            margin: auto 1rem;
          }
        }
        &__detail {
          display: flex;
          span {
            width: 25%;
            @media #{$desktop} {
              width: 10%;
            }
          }
        }
      }

      &__arrow {
        width: 3%;
        display: flex;
        svg {
          height: 100%;
        }
      }

      .service-center,
      .mileage,
      .date {
        width: 75%;
      }
    }
  }

  &__body-no-repair {
    height: calc(100vh - 21rem);
    align-items: center;
    display: flex;

    &__content {
      text-align: center;
      width: 100%;
      &__text {
        color: $color-grey;
        font-size: 2rem;
        margin-top: 20px;
      }
    }
  }
}

.ant-spin-dot-item {
  background-color: red;
}

.predownload {
  text-align: center;
  padding: 2rem 0;
  background-color: $bg;
}
.infinite-scroll-component {
  @media #{$phone-md},#{$phone-sm} {
    padding-bottom: 6.5rem;
  }
}
