@import 'config/_theme.scss';

.filter {
  height: $el-height-sm * 2;
  width: 100%;
  padding: $el-pad/2 $el-pad;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  z-index: 2;
  position: relative;
  box-sizing: border-box;

  .input-search,
  .select {
    flex: 1 1 calc(40% - 1.5rem);
    margin-left: 1.5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  .change-view-sm {
    display: flex;
  }

  .change-view {
    display: none;
    flex-shrink: 0;
    margin-left: auto;
    overflow: hidden;
    white-space: nowrap;
  }

  .button {
    padding-left: $el-pad / 2 !important;
  }

  @media #{$desktop} {
    max-width: $wrapper-width;

    .change-view-sm {
      display: none;
    }

    .change-view {
      display: flex;
    }

    .button--sort {
      position: relative;
    }

    padding: $el-pad/2 0;
    width: 100%;
    justify-content: flex-start;

    .input-search,
    .select {
      flex-grow: 0;
      flex-basis: 30rem;
    }
  }

  @media #{$tablet} {
    max-width: $wrapper-width;

    .change-view-sm {
      display: none;
    }

    .change-view {
      display: flex;
    }
  }

  @media #{$breakpoint-md-down} {
    .select {
      &__types,
      &__controllers {
        display: none;
      }
    }
  }
}
