@import 'config/_theme.scss';

.detail-header {
  display: flex;
  // width: calc(100% - (#{$el-pad}* 2));
  height: $el-pad * 2;
  align-items: center;
  padding: 0 $el-pad/2 0 0;
  // padding: 0;
  position: relative;
  justify-content: flex-end;
  // background-color: $bg;

  &__grey-fixed {
    background-color: #f4f4f4;
    position: fixed;
    max-width: unset !important;
    padding: unset !important;
    width: 100vw;
    @media #{$desktop} {
      width: 64rem;
      top: 6rem;
    }
    @media #{$tablet} {
      width: 54rem;
      top: 6rem;
    }

    &__title {
      width: 80%;
      text-align: center;
      position: absolute;
      top: auto;
      left: 55%;
      transform: translate(-55%, 0);
      font-size: 18px;
      font-weight: bold;
    }
  }

  .button-back {
    margin-right: auto;
    z-index: 20;
    margin-left: 1.5rem;
  }

  .button-fav {
    position: static;
  }

  &__title {
    text-align: center;
    position: absolute;
    top: auto;
    left: 50%;
    transform: translate(-55%, 0);

    span {
      font-weight: bold;
      font-size: 1.5rem;
    }

    &_company-add {
      text-align: center;
      position: absolute;
      top: auto;
      left: 50%;
      transform: translate(-55%, 0);
      font-weight: bold;
      font-size: 1.8rem;
    }

    &__profile {
      font-weight: bold;
      font-size: 16px;
    }

    &__til-refinance {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .edit {
    font-size: $font-base !important;
    text-transform: capitalize;
    margin-left: $el-pad/2;
    min-width: 3rem;
    color: $color-primary;
    width: auto;
    position: static;

    &[disabled] {
      span,
      i {
        color: $color-gray-g5;
      }
    }

    span,
    i {
      color: $color-primary;
    }
  }

  .delete {
    margin-right: $el-pad/2;

    &.delete-inbox {
      color: $color-grey !important;
    }
  }

  .delete-vehicle {
    display: none;
    flex-direction: row;
    height: 4.5rem;
    padding: 0 8px;
    margin-right: $el-pad/2;
  }

  .excellency-btn {
    color: $color-dark;
  }

  @media #{$desktop} {
    margin: 0 auto;
    max-width: $wrapper-width;

    .delete-vehicle {
      display: flex;
    }
  }

  @media #{$tablet} {
    .delete-vehicle {
      display: flex;
    }
  }

  &_bg {
    width: auto;
  }
}
