@import 'config/_theme.scss';

.company-add {
  max-height: 100%;
  background-color: white;

  &__content {
    background-color: white;
    min-height: 100vh;
    margin: 0 auto;

    background-color: white;
  }
  &__list {
    &__company {
      padding: 2rem 3rem;
      border-bottom: 0.2rem solid #dfe1e5;
    }
  }
  &__title {
    padding: 2rem 3rem;
    margin-top: 3rem;
    background-color: #f4f6f6;
  }

  &__header {
    background-color: #f4f6f6;
  }
}


.textField-add-company {
  label {
    font-size: 1.4rem !important;
  }
}

#outlined-basic-helper-text {
  font-size: 1.2rem;
}

.content-modal {
  width: 100%;

  &_row {
    width: 100%;
  }
  &_col {
    max-width: 40%;
    width: 100%;
    margin: 0 !important;
  }
}
