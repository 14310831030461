@import 'config/_theme.scss';

.isp {
  &--header {
    width: 100vw;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    vertical-align: middle;
    margin: auto 0;
    padding-bottom: 3rem;

    @media (max-width: 375px) {
      height: 440px;
    }

    &--body {
      margin: 0 $el-pad/2;
      &--text {
        span {
          h5 {
            margin: 12px 0;
          }
        }
      }
    }

    &--btn {
      margin-top: $el-pad/2;
      button {
        width: 200px;
      }
    }
  }
}
