@import 'config/_theme.scss';

.input-search{
  position: relative;
  
  i{
    position: absolute;
    top: .7rem;
    left: .5rem;
    font-size: 1.8rem;
    color: $color-grey;
  }

  input{
    box-sizing: border-box;
    height: 3rem;
    margin: 0;
    background: $color-light;
    border: 1px solid $color-light;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    width: 100%; 
    outline: none;
    border-radius: $el-rad;
    font-size: $font-base;
    font-weight: $weight-normal;
    color: $color-dark;

    &::before{
      font-family: $font-icon; 
      content: 'search';  
    }

    &::placeholder{
      font-family: $font-header;
      color: $color-grey;
    }

    &:focus{
      background: $color-light;
      border-color: $color-grey-soft;
    }
  }
}