@import 'config/_theme.scss';

.profile-settings {
  .profile-settings-wrapper {
    padding: 0 $el-pad $nav-height $el-pad;
    height: 100vh;
  }

  &__lang {
    margin-bottom: $el-pad;
  }

  &__display {
    margin-bottom: $el-pad;
  }

  &__promo {
    margin-bottom: $el-pad;
  }

  &__options {
    display: flex;
    align-items: center;

    &__sub-label {
      font-size: $font-base;
      flex: 0 0 50%;
    }

    .select {
      width: 50%;
      border: 1px solid $color-gray-900;
      border-radius: $el-rad;
    }
  }

  .label {
    font-size: $label;
    color: $color-grey;
    font-weight: $weight-semi;
    letter-spacing: $label-spc;
    display: block;
    margin-bottom: $el-margin;
    text-transform: uppercase;
  }

  @media #{$desktop} {
    .profile-settings-wrapper__form {
      max-width: 54rem;
      margin: 0 auto;
    }
  }

  @media #{$tablet} {
    .profile-settings-wrapper__form {
      max-width: 54rem;
      margin: 0 auto;
    }
  }
}
