@import 'config/_theme.scss';

.navigation{
  width: 100%;
  display: block;
  position: fixed;
  bottom: 0;
  background-color: $bg-nav;
  z-index: $z-navigation;
  box-shadow: 0 -1px 2px rgba(0,0,0,.1);

  &__main, &__sub{
    display: flex;
    max-width: $wrapper-width;
    width: 100%;
    padding: 0;
    height: inherit;
    .nav-item {
      width: 20%;
    }
  }

  &__main {
    @media #{$desktop}, #{$tablet} {
      justify-content: flex-end;
      padding: 0 $el-pad*2;
    }
  }
  
  &__sub{
    bottom: $nav-height;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    position: absolute;
    background: $color-light;
    box-shadow: 0 -1px 2px rgba(0,0,0,.1);
    margin: 0 auto;

    .nav-item {
      width: auto;
      flex: 0 0 33.3%;
      margin: $el-pad/2 0;
    }
  }

  @media #{$desktop}, #{$tablet}{
    bottom: unset;
    height: $nav-height-desk;
    overflow: hidden;
    background-color: transparent;
    box-shadow: unset;
    left: 0;
  }

  @media #{$desktop} {
    &__main{
      max-width: calc(100% - (#{$el-pad} * 3)) !important;

      span {
        white-space: nowrap;
      }
    }
  }

  @media #{$tablet} {
    &__main{
      max-width: calc(100% - (#{$el-pad} * 6)) !important;

      span {
        white-space: nowrap;
      }
    }
  }

  @media (max-height: 360px){
    position: static;
  }

  @media (min-height: 361px), #{$desktop}, #{$tablet}{
    position: fixed;
  }
}

.mobileAppInstaller {
  background-color: $color-gray-g9;
  height: 60px;

  &__icon {
    position: absolute;
    margin: 10px;
    border-radius: 8px;
  }

  &__text1 {
    position: absolute;
    top: 10px;
    left: 60px;
    color: white;
    font-weight: 700;
  }

  &__text2 {
    position: absolute;
    top: 30px;
    left: 60px;
    color: white;
    text-decoration: underline;
    font-weight: 700;
  }

  &__link {
    color: white;
  }
}

#mobileX {
  position: absolute;
  background: transparent;
  border: none;
  color: white;
  left: 95%;
}