@import 'config/_theme.scss';

.modal-overlay {
  background-color: rgba($color-dark, 0.5) !important;
  z-index: $z-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  overflow: hidden;
  position: absolute;
  z-index: $z-modal + 1;
  padding: 0;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: $el-rad;
  border: none;
  width: calc(100vw - 6rem);
  max-width: 50rem;
  // height: calc(100vh - 9rem);
  // max-height: 20rem;
  background: $color-light;

  &--coupon {
    width: calc(100vw - 6rem);
    max-width: 50rem;
    height: calc(100vh - 27.5rem);
    max-height: 55rem;
    background: rgba(255, 255, 255, 0);
    padding-top: 4rem;
  }

  &--coupon-vehicle {
    width: calc(100vw - 6rem);
    max-width: 50rem;
    height: calc(100vh - 9rem);
    max-height: 55rem;
    background: rgba(255, 255, 255, 0);
    padding-top: 4rem;
  }

  &--coupon-owner {
    width: calc(100vw - 6rem);
    max-width: 50rem;
    height: calc(100vh - 24.5rem);
    max-height: 55rem;
    background: rgba(255, 255, 255, 0);
    padding-top: 4rem;
  }

  &--el {
    width: calc(100vw - 6rem);
    max-width: 50rem;
    height: calc(100vh - 9rem);
    max-height: 50rem;

    .modal__body {
      height: calc(100% - 4.5rem);
    }
  }

  &--privilege {
    width: calc(100vw - 6rem);
    height: calc(100vh - 9rem);
    max-width: 120rem;
    max-height: 70rem;

    .modal__body {
      height: calc(100% - 4.5rem);
    }
  }

  &--promotion {
    width: calc(100vw - 6rem);
    height: calc(100vh - 9rem);
    max-width: 50rem;
    max-height: 70rem;

    .modal__body {
      height: calc(100% - 4.5rem);
    }
  }

  &--dealer {
    width: calc(100vw - 6rem);
    max-width: 50rem;
    height: calc(100vh - 9rem);
    max-height: 35rem;

    @media #{$desktop} {
      max-height: 40rem;
    }

    .modal__body {
      height: calc(100% - 4.5rem);
    }

    .modal__body__content {
      padding: 0 1.5rem !important;
      margin-top: 0 !important;
    }
  }

  &--delete {
    width: calc(100vw - 4rem);
    max-width: 50rem;
    height: calc(100vh - 9rem);
    max-height: 43rem;
  }
  &--menu-vehciel-image {
    background-color: unset;
    // height: calc(100vw - 90rem);
    @media #{$phone-sm} ,#{$phone-sm-pt},#{$phone-sm-ls},#{$phone-md},#{$tablet} {
      top: 90%;
    }
    .modal__body {
      height: calc(100% - 15rem);
      border-radius: 0.5rem;
      background-color: unset;
    }
  }

  &--company {
    max-width: 37rem;
  }
  &--basic, &--blurLicence, &--company {
    border-radius: 24px;
  }

  &--basic {
    border-radius: 24px;
    height: 360px;
    width: 90%;
    @media #{$desktop}, #{$tablet} {
      width: 570px;
      height: 590px;
    }
  }

  &__header {
    border-radius: 16px 16px 0 0;
    background: $color-light;
    height: $modal-height * 0.8;
    display: flex;
    align-items: center;

    &__text {
      font-weight: $weight-semi;
      align-items: center;
      padding: $el-pad/2;
      font-size: $font-base;
      width: calc(100% - 6.5rem);
      display: flex;
      justify-content: center;
    }

    &__icon {
      display: inline-block;

      i {
        vertical-align: middle;
        color: $color-grey;
      }
    }

    &__image {
      height: 7rem;
      width: 7rem;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -25%);
      border-radius: 50%;
      background: $color-light;
      text-align: center;

      img {
        width: 5.5rem;
        height: auto;
        margin-top: 1.5rem;
      }
    }

    &__value {
      font-weight: normal;
      color: $color-light;
      position: relative;
      top: -44%;
      left: -7px;
      font-size: 1rem;

      &__2 {
        padding-left: 5px;
      }

      &__3 {
        padding-left: 6px;
      }

      &__4 {
        padding-left: 5px;
      }

      &__5 {
        padding-left: 3px;
        font-size: 0.8rem;
      }
    }
  }

  &__body {
    display: block;
    height: calc(100% - 5rem);
    background-color: $color-light;
    overflow-y: auto;

    &__content {
      padding: $el-pad/2;
      line-height: $lh;

      a {
        color: $color-primary;
      }

      &__privilege {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 1.5rem;

        &__item {
          display: flex;
          align-items: center;
          padding: $el-pad/4 $el-pad;

          &:first-child {
            padding-top: 1rem;
          }

          &__img {
            max-height: 8rem;
            max-width: 8rem;
            // min-width: 40%;
            margin-right: 1.5rem;
            display: flex;

            img {
              height: auto;
              width: 100%;
              object-fit: cover;
              object-position: center;
              overflow: hidden;
              border-radius: 15px;
            }
          }

          &__info {
            display: flex;
            flex-direction: column;
            font-weight: $weight-semi;
            max-width: 60%;

            &__name {
              font-size: $h6;
              margin-bottom: 0.5rem;
              text-transform: uppercase;
            }

            &__license {
              color: $color-primary;
              font-size: $h4;
            }
          }
        }
      }

      &__company {
        .title {
          text-align: center;
          padding-bottom: 1rem;
          font-weight: bold;
        }
        .content {
          text-align: center;
          padding-bottom: 3rem;
        }
        .confirm {
          text-align: center;
          padding-bottom: 3rem;
          button {
            width: 15rem;
            border-radius: 2rem;
          }
        }
      }
      &__blur__licence {
        .title {
          text-align: center;
          padding-bottom: 1rem;
          font-weight: bold;
          span {
            font-size: 1.7rem;
          }
        }
        .content {
          text-align: center;
          padding-bottom: 3rem;
          border-radius: 2.5rem;
          img {
            margin: 1rem 0;
          }
        }
        .confirm {
          text-align: center;
          padding-bottom: 3rem;
          button {
            width: 15rem;
            border-radius: 2rem;
          }
        }
      }
    }
  }

  &__action {
    display: flex;
    align-content: center;
    justify-content: center;

    button {
      min-width: 50%;
    }
  }
}

.title {
  margin-top: 10px;
}

.blur_licence_plate_modal {
  // padding: 0 2rem;
  span {
    font-size: 1.7rem;
  }
}
