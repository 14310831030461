@import 'config/_theme.scss';

.list{
  margin: 0 auto;
  @media #{$desktop}{
    max-width: $wrapper-width;
    .ReactVirtualized__Grid__innerScrollContainer{
      max-height: unset !important;
      height: auto !important;
    }
  }
  
  @media #{$tablet}{
    max-width: calc(100% - (#{$el-pad}*2));
    .ReactVirtualized__Grid__innerScrollContainer{
      max-height: unset !important;
      height: auto !important;
    }
  }

  &.adaptable{
    @media #{$desktop}{
      .ReactVirtualized__Grid__innerScrollContainer{
        > div{
          display: inline-flex;
          width: calc((100% - (#{$el-pad}/2 * 3)) / 4);
          margin-right: $el-pad/2;
          margin-bottom: $el-pad/2;
    
          &:nth-child(4n){
            margin-right: 0;
          }
        }
      }
    }
  
    @media #{$tablet}{
      .ReactVirtualized__Grid__innerScrollContainer{
        > div{
          display: inline-flex;
          width: calc((100% - (#{$el-pad}/2 * 2)) / 3);
          margin-right: $el-pad/2;
          margin-bottom: $el-pad/2;
    
          &:nth-child(4n){
            margin-right: $el-pad/2;
          }
    
          &:nth-child(3n){
            margin-right: 0;
          }
        }
      }
    }
  }
}