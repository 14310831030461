@import 'config/_theme.scss';

.Token_Expire{
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title span{
    font-size: $h4;
  }

  &__desc{
    text-align: center;
  }
}