@import 'config/_theme.scss';

.delete-vehicle-modal {
  .modal__body {
    height: auto;
  }

  .modal__body__content {
    background-color: $color-grey-light;
  }

  &__content {
    &__selectors {
      display: flex;
      flex-wrap: nowrap;
      flex: 1 0 50%;

      > div {
        span {
          font-size: 1rem;
          font-weight: $weight-semi;
          white-space: nowrap;
        }

        span:last-child {
          font-size: 1.2rem;
        }

        span[class^='MuiIconButton'] {
          color: $color-primary;
        }
      }

      &__select {
        width: 50%;
        padding: 1rem 0 0 0;
      }
    }

    &__textarea {
      width: 100%;
      min-height: $input-height * 2;
      border-radius: $el-rad;
      box-sizing: border-box;
      border: 1px solid $color-light;
      background: $color-light;
      padding: $el-pad/2;
      font-size: $font-base;
      font-family: $font-body;
      margin: 0.5rem 0;
      resize: none;

      &:disabled {
        background: $color-grey-soft;
      }
    }
  }
}
