@import 'config/_theme.scss';

.company-list {
  max-height: 100%;
  // display: flex;
  background-color: white;

  &__content {
    background-color: white;
    // height: 80rem;
    min-height: 100vh;
    margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    background-color: white;
    @media (min-width: 1200px) {
      max-width: 120rem;
    }
  }
  &__list {
    // margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    // background-color: white;
    // @media (min-width: 1200px) {
    //   max-width: 120rem;
    // }

    // &__title {
    //   padding: 2rem 3rem;
    //   margin-top: 3rem;
    //   background-color: #F4F6F6;
    // }
    &__company {
      padding: 2rem 3rem;
      border-bottom: 0.2rem solid #DFE1E5;;
    }
  }
  &__title {
    padding: 2rem 3rem;
    margin-top: 3rem;
    background-color: #f4f6f6;
  }

  &__header {
    background-color: #f4f6f6;
  }
}
