@import "config/_theme.scss";


.coupon-item {
  display: flex;
  border-radius: $el-rad;
  background: $bg-mileage;
  width: 97% !important;
  text-align: left;

  &--group {
    box-shadow: 5px 5px 0px $color-grey-dark;
    margin-bottom: 1rem;
    margin-right: $el-pad/3;
    margin: 10px 5px;
  }

  &--service {
    min-height: 19.5rem;
    max-height: 23.5rem;
    margin: 10px 5px;
  }

  &--cash {
    min-height: 19.5rem;
    max-height: 23.5rem;
    margin: 10px 5px;
  }

  &--expired {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    opacity: 0.5;
  }

  &--defered {
    animation: blink 1s infinite;
  }

  &--mileage {
    background: $color-light;
  }

  &--discount {
    background: $color-light;
  }

  &__info {
    width: 7rem;
    display: flex;
    flex-direction: column;
    padding: $el-pad/2;
    padding-right: $el-margin;

    &__icon {
      margin: 1.5rem 0;
      position: relative;

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }

      svg {
        height: 6rem;
        width: auto;
        fill: $color-light;
      }
    }

    &__value {
      padding-top: 1px;
      color: $color-light;
      position: absolute;
      top: 3.3rem;
      left: 50%;
      transform: translate(-65%, 0);
      font-size: 1.2rem;

      &__2 {
        padding-left: 5px;
      }

      &__3 {
        padding-left: 3px;
      }

      &__4 {
        padding-left: 1px;
      }

      &__5 {
        padding-left: 0px;
        font-size: 1rem;
      }
    }

    &__expiry {
      justify-content: flex-end;
      margin-top: auto;
      width: 100%;
      line-height: 1.55rem;
      font-size: $expiry;
      font-weight: $weight-semi;
      letter-spacing: $label-spc;
      color: $color-dark;
      text-transform: uppercase;

      span {
        display: block;
        color: $color-primary;
        margin-bottom: 0.5rem;
      }
      &__date {
        font-size: $label;
      }
    }
  }

  &__separator {
    flex: 0 0 1rem;
    background: radial-gradient(
      ellipse at center,
      #ffffff 0%,
      #ffffff 30%,
      transparent 30%
    );
    background-size: 0.5rem 0.5rem;
    background-repeat: repeat-y;
    background-position: center;
    position: relative;

    &--group {
      background: radial-gradient(
        ellipse at center,
        $color-grey-dark 0%,
        $color-grey-dark 30%,
        transparent 30%
      );
    }

    &:before {
      content: "";
      display: block;
      width: 1rem;
      height: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      background: $bg;
      position: absolute;
      top: 0;
    }

    &:after {
      content: "";
      display: block;
      width: 1rem;
      height: 0.5rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      background: $bg;
      position: absolute;
      bottom: 0;
    }
  }

  &__desc {
    position: relative;
    width: calc(100% - 12rem);
    padding: 1.5rem 1.5rem 1.5rem 0.5rem;
    display: flex;
    flex-direction: column;

    &__name {
      font-size: $h65;
      font-weight: $weight-semi;
      letter-spacing: $label-spc;
      color: $color-primary;
      max-height: 2.9rem;
      line-height: 1.8rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      text-transform: uppercase;
      margin-bottom: .4rem;
      padding: .6rem 2.5rem .6rem 0;
    }

    &__number {
      font-size: $h65;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__cash-amt {
      font-size: $h625;
      letter-spacing: $label-spc;
    }

    &__title {
      font-size: $h625;
      max-height: 4rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0.2rem;
      line-height: 1.8rem;
    }

    &__benefits {
      font-size: $h625;
      line-height: 1.8rem;
      max-height: 3.2rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-dark;
      padding: .3rem 0;
    }

    &__license-plate {
      font-size: $h625;
      line-height: 1.8rem;
      height: 2.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: $weight-bold;
      margin-top: .3rem;
    }

    &__vehicle {
      font-size: $h625;
      line-height: 1.8rem;

      &__name {
        max-height: 4rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-dark;
        margin-bottom: 0.2rem;
      }
      &__allowance {
        position: relative;
        top: -1rem;
      }
      &__plate {
        height: 2.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: $weight-bold;
      }
      &__vin {
        height: 2.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-dark;
      }
    }

    &__more {
      color: $color-primary;
      font-size: $nav-desk;
      margin-top: auto;
      line-height: 2rem;
      margin-right: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__deferred {
      position: absolute;
      top: 4.5rem;
      width: $el-height * 3;
      right: -1rem;
      color: $color-primary;
      text-align: center;
      text-shadow: -1px 2px 0 $color-light;
      transform: rotate(45deg);
      font-weight: $weight-bold;
    }

    &__new-badge {
      position: absolute;
      top: -1rem;
      right: 0.2rem;
      width: 3.5rem;

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }

    &__mark-logo {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;

      img {
        max-height: 3.5rem;
        width: 8.5rem;
        vertical-align: middle;
      }
    }
  }

  &--placeholder {
    background: rgba($color-dark, 0.05);
    .coupon-item__info {
      &__icon {
        background: rgba($color-dark, 0.05);
      }

      &__expiry {
        height: 3rem;
        background: rgba($color-dark, 0.05);
      }
    }
    .coupon-item__desc {
      &__type,
      &__name,
      &__vehicle {
        background: rgba($color-dark, 0.05);
      }
      &__type {
        height: 2rem;
      }
    }
  }
}

.coupon-modal-action {
  &--mileage {
    background: darken($color-light, 10);
  }
  &--discount {
    background: darken($color-light, 10);
  }

  font-family: $font-header;
  border-radius: 0 0 16px 16px;
  background: $bg-mileage;
  position: fixed;
  bottom: 0;
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  span {
    color: $color-primary;
    font-weight: $weight-bold;
    line-height: 2rem;
  }

  a {
    text-decoration: none;
  }
}

.coupon-modal {
  padding: 1.5rem;
  background: $color-light;

  &--mileage {
    background: $color-light;
  }

  &--discount {
    background: $color-light;
  }

  &__info {
    margin-bottom: 1.5rem;
    color: $color-semi-dark;

    &__header {
      font-size: $h6;
      color: $color-primary;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      margin-bottom: 0.5rem;
      display: block;

      &.name {
        font-size: $h55;
        font-weight: $weight-bold;
      }
    }

    &__name,
    &__vehicle-name,
    &__vehicle-plate {
      font-size: $h55;
      font-weight: $weight-bold;
    }

    &__cash-number {
      font-size: $h6;
      margin-top: $el-margin*2;
    }

    &__terms {
      font-size: $font-base;
      line-height: 1.2;
    }

    &__expiry {
      font-weight: $weight-bold;
    }
  }

  &__remarks {
    &__label {
      color: $color-primary;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    &__text {
      color: $color-dark;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
    //transform: scale(1);
  }
  100% {
    opacity: 0.5;
    //transform: scale(1.01);
  }
}

.coupon_pdf_html {
  width: 750px;
  font-family: $font-body;
  font-size: 28px;

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    font-family: $font-body;
    font-size: 28px;
  }
  ul,
  ol {
    margin-left: 25px;
  }
}

.dealer-list {
  margin: $el-margin * 2 0;
  span {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  &__province-name {
    margin: 0.5rem $el-margin * 4;

    ul {
      margin: $el-margin;
    }

    li {
      margin: $el-margin;
    }
  }
}

/** MYI-317 remove download coupon */
.modal__body {
  border-radius: 0 0 16px 16px;
}