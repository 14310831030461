/* TIS-normal */
@font-face {
  font-family: 'TIS';
  font-style: normal;
  font-weight: 400;
  src: url('./TIS-Regular.eot'); /* IE9 Compat Modes */
  src: url('./TIS-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./TIS-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./TIS-Regular.woff') format('woff'), /* Modern Browsers */
       url('./TIS-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* TIS-bold */
@font-face {
  font-family: 'TIS';
  font-style: normal;
  font-weight: 700;
  src: url('./TIS-Bold.eot'); /* IE9 Compat Modes */
  src: url('./TIS-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./TIS-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('./TIS-Bold.woff') format('woff'), /* Modern Browsers */
       url('./TIS-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* MYI Noto-Regular */
@font-face {
  font-family: 'Noto';
  font-style: normal;
  font-weight: 400;
  src: url('./NotoSansThai-Regular.ttf') format('truetype');
}

/* MYI Noto-Bold */
@font-face {
  font-family: 'Noto';
  font-style: normal;
  font-weight: 700;
  src: url('./NotoSansThai-Bold.ttf') format('truetype');
}