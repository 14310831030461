@import 'config/_theme.scss';

.chip{
  display: flex;
  align-items: center;
  padding: $el-pad / 6;
  color: $color-primary;
  background-color: white;

  & span {
    display: block;
    font-size: $label;
    font-weight: $weight-semi;
    margin: 0.25rem;
  }

  & .button{
    height: 1.5rem;

    & i{
      font-size: 1.5rem !important;
      color: $color-primary;
    }
  }
}