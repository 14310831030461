@import 'config/_theme.scss';

.vehicles {
  height: 100%;
  background: $color-light;
  padding-bottom: $nav-height * 4;

  .content-header {
    padding: $el-pad/2;
  }

  .list {
    position: relative;
    min-height: calc(100vh - 15rem);
    z-index: 0;

    > div {
      height: auto !important;

      .ReactVirtualized__Grid {
        height: auto !important;
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
    }
  }

  .predownload {
    background-color: $color-light;
  }

  &__header {
    position: fixed;
    z-index: 10;
    background: $bg;
    width: 100%;

    &__pager {
      background: $color-light;
    }

    .content-header {
      display: none;
    }
  }

  &__body {
    display: block;
    min-height: 100vh;
    width: 100%;
    position: relative;
    top: 19rem;
    @media #{$desktop}, #{$tablet} {
      top: 0;
    }

    &.has--checked {
      top: 8rem;
    }

    &__count {
      max-width: $wrapper-width;
      display: flex;
      justify-content: flex-end;
      margin: $el-margin*2; 

      @media #{$desktop}, #{$tablet} {
        margin: $el-pad/2;
      }
    }

    &__list {
      &--header {
        margin: 0 auto;
        padding: 1.5rem 3rem;
        font-weight: $weight-bold;
        position: relative;
        top: 17rem;
        display: flex;
        max-width: calc(100% - (#{$el-pad}));

        &--novehicles {
          color: $color-primary;
        }

        &__item {
          display: inline-flex;

          &.assignment-column,
          &.last-maintenance-date,
          &.type,
          &.driver-name {
            display: none;
          }

          &.name {
            width: 45%;
          }

          &.next-main {
            width: calc(25% - 1rem);
            padding-right: 1rem;
          }

          &.age {
            width: calc(25% - 1rem);
            padding-right: 1rem;
          }

          &.fav {
            width: 5%;
            display: inline-flex;
            margin-left: auto;
            overflow: visible;
          }
        }
      }
    }
  }

  .fab {
    position: fixed;
    width: 72px;
    height: 72px;
    right: $el-pad/2;
    bottom: $el-pad/2 + $nav-height;
  }

  @media #{$desktop}, #{$tablet} {
    .fab {
      display: none;
    }

    &__header {
      position: sticky;

      .content-header {
        display: block;
      }
    }

    &__body {
      &.has--checked {
        top: 0;
      }

      &__list {
        &--header {
          position: static;
          margin: 0 auto;

          &.corporate {
            .vehicles__body__list--header__item {
              &.name {
                width: 20%;
              }
              &.assignment-column {
                display: inline-flex;
                width: calc(15% - 1rem);
                padding-right: 1rem;
              }
              &.last-maintenance-date {
                display: inline-flex;
                width: calc(15% - 1rem);
                padding-right: 1rem;
              }
              &.next-main {
                display: inline-flex;
                width: calc(10% - 1rem);
                padding-right: 1rem;
              }
              &.age {
                display: inline-flex;
                width: calc(15% - 1rem);
                padding-right: 1rem;
              }
              &.type {
                display: inline-flex;
                width: calc(10% - 1rem);
                padding-right: 1rem;
              }
              &.driver-name {
                display: inline-flex;
                width: calc(10% - 1rem);
                padding-right: 1rem;
              }
              &.fav {
                width: 5%;
                display: inline-flex;
                margin-left: auto;
                overflow: visible;
              }
            }
          }

          &.controller {
            .vehicles__body__list--header__item {
              &.name {
                width: 25%;
              }

              &.last-maintenance-date {
                display: inline-flex;
                width: calc(15% - 1rem);
                padding-right: 1rem;
              }

              &.next-main {
                display: inline-flex;
                width: calc(10% - 1rem);
                padding-right: 1rem;
              }

              &.age {
                display: inline-flex;
                width: calc(15% - 1rem);
                padding-right: 1rem;
              }

              &.type {
                display: inline-flex;
                width: calc(10% - 1rem);
                padding-right: 1rem;
              }

              &.driver-name {
                display: inline-flex;
                width: calc(15% - 1rem);
                padding-right: 1rem;
              }

              &.fav {
                width: 5%;
                display: inline-flex;
                margin-left: auto;
                overflow: visible;
              }
            }
          }

          &__item {
            display: inline-flex;

            &.assignment-column {
              display: inline-flex;
            }

            &.name {
              width: 45%;
            }
            &.next-main {
              width: calc(25% - 1rem);
              padding-right: 1rem;
            }
            &.age {
              width: calc(25% - 1rem);
              padding-right: 1rem;
            }
            &.fav {
              width: 5%;
              display: inline-flex;
              margin-left: auto;
              overflow: visible;
            }
          }
        }
      }
    }
  }

  @media #{$desktop} {
    .list {
      top: unset;
    }

    .vehicle-item {
      width: calc((100% - (#{$el-pad}/ 2 * 3)) / 4);
      margin-right: $el-pad/2;
      margin-bottom: $el-pad/2;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    &__body {
      &__list {
        &--header {
          position: static;
          margin: 0 auto;
          max-width: $wrapper-width - 9;
          padding-left: 4.5rem;
          padding-right: 4.5rem;

          &.corporate {
            max-width: $wrapper-width - 9rem;
          }
        }
      }
    }
  }

  @media #{$tablet} {
    .list {
      top: unset;
    }

    .vehicle-item {
      width: calc((100% - (#{$el-pad}/ 2 * 2)) / 3);
      margin-right: $el-pad/2;
      margin-bottom: $el-pad/2;

      &:nth-child(4n) {
        margin-right: $el-pad/2;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    &__body {
      &__list {
        &--header {
          position: static;
          margin: 0 auto;
          max-width: calc(100% - ((#{$el-pad}* 2) + 7.5rem));
          padding-left: 4.5rem;
          padding-right: 3rem;
        }
      }
    }
  }
}

.dealer-appointment { 
  text-align: center;
  button {
    width: 95%;
    margin: 10px;
    padding: 0;
    background-color: #F01516 !important;
  }
  @media #{$desktop} {
    display: none;
  }
}