@import '../../../config/theme';

.myi-privacy-policy {
  background-color: $color-light;
  padding: 24px 16px;
  // Make indents (leading tab and space characters) visible, so that the whole
  // content look more like a proper paragraph.
  white-space: pre-wrap;
  font-family: 'Noto', sans-serif;

  // Make empty p elements visible, so that the content maintainer can freely
  // insert empty lines to separate paragraphs.
  p:empty::before {
    content: '\200B';
  }

  td {
    border: solid;
    padding: 5px;
  }

  h4, strong {
    font-weight: 700;
  }
}
