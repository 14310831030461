@import 'config/_theme.scss';
@import '@material/fab/mdc-fab';

.popup-vehicles {
  width: 100%;
  padding: $el-pad / 6;

  .list-pager {
    padding: 0 $el-pad/2;
  }

  &__list-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: $el-pad / 2;
    position: relative;
    max-width: 120rem;
    margin: 0 auto;

    .modal__body__content__privilege__item {
      width: 100%;
      padding: $el-pad / 2;
      box-sizing: border-box;
      margin-bottom: 1.5rem;
      cursor: pointer;

      &__img {
        display: inline;
        img {
          width: 100%;
          object-fit: unset;
          height: unset;
        }
      }

      @media #{$desktop}, #{$tablet} {
        width: calc((100% - 3rem) / 3);
        margin: 0 1.5rem 1.5rem 0;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
