@import 'config/_theme.scss';

.signin-issue{
  &__form{
    padding: $el-pad;

    &__result{
      margin-bottom: $el-pad/2;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &__field{
        font-weight: $weight-bold;
        margin-right: $el-pad;
        word-break: break-word;
        span{
          font-size: $h5;
        }

        &:nth-child(even){
          margin-right: 0;
          button{
            color: $color-primary !important;
          }
        }
      }
    }

    &__otp-message{
      margin-bottom: $el-pad;

      &--timer{
        color: $color-primary;
        font-size: $h4;
        font-weight: $weight-semi;
        text-align: right;
      }
    }

    &__instruction{
      margin-bottom: $el-pad/2;
      &__title{
        margin-bottom: $el-pad/2;
      }
    }

    p{
      margin-bottom: $el-pad/2;
    }

    button{
      font-weight: $weight-bold;
      padding: 0;
    }

    .input{
      margin-bottom: $el-pad/2;
    }

    &__page_select{
      &__option{
        font-weight: $weight-bold;
        button{
          display: block;
        }
      }
    }

    &__radio{
      margin-bottom: $label-margin;
      
      > div{
        display: flex;
        flex-direction: row;

        span{
          font-size: $font-base;
          font-weight: $weight-semi;
        }

        span[class^="MuiIconButton"]{
          color: $color-primary;
        }
      }
    }

    &__action{
      margin-top: $el-pad;
      display: flex;
      align-items: center;
      flex-direction: row;
      width:100%;
      
      
      button{
        width: 100%;
      }

      &__section{
        width:100%;
        &--half{
          display: flex;
          align-items: center;
          flex: 1 1 50%;
          margin: 0;
          font-weight: $weight-bold;
        }

        label{
          font-size: $label;
        }

        &--button{
          flex: 0 0 100%;
          width: 100%;
        }

        
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 1.5rem 3rem;

    &__language {
      flex: 0 1 40%;
    }
  }

  @media #{$desktop}{
    .tab-bar{
      padding-left: $el-pad;
      padding-right: $el-pad;
      width: calc(100% - (#{$el-pad} *2))
    }

    &__form{
      margin: 0 auto;
      max-width: $wrapper-width;
    }
  }

  @media #{$tablet}{
    .tab-bar{
      padding-left: $el-pad;
      padding-right: $el-pad;
      width: calc(100% - (#{$el-pad} *2))
    }
  }
}

.webview-sub-header {
  color: $color-primary;
  font-weight: 600;
  padding: $el-pad/2 $el-pad;
  text-align: center;
  border-bottom: solid 2px $color-primary;
}