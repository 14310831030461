
@import "../../config/theme";

.privacy-policy{

  &__wrapper{
    padding: $el-pad/4 $el-pad $nav-height $el-pad;
    height:calc(100vh - 20rem);  
    
  &__header{
    padding-bottom: 5px;
    font-size: 1.8rem;
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
  }
  }


  @media #{$desktop}{

    &__wrapper{
      margin: 0 auto;
      max-width: $wrapper-width;
    }
   }
 }