@import "../../../../config/_theme.scss";

.item-detail {
  &__header {
    margin: $el-pad / 2 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    li::marker {
      color: $color-primary;
    }
  }
  &__list {
    font-size: 15px;
    li::marker {
      color: $color-dark;
      font-size: 10px;
    }
  }
}