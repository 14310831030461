@import '../../../config/theme';

.delete-vehicle {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$top-height-desk + $nav-height-desk});

  & .content-header {
    flex-shrink: 0;
  }

  &__body {
    flex: 1;
    background-color: white;

    &__container {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      margin: auto;

      &__controllers {
        flex-shrink: 0;
        padding: 1rem 3rem;
        background-color: $bg-nav-desk;

        &__content {
          margin: auto;
          max-width: 120rem;
        }
      }

      &__vehicles-list {
        height: 0;
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        flex-grow: 1;

        @media #{$desktop}, #{$tablet}{
          padding: 1rem 3rem;
        }

        & .vehicles__body__list--header {
          width: 100%;
          top: 0;
        }

        & .list {
          width: 100%;
          // use overlay prevent browser reduces space of item
          overflow-y: overlay;
        }
      }

      &__actions {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        padding-bottom: 1rem;

        @media #{$desktop}, #{$tablet}{
          padding-bottom: 3rem;
        }

        button {
          width: auto;
          padding: 0 5rem;
          margin: 1rem;
        }
      }
    }
  }
}
