@import 'config/_theme.scss';

.controller-detail {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$top-height-desk + $nav-height-desk});

  .change-view {
    display: none;
  }

  .list {
    margin: 0 auto;
    max-width: $wrapper-width;
  }

  & .vehicles__body__list--header {
    width: 100%;
    top: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    padding: 0 $el-pad;
    max-width: $wrapper-width;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;

    &--delete {
      display: none;
    }
  }

  &__body {
    padding: $el-pad/2 $el-pad;

    &__info {
      margin-bottom: $el-pad/2;
      min-width: 10rem;
      padding-right: 1rem;

      span {
        font-size: $label;
        text-transform: uppercase;
        letter-spacing: $label-spc;
        margin-bottom: 0.5rem;
        display: block;
      }

      button {
        span {
          margin-bottom: 0;
        }
      }

      &.status {
        display: flex;
        flex-wrap: nowrap;
        width: auto;
      }

      &__resend--button {
        max-height: 3.5rem;
        margin-left: 1rem;
        display: inline-block;
        vertical-align: bottom;
      }

      &__group {
        display: flex;
        flex-wrap: nowrap;
      }

      &__name,
      &__office-phone,
      &__mobile-phone {
        &__value {
          white-space: nowrap;
        }
      }

      &__name,
      &__username,
      &__assignment,
      &__mobile-phone,
      &__office-phone,
      &__email,
      &__vehicle {
        &__value {
          font-size: $h5;
          color: $color-grey;
          font-weight: $weight-bold;
          margin-bottom: 0.5rem;
          word-break: break-all;
        }
      }

      &__office-phone,
      &__mobile-phone {
        width: 10rem;
      }

      &__email {
        width: auto;
        padding-right: 0;
      }
    }
  }

  &__vehicles {
    position: sticky;
    // top: 45px; old version
    top: 0px;
    z-index: 100;

    &__detail {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__tabs {
      padding-top: 5px;
      background: $bg;

      &__wrapper {
        max-width: $wrapper-width;
        display: block;
        margin: 0 auto;
        position: relative;
      }

      &__pager {
        position: relative;
        width: 100%;
        background: $color-light;
      }
    }

    &__list {
      flex: 1;
      padding: $el-pad 0 $nav-height;
      background: $color-light;

      &__action {
        padding: $el-pad $el-pad;
      }

      &__novehicles {
        font-size: $font-base;
        color: $color-primary;
        margin: 0 $el-pad;
      }
    }
  }

  @media #{$desktop} {
    .controller-detail__body-wrapper {
      margin: 0 auto;
      max-width: $wrapper-width;

      .info {
        float: left;
        margin-right: $el-pad * 4;
      }
    }

    .vehicle-item {
      width: calc((100% - (#{$el-pad}/ 2 * 3)) / 4);
      margin-right: $el-pad/2;
      margin-bottom: $el-pad/2;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    .change-view {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      padding-right: 0;
    }

    .change-view-sm {
      display: none;
    }

    &__header {
      padding: 0;

      &--delete {
        display: block;
        margin-left: auto;
      }
    }

    &__body {
      &__info {
        width: 30rem;
      }
    }

    &__vehicles {
      position: sticky;
      top: 120px;

      &__list {
        padding: $el-pad $el-pad $nav-height;

        &__action {
          display: none;
        }

        &__novehicles {
          margin: 0 auto;
          width: 100%;
          max-width: $wrapper-width;
        }
      }
    }
  }

  @media #{$tablet} {
    .controller-detail__body-wrapper {
      margin: 0 auto;

      .info {
        float: left;
        margin-right: $el-pad * 4;
      }
    }

    .vehicle-item {
      width: calc((100% - (#{$el-pad}/ 2 * 3)) / 4);
      margin-right: $el-pad/2;
      margin-bottom: $el-pad/2;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    .change-view {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      padding-right: $el-pad;
    }

    .change-view-sm {
      display: none;
    }

    &__header {
      &--delete {
        display: block;
        margin-left: auto;
      }
    }

    &__body {
      &__info {
        width: 23rem;
      }
    }

    &__vehicles {
      position: sticky;
      top: 120px;

      &__list {
        padding: $el-pad $el-pad $nav-height;

        &__action {
          display: none;
        }

        &__novehicles {
          margin: 0;
          width: 100%;
          max-width: $wrapper-width;
        }
      }
    }
  }
}
