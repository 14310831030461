@import "config/_theme.scss";


.detail-promotion {
  background: $color-light;
  padding-bottom: $el-pad + $nav-height;
  height: 100%;

  &__meta,
  &__title,
  &__content,
  &__target {
    padding: $el-pad/2 $el-pad 0 $el-pad;
  }

  &__meta {
    display: flex;

    &__date {
      display: block;
      height: 1.8rem;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-grey;
    }
  }

  &__title {
    h1 {
      font-size: $h3;
    }
    margin-bottom: $el-pad/2;
  }

  &__image {
    overflow: hidden;
    padding-top: $el-pad/2;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__content {
    color: $color-dark;
    line-height: $lh;

    img {
      max-width: 100%;
      margin-top: 1rem;
    }
  }

  &__vehicles {
    width: 100%;

    &__item {
      display: flex;
      align-items: center;
      padding: $el-pad/4 $el-pad;

      &:first-child {
        padding-top: 1rem;
      }

      &__img {
        max-height: 8rem;
        max-width: 8rem;
        min-width: 40%;
        margin-right: 1.5rem;
        display: flex;

        img {
          object-fit: contain;
          object-position: center;
          overflow: hidden;
          border-radius: 15px;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        font-weight: $weight-semi;
        max-width: 60%;

        &__name {
          font-size: $h6;
          margin-bottom: 0.5rem;
          text-transform: uppercase;
        }

        &__license {
          color: $color-primary;
          font-size: $h4;
        }
      }
    }
  }

  &__target {
    &__vehicles {
      width: 100%;

      &__title {
        font-weight: $weight-semi;
        font-size: $h5;
        margin-bottom: $el-pad/2;
      }

      &__item {
        display: flex;
        padding: $el-pad/4 0;

        &__img {
          max-height: 8rem;
          max-width: 8rem;
          min-width: 40%;
          margin-right: 1.5rem;
          display: flex;

          img {
            height: auto;
            width: 100%;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            border-radius: 5px;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          font-weight: $weight-semi;
          max-width: 60%;

          &__name {
            font-size: $h6;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
          }

          &__license {
            color: $color-primary;
            font-size: $h4;
          }
        }
      }
    }

    &__button {
      height: 4rem;
    }
  }

  @media #{$tablet} {
    &__meta,
    &__title,
    &__content,
    &__target {
      margin: 0 auto;
      max-width: calc(100% - (#{$el-pad}* 4));
      &__vehicles {
        max-width: 50%;
      }
    }
    &__image {
      margin: 0 auto;
      max-width: calc(100% - (#{$el-pad}* 2));
    }
  }

  @media #{$desktop} {
    &__meta,
    &__title,
    &__content,
    &__target {
      margin: 0 auto;
      max-width: calc(#{$wrapper-width} - (#{$el-pad}* 8));
      &__vehicles {
        max-width: 40%;
      }
    }
    &__image {
      margin: 0 auto;
      max-width: calc(#{$wrapper-width} - (#{$el-pad}* 6));
    }
  }
}

.appointment-promotion {
  @media #{$desktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  button {
    width: 100%;
    height: 45px;
    background-color: #f01516 !important;
    font-weight: bold;
    border-radius: 5px;
    border: unset;
    color: $color-light;
    font-size: $font-base;
    font-family: $font-body !important;
    text-decoration: none;
    letter-spacing: 1.5px;
    cursor: pointer;
    @media #{$desktop} {
      width: 200px;
    }
    i {
      vertical-align: middle;
      margin-right: $el-margin;
    }
  }
}
