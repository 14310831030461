@import 'config/_theme.scss';

.input{
  position: relative;

  &__container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__otp_cont{
    justify-content: space-around;
    input{
      width: calc(100% - #{$el-margin}) !important;
      padding: $el-pad/2 $el-pad/4;
      font-weight: $weight-bold;
      outline: none;
      border: 1px solid $color-light;
      font-size: $font-base;
      height: $input-height;
      border-radius: $el-rad;
      box-sizing: border-box;
      background: $color-light;
    }
  }

  &__label{
    display:block;
    width: 100%;
    min-height: $label-height;
    margin-bottom: $label-margin;
    font-size: $label;
    letter-spacing: $label-spc;
    color: $color-dark;

    .input__label__required{
      margin-left: $el-margin/2;
      color: $color-primary;
    }
  }

  &__input{
    width: 100%;
    height: $input-height;
    border-radius: $el-rad;
    box-sizing: border-box;
    border: 1px solid $color-light;
    background: $color-light;
    padding: $el-pad/2;
    font-size: $font-base;

    &:disabled{
      background-color: lighten($color-grey-soft, 10);
      border-color: lighten($color-grey-soft, 10);
    }
    &::placeholder{
      font-family: $font-header;
    }
  }

  &__otp_cont{
    justify-content: space-around;
    input{
      border: 1px solid $color-light;
      font-size: $font-base;
      height: $input-height;
      border-radius: $el-rad;
      box-sizing: border-box;
      background: $color-light;
    }
  }

  &__actions{
    width: auto;
    position: absolute;
    display: flex;
    right: $el-margin*2;
    justify-content: flex-end;
    align-items: center;
    //top: $clear-top; // to accomodate validation error
    height: $clear-height;

    &__clear, &__toggle-password{
      width: $clear-height;
      height: $clear-height;
      border-radius: 100%;
      background: lighten($color-grey, 40);
      color: $color-light;
      display: flex;
      justify-content: center;
      align-items: center;
      right: $clear-height/2;
      margin-left: $el-margin*2;
      cursor: pointer;

      i{
        font-size: $clear-height;
      }
    }

    &__toggle-password{
      background: none;
      color: $color-grey;
    }
  }


  &__error{
    font-size: $label;
    color: $color-primary;
    padding-left: $el-pad/2;
  }

  &__tooltip{
    position: absolute;
    right: 0;
    width: $label-height;
    height: $label-height;
    top: 0;

    i{
      font-size: $label-height;
      color: $color-grey-soft;
    }

    &--text{
      &:hover{
        ~ .input__tooltip-detail{
          visibility: visible;
        }
      }
    }
  }

  &__tooltip-detail{
    visibility: hidden;
    position: absolute;
    width: auto;
    display: inline-block;
    text-align: right;
    right: 0;
    z-index: $z-tooltip;
    background: $color-grey;
    color: $color-light;
    padding: $tooltip-pad $tooltip-pad*2;
    border-radius: $el-rad;
  }
}

.how-to{
  &__image{
    margin-top: $el-pad;
    img{
      max-width: 100%;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: $color-light !important;
}
