@import 'config/_theme.scss';

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $nav-height;
  text-decoration: none;
  outline: none;
  flex-direction: column;
  position: relative;

  &__icon-wrapper, &__icon-wrapper-inbox {

    display: flex;
    @media #{$desktop}, #{$tablet} {
      display: none;
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__icon-wrapper-inbox {
    @media #{$desktop}, #{$tablet} {
      display: flex;
    }
  }


  &:active {
    background: none;
  }

  i {
    font-size: $nav-icon;
    color: $color-dark;
  }

  span {
    display: block;
    font-size: $nav-ph2;
    font-weight: $weight-semi;
    text-align: center;
    text-transform: uppercase;
    margin-top: $el-margin;
    letter-spacing: $nav-spc;
    color: $color-gray-900;

    @media #{$desktop}, #{$tablet} {
      color: $color-gray-g9;
    }

    &.counter,
    &.coupon-counter {
      position: absolute;
      width: auto;
      min-width: $counter-width/1.5;
      height: $counter-height;
      box-sizing: border-box;
      font-size: $label;
      background: $color-primary;
      border-radius: $el-rad * 2;
      color: $color-light;
      text-align: center;
      display: flex;
      justify-content: center;
      border: 1px solid $color-light;
      top: 0.25rem;
      left: auto;
      right: auto;
      margin-left: 1.25rem;
      letter-spacing: -0.1rem;
    }
  }

  &--active {
    i,
    span {
      color: $color-primary-ISUZU;
    }
    svg {
      fill: $color-primary-ISUZU;
    }
  }

  &__icon {
    width: $nav-icon-el;
    height: auto;
  }

  @media #{$desktop}, #{$tablet} {
    height: $nav-height-desk;
    flex-direction: row;
    flex-grow: 0;
    padding: 0;
    margin-right: 3rem;
    min-width: unset;
    width: auto !important;
    box-sizing: border-box !important;

    &:last-child {
      margin-right: 0;
    }

    span {
      font-size: $nav-desk;
      letter-spacing: 0;
      margin: auto;

      &.counter {
        margin-top: -1.5rem;
        left: 0.15rem;
        top: unset;
      }

      &.coupon-counter {
        margin-top: 1.8rem;
        left: 1.6rem;
      }
    }
  }
}
