@import 'config/_theme.scss';

.promotions {
  .content-header {
    display: none;
  }

  &__body {
    position: relative;
    height: 100%;
    margin-bottom: $nav-height;
    @media (max-width: 767px) {
      top: 6rem;
    }
  }

  &__header {
    position: fixed;
    z-index: 10;
    background: $bg;
    width: 100%;

    .list-pager {
      background: $bg;
    }

    &__pager {
      display: flex;
      justify-content: space-between;

      max-width: $wrapper-width;
      margin: 0 auto;
    }

    &__total {
      background: $bg;
      display: flex;
      align-items: center;
      height: $el-height;
      font-size: $label;
      color: $color-grey;
      font-weight: $weight-semi;
      letter-spacing: $label-spc;
      text-transform: uppercase;
      position: relative;

      span {
        margin-left: $el-pad;
      }
    }
  }

  .list {
    padding-bottom: $nav-height;
  }

  @media #{$tablet}, #{$desktop} {
    &__header {
      position: static;
    }

    .content-header {
      display: block;
    }
  }

  @media #{$tablet} {
    &__body {
      margin: 0 auto;
      max-width: calc(100% - (#{$el-pad}* 2));
    }
  }
}
