@import 'config/_theme.scss';
@import '@material/react-button/index';
@import '@material/button/mixins';

.button {
  @include mdc-button-container-fill-color(transparent);
  @include mdc-button-ink-color($color-light);
  @include mdc-button-shape-radius($el-rad);
  @include mdc-states-base-color($color-primary);
  @include mdc-states-hover-opacity(0);
  @include mdc-states-focus-opacity(0);
  @include mdc-states-press-opacity(0.05);

  font-family: $font-header;
  height: $button-height;
  font-size: $font-base;
  text-transform: capitalize;

  .icon-next {
    margin-left: 8px !important;
    width: 18px !important;
    height: 18px !important;
    font-size: 18px !important;
    vertical-align: top !important;
  }

  &--icon-only {
    @include mdc-button-ink-color($color-dark);
    @include mdc-states-press-opacity(0);
    @include mdc-button-container-fill-color(transparent);
    padding: 0 !important;
    min-width: 0 !important;
    display: flex;
    align-items: center;

    i {
      font-size: $icon-only !important;
      margin-right: 0 !important;
      &.mdc-button__icon {
        width: $icon-only;
        height: $icon-only;
      }
    }
  }

  &--submit {
    width: 100%;
    height: $button-height;
    font-size: $font-base;

    &:disabled {
      background: $color-dark !important;
      color: $color-light !important;
      @include mdc-button-ink-color($color-light);
      opacity: 0.2;
    }
  }

  &--primary {
    @include mdc-button-container-fill-color($color-primary);
    @include mdc-button-ink-color($color-light);
    &[disabled] {
      background-color: $color-gray-g5;
      color: $color-light;
    }
  }

  &--primary-shadow {
    @include mdc-button-container-fill-color($color-light);
    @include mdc-button-ink-color($color-primary);
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  }

  &--delete {
    @include mdc-button-container-fill-color(transparent);
    @include mdc-button-ink-color($color-primary);
    padding: 0;
  }

  &--dark {
    @include mdc-button-container-fill-color($color-dark);
    @include mdc-button-ink-color($color-light);
  }

  &--cancel {
    @include mdc-button-container-fill-color($color-grey);
    @include mdc-button-ink-color($color-light);
  }

  &--transparent {
    @include mdc-button-container-fill-color(transparent);
    @include mdc-button-ink-color($color-dark);
    @include mdc-states-press-opacity(0);
  }

  &--transparent-primary {
    @include mdc-button-container-fill-color(transparent);
    @include mdc-button-ink-color($color-primary);
    @include mdc-states-press-opacity(0);

    i > svg > path {
      &[disabled] {
        fill: $color-gray-g5;
      }
    }
  }

  &--transparent-primary-shadow {
    @include mdc-button-container-fill-color(transparent);
    @include mdc-button-ink-color($color-primary);
    @include mdc-states-press-opacity(0);
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  }

  &--modal-close {
    @include mdc-button-ink-color($color-primary);
  }

  &--top-nav {
    @include mdc-button-container-fill-color(transparent);
    @include mdc-button-ink-color($color-dark);
    padding: $el-pad/2;
    width: 100%;
    justify-content: flex-start;
    border-top: 1px solid $color-border;
  }

  &--link {
    @include mdc-button-ink-color($color-primary);
    @include mdc-button-container-fill-color(transparent);
    font-weight: $weight-semi;
    height: $el-height-sm;
    align-items: left;
    float: left;
    clear: left;
  }

  &--pager {
    @include mdc-button-ink-color($color-light);
    @include mdc-button-container-fill-color($color-primary);
    width: $el-height-sm;
    height: $el-height-sm;

    &:disabled {
      background: $color-grey;
      opacity: 0.2;
    }
  }

  &--check-all {
    @include mdc-button-ink-color($color-primary);
  }

  &--add-new {
    @include mdc-button-ink-color($color-light);
    @include mdc-button-container-fill-color($color-dark);
  }

  &--line {
    @include mdc-button-ink-color($color-light);
    @include mdc-button-container-fill-color(#00c200);
    width: 100%;
    height: $button-height;
    font-size: $font-base;
    text-decoration: none;

    i {
      width: $el-height-sm !important;
      height: $el-height-sm !important;
    }

    svg {
      width: $el-height-sm !important;
      height: $el-height-sm !important;
    }
  }

  &--line-home {
    @include mdc-button-ink-color($color-dark);
    width: 100%;
    height: $button-height;
    font-size: $font-base;
    text-decoration: none;

    i {
      width: $el-height-sm !important;
      height: $el-height-sm !important;
    }

    svg {
      width: $el-height-sm !important;
      height: $el-height-sm !important;
    }
  }

  &--line-dealer {
    @include mdc-button-ink-color($color-light);
    @include mdc-button-container-fill-color(#00c200);
    width: 100%;
    height: auto;
    font-size: $font-base;
    display: flex;
    align-items: flex-start;
    padding: $el-pad/2;
    text-align: left;

    > span {
      margin-top: $el-margin;
    }

    i {
      width: $el-height-sm !important;
      height: $el-height-sm !important;
      margin-right: $el-margin * 2;
    }

    svg {
      width: $el-height-sm !important;
      height: $el-height-sm !important;
    }
  }

  &--sort {
    height: $el-height-sm;
    display: flex;
    align-items: center;

    &--desc {
      i {
        transform: scale(1, -1);
      }
    }

    i {
      color: $color-grey;
      font-size: $icon-only * 0.7 !important;
      height: $icon-only * 0.7 !important;
    }
  }

  i {
    position: relative !important;
    // top: -1px !important;
  }

  &--close {
    i {
      font-size: $icon-only * 0.75 !important;
      margin-right: 0 !important;
      margin-top: 10px;
      &.mdc-button__icon {
        width: $icon-only * 0.75;
        height: $icon-only * 0.75;
      }
    }
  }

  &--small {
    font-size: $label;
    font-weight: $weight-semi;
    height: $el-height-sm;
  }

  &--light {
    @include mdc-button-container-fill-color($color-light);
    @include mdc-button-ink-color($color-primary);
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);

    &:disabled {
      @include mdc-button-ink-color($color-grey-soft);
      opacity: 0.8;
      i svg {
        fill: $color-grey;
      }
    }
  }

  &--set-cover {
    border-bottom: 1px solid #959595 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &--white {
    @include mdc-button-container-fill-color($color-light);
    @include mdc-button-ink-color($color-dark);
    font-weight: bold;
    letter-spacing: 0;
    i,
    svg {
      height: unset !important;
    }
  }

  &--red,
  &--outline-red {
    @include mdc-button-container-fill-color($color-primary-ISUZU);
    @include mdc-button-ink-color($color-light);
    font-weight: bold;
    letter-spacing: 0;
    border-radius: 40px;
    width: 280px;

    &:disabled {
      background: $color-gray-g5 !important;
      color: $color-light;
      border-radius: 40px;
      width: 280px;
    }
  }

  &--outline-red {
    border: 1px solid $color-primary-ISUZU;
    @include mdc-button-container-fill-color(transparent);
    @include mdc-button-ink-color($color-primary-ISUZU);
  }

  &--vehicle {
    @include mdc-button-ink-color($color-primary-defualt);
    font-weight: bold;
    font-size: 14px;
    i {
      margin-right: 5px !important;
      svg {
        width: 100%;
        height: 100%;
      }
      span {
        letter-spacing: normal;
      }
    }
  }
}
