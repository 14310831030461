@import 'config/_theme.scss';

@import './swiper';

.slider {
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  margin-bottom: $el-pad;
  max-width: $wrapper-width;
  clear: both;

  @media #{$desktop}, #{$tablet} {
    margin: auto;
  }

  &--no-margin {
    width: 100%;
    .swiper-container {
      width: 100%;

      .swiper-pagination {
        right: $el-pad !important;
        left: unset !important;
      }
    }
    .slider__title {
      margin-left: $el-pad;
      margin-right: $el-pad;
    }

    .slider__nav__next {
      right: 1rem;
    }

    .slider__nav__prev {
      left: 0.2rem;
    }
  }

  &__title {
    height: 1.5rem;
    font-size: $label;
    font-weight: $weight-semi;
    letter-spacing: $label-spc;
    color: $color-dark !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;

    &__count {
      margin-left: 0.3rem;
    }
  }

  &__nav {
    &__next,
    &__prev {
      position: absolute;
      top: 50%;
      z-index: $z-slider;
      width: $slider-margin;
      height: $slider-margin;

      i {
        color: $color-grey;
      }
    }

    &__next {
      right: -2rem;
    }

    &__prev {
      left: -3rem;
    }
  }

  .swiper-container {
    position: static;
    height: 100%;
    margin-top: 12px !important;

    .swiper-pagination {
      text-align: center;
    }

    //old left and right button is -3rem
    .swiper-button-prev {
      left: -3rem;
    }
    .swiper-button-next {
      right: -3rem;
    }

    .swiper-button-black {
      background-size: 10px 10px;
      height: 4rem;
      top: 50%;
    }
  }

  .swiper-pagination-bullets-dynamic {
    .swiper-pagination-bullet-active-next,
    .swiper-pagination-bullet-active-next-next,
    .swiper-pagination-bullet-active-prev,
    .swiper-pagination-bullet-active-prev-prev {
      transform: scale(1);
    }
  }

  .swiper-pagination-bullet {
    background: $color-gray-400;
    position: relative;
    width: 12px;
    height: 6px;
    border-radius: 4px;
  }

  .swiper-pagination-bullet-active {
    background: $color-primary-ISUZU;
  }
}

.swiper-container--banner {
  .swiper-container {
    text-align: center;
    // max-height: 100%;
    margin: auto;
  }
}

.swiper-slide .swiper-slide-active {
  width: 100%;
}

.slider-signin {
  width: calc(100% - (#{$el-pad}* 2));
  margin: 0 auto;
  position: relative;
  margin-bottom: $el-pad;
  clear: both;
  max-width: $wrapper-width;

  .swiper-container {
    position: static;
    height: 100%;

    .swiper-pagination {
      text-align: center;
    }

    //old left and right button is -3rem
    .swiper-button-prev {
      left: -3rem;
    }
    .swiper-button-next {
      right: -3rem;
    }

    .swiper-button-black {
      background-size: 10px 10px;
      height: 4rem;
      top: 50%;
    }
  }
}
