@import '../../../config/theme';

.end-of-support-banner {
  cursor: pointer;
  img {
    @media #{$tablet}, #{$desktop} { 
      width: 50%;
      display: block;
    }
    margin: $el-pad auto;
    width: 100%;
  }
}