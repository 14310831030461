@import 'src/config/_theme.scss';

.vehicle-item {
  width: 100%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  &--card {
    width: calc(100% - 20px);
    border-radius: $el-radius;
    align-self: center;
    margin: 5px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    @media #{$desktop}, #{$tablet} {
      margin-bottom: 30px;
      width: calc(100% - 10px);
    }
  }

  &--list {
    width: 100% !important;
    height: 10rem !important;
    margin: 0 !important;
    display: flex;
    align-items: center;

    label {
      height: 8rem !important;
      width: 4.5rem;
      display: flex;
      align-items: center;

      i {
        top: unset;
        bottom: unset;
      }
    }
  }

  &--is-corporate,
  &--is-new {
    height: auto;

    .vehicle-item__label {
      height: auto;
    }
  }

  &__label {
    position: absolute;
    height: 14rem;
    width: 6rem;
    top: 0;
    left: 0;
    z-index: 2;

    i {
      display: block;
      position: absolute;
      top: 2rem;
      left: 0.5rem;
      font-size: 2rem;
      color: $color-grey-light;
    }
  }

  &__wrapper {
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid $color-border;
    background: $color-light;
    padding: 1.5rem;

    &--list {
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      border-top: 1px solid $color-border;
      border-bottom: 1px solid $color-border;
      padding: $el-pad/2 3rem;

      .vehicle-item__data {
        font-size: $h6;
        white-space: normal;

        &.assignment-column,
        &.last-maintenance-date,
        &.type,
        &.driver-name {
          display: none;
        }

        &.name {
          width: 45%;
        }

        &.new-assignment-column {
          color: $color-primary;
          font-size: 1rem;
          width: calc(30% - 1rem);
          padding-right: 1rem;
        }

        &.current-assignment-column {
          font-size: 1rem;
          width: calc(30% - 1rem);
          padding-right: 1rem;
        }

        &.next-main {
          width: calc(25% - 1rem);
          padding-right: 1rem;
          .new-list {
            white-space: nowrap !important;
            .overdue {
              color: $color-primary;
              font-size: smaller;
            }
          }
        }

        &.age {
          width: calc(25% - 1rem);
          padding-right: 1rem;
          .vehicle-item__wrapper__data__age {
            padding-right: 5px;
          }
        }

        &.fav {
          width: 5%;
          display: inline-flex;
          margin-left: auto;
          overflow: visible;
        }

        .vehicle-item__wrapper__data__assignment.assignment-below {
          display: block;
          .vehicle-item__wrapper__assignment__name {
            margin-left: 0;
          }
        }

        .vehicle-item__wrapper__data__plate {
          color: $color-accent !important;
        }

        h3 {
          font-size: $h6;
        }

        .button-fav {
          position: static;
          margin-left: auto;
          margin-right: $el-pad;

          i {
            color: $color-accent;
          }
        }
      }
    }

    &__data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: left;

      &__desc {
        max-width: calc(100% - 15rem);
        margin-left: $el-pad / 2;

        &__name {
          font-size: $h4;
          font-weight: $weight-semi;
          text-transform: capitalize;
          color: $color-dark;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 3rem;
          width: 100%;
        }

        &__plate {
          font-size: $h5;
          font-weight: $weight-semi;
          text-transform: capitalize;
          color: $color-primary;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 2.5rem;
          width: 100%;
          margin-bottom: 0.5rem;
        }

        &__vin {
          font-size: $font-base;
          text-transform: capitalize;
          color: $color-dark;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 1.5rem;
          width: 100%;
          margin-top: 1rem;
        }

        &__badges {
          display: flex;
          justify-content: flex-start;
          height: 6.5rem;

          img {
            max-height: 2.9rem;
            width: auto;
            padding-right: 0.5rem;
          }
          .isuzu_care {
            max-height: 2.5rem;
            margin-top: 0.3rem;
          }
        }

        &__label-group {
          margin-top: 1rem;

          &__detail {
            letter-spacing: $label-spc;
          }

          &__label {
            color: $color-grey;
            text-transform: uppercase;
            letter-spacing: $label-spc;
            margin-bottom: 0.5rem;
            .maintenance__label__hide {
              height: 35px;
              display: block;
            }
          }

          .overdue {
            color: $color-primary;
            font-size: smaller;
            white-space: nowrap;
          }

          @media #{$phone-sm}, #{$phone-md} {
            width: calc(100% + 10rem);
          }
        }

        .last-maintenance-date,
        .type {
          display: none;
        }
      }

      &__info {
        position: relative;

        .button-fav {
          right: 0rem;
          bottom: 0rem;

          i {
            color: $color-accent;
          }
        }

        &__age {
          text-align: right;
          display: flex;
          justify-content: flex-end;
          font-size: $label;
          color: $color-primary;
          height: 2rem;

          i {
            position: relative;
            top: 0.25rem;
            font-size: $label;
            margin-left: 0.5rem;
          }
        }

        &__image {
          width: 12rem;
          height: 7.5rem;
          overflow: hidden;
          position: relative;
          margin: 0.5rem;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            height: 100%;
            width: 80%;
            transform: translate(-50%, -50%);
            max-width: 11rem;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }

    &__assignment {
      margin: 0 0 1rem 0;
      width: 100%;
      display: flex;
      font-size: $font-base;

      .assignment {
        display: block;
        max-width: 100%;

        > span {
          float: left;
          display: inline-block;
          margin-right: 0.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 0.5rem);
        }
      }

      &__name {
        display: inline-block;
        float: none;
        text-transform: capitalize;
        word-break: break-word;
      }
    }
  }

  &:first-child {
    .vehicle-item__wrapper {
      border-top: 1px solid $color-border;
    }
  }

  &__check {
    display: none;

    &:checked ~ .vehicle-item__label {
      i {
        color: $color-primary;
      }
    }

    &:checked ~ .vehicle-item__wrapper {
      background: $bg-checked;
      border-bottom: 1px solid $bg-checked-border;
    }

    &:checked ~ .vehicle-item__wrapper--list {
      background: $bg-checked;
      border-bottom: 1px solid $bg-checked-border;
      border-top: 1px solid $bg-checked-border;
    }
  }

  &--past-maintenance {
    .vehicle-item__wrapper {
      background: $bg-maint;
      border-bottom: 1px solid $bg-maint-border;
    }
  }

  &--is-placeholder {
    .vehicle-item__wrapper {
      &__data {
        &__desc {
          &__name {
            background: rgba($color-dark, 0.05);
          }
          &__plate {
            background: rgba($color-dark, 0.05);
          }
          &__label-group {
            height: 3rem;
            background: rgba($color-dark, 0.05);
          }
        }
        &__info {
          &__image {
            width: calc(100% - 1rem);
            margin-left: 1rem;
            margin-top: 1rem;
            background: rgba($color-dark, 0.05);
          }
        }
      }
    }
    &.vehicle-item--is-corporate {
      .vehicle-item__wrapper__assignment {
        background: rgba($color-dark, 0.05);
      }
    }
  }

  @media #{$desktop}, #{$tablet} {
    display: inline-flex;
    border-radius: $el-rad;
    overflow: hidden;
    height: 100%;
    vertical-align: top;

    &__check {
      &:checked ~ .vehicle-item__wrapper {
        border: 1px solid $bg-checked-border;
      }
    }

    &__label {
      height: 15rem !important;
      top: 1px;

      i {
        top: $el-margin;
        left: $el-margin;
        font-size: $icon-only;
      }
    }

    &__wrapper {
      border: 1px solid $color-border;
      padding: 0;

      &--list {
        align-items: flex-start;
        padding-top: $el-pad/2;
        padding-left: 4.5rem;
        padding-right: 4.5rem;

        &.corporate {
          .vehicle-item__data {
            white-space: normal;

            &.name {
              width: 20%;
            }

            &.assignment-column {
              display: inline-flex;
              width: calc(15% - 1rem);
              padding-right: 1rem;

              .vehicle-item__wrapper__data__assignment {
                width: 100%;

                span {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 100%;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 4;
                }
              }
            }

            &.current-assignment-column {
              display: inline-flex;
              width: calc(40% - 1rem);
              padding-right: 1rem;
              font-size: 1.4rem;
              font-weight: 600;
            }

            &.new-assignment-column {
              display: inline-flex;
              width: calc(40% - 1rem);
              padding-right: 1rem;
              color: $color-primary;
              font-size: 1.4rem;
              font-weight: 600;
            }

            &.last-maintenance-date {
              display: inline-flex;
              width: calc(15% - 1rem);
              padding-right: 1rem;
            }

            &.next-main {
              display: inline-flex;
              width: calc(10% - 1rem);
              padding-right: 1rem;
            }

            &.age {
              display: inline-flex;
              width: calc(15% - 1rem);
              padding-right: 1rem;
            }

            &.type {
              display: inline-flex;
              width: calc(10% - 1rem);
              padding-right: 1rem;
            }

            &.driver-name {
              display: inline-flex;
              width: calc(10% - 1rem);
              padding-right: 1rem;
            }

            &.fav {
              width: 5%;
              display: inline-flex;
              margin-left: auto;
              overflow: visible;
            }
          }
        }

        &.controller {
          .vehicle-item__data {
            white-space: normal;

            &.name {
              width: 25%;
            }

            &.last-maintenance-date {
              display: inline-flex;
              width: calc(15% - 1rem);
              padding-right: 1rem;
            }

            &.next-main {
              display: inline-flex;
              width: calc(10% - 1rem);
              padding-right: 1rem;
            }

            &.age {
              display: inline-flex;
              width: calc(15% - 1rem);
              padding-right: 1rem;
            }

            &.type {
              display: inline-flex;
              width: calc(10% - 1rem);
              padding-right: 1rem;
            }

            &.driver-name {
              display: inline-flex;
              width: calc(15% - 1rem);
              padding-right: 1rem;
            }

            &.fav {
              width: 5%;
              display: inline-flex;
              margin-left: auto;
              overflow: visible;
            }
          }
        }

        .vehicle-item__data {
          white-space: normal;
          word-break: break-word;

          &.name {
            width: 45%;
          }

          &.next-main {
            width: calc(25% - 1rem);
            padding-right: 1rem;
          }

          &.age {
            width: calc(25% - 1rem);
            padding-right: 1rem;
          }

          &.fav {
            width: 5%;
            display: inline-flex;
            margin-left: auto;
            overflow: visible;
          }
        }
      }

      &__assignment {
        width: 100%;
      }

      &__data {
        flex-direction: column-reverse;

        &:hover {
          &:before {
            width: 100%;
            height: 100%;
          }
        }

        &__desc {
          width: calc(100% - #{$el-pad});
          max-width: 100%;
          box-sizing: content-box;
          padding: $el-pad/2;
          margin-left: 0;

          .last-maintenance-date,
          .type {
            display: block;
          }
        }

        &__info {
          width: 100%;
          height: 15rem;
          position: relative;
          overflow: hidden;

          .button-fav {
            position: absolute;
            z-index: 4;
            right: $el-margin * 2;
            bottom: $el-margin * 2;
            background: rgba($color-grey-light, 0.5);

            i {
              color: $color-accent;
            }

            &.button-fav--favorite {
              i {
                color: $color-primary;
              }
            }
          }

          &__age {
            position: absolute;
            z-index: 2;
            right: $el-margin * 2;
            top: $el-margin * 2;
            color: $color-primary;
          }

          &__image {
            width: calc(100% - 1rem);
            height: 100%;

            img {
              transform: translate(-55%, -50%);
              max-width: none;
            }
          }
        }
      }
    }
  }
}
